export const FormatUtil = {
  Convert: {
    exceptHyphen(formData) {
      const formatData = { ...formData };

      const target = [
        'businessRegistrationNumber',
        'fax',
        'mainPhone',
        'picContact',
        'entrpsId',
        'cellphone',
        'contact',
      ];

      for (let field in formatData) {
        if (
          field === 'businessRegistrationNumber' &&
          formatData[field].length > 12
        ) {
          const [first, second, third, affix] = formatData[field].split('-');
          formatData[field] = `${first}${second}${third}-${affix}`;
        }
        if (target.indexOf(field) >= 0 && formatData[field]) {
          formatData[field] = formatData[field].split('-').join('');
        }
      }

      return formatData;
    },
  },
};
