const FileState = {
  Loaded: 'L',
  Inserted: 'I',
  Updated: 'U',
  Deleted: 'D',
};

Object.freeze(FileState);

export default FileState;
