import * as Button from './../../components/Atoms/Button';

import { Link, useHistory } from 'react-router-dom';
import React, { useState } from 'react';

import { Checkbox } from 'primereact/checkbox';
import DOMPurify from 'dompurify';
import { Dialog } from 'primereact/dialog';
import _ from 'lodash';
import dayjs from 'dayjs';

const LandingPopup = ({ visible = false, item, onHide = null }) => {
  const history = useHistory();

  const [isTodayHidden, setIsTodayHidden] = useState(false);

  const seq = _.get(item, 'boardData.seq');
  const boardFilesData = _.get(item, 'boardFilesData');

  function onClose() {
    // 공지사항 팝업을 닫고
    // 세션 스토리지에 showPopup_seq 값 저장하기
    onHide();
    sessionStorage.setItem(`showPopup_${seq}`, 'N');
  }
  function onClickDetail(seq) {
    // 공지사항 팝업을 닫고
    // 세션 스토리지에 showPopup_seq 값 저장하고
    // 게시글 페이지로 이동시키기
    onHide();
    sessionStorage.setItem(`showPopup_${seq}`, 'N');

    history.replace(`/erp/post/${seq}`);
  }
  function onClickFiles(seq) {
    onHide();
    sessionStorage.setItem(`showPopup_${seq}`, 'N');
  }
  function onClickTodayHidden(seq, isTodayHidden) {
    // 로컬 스토리지에 isTodayHidden_seq 값을 저장함
    const now = dayjs();
    const endTime = now.endOf('day').toDate();

    if (isTodayHidden) {
      localStorage.setItem(`isTodayHidden_${seq}`, endTime);
    } else {
      localStorage.removeItem(`isTodayHidden_${seq}`);
    }
  }

  return (
    <Dialog
      modal
      visible={visible}
      blockScroll
      position={'top-right'}
      header="공지사항"
      footer={
        <div className="flex flex-column justify-content-start w-full gap-3">
          <Button.Default
            label="게시글 상세 보기"
            className="w-full"
            onClick={() => {
              onClickDetail(seq);
            }}
          />
          <div className="flex">
            <Checkbox
              id="isTodayHidden"
              className="mr-2"
              checked={isTodayHidden}
              onChange={({ checked }) => {
                setIsTodayHidden(checked);
                onClickTodayHidden(seq, checked);
              }}
            />
            <label htmlFor="isTodayHidden">오늘 하루 보지 않기</label>
          </div>
        </div>
      }
      onHide={onClose}
      style={{
        width: '30rem',
      }}
    >
      <>
        <p className="font-bold">{_.get(item, 'boardData.title')}</p>
        {boardFilesData && boardFilesData.length > 0 && (
          <Link to={`/post/notice/${seq}`} onClick={() => onClickFiles(seq)}>
            첨부파일 확인하기
          </Link>
        )}
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(_.get(item, 'boardData.content')),
          }}
          className="py-3 overflow-auto max-h-25rem"
        />
      </>
    </Dialog>
  );
};

export default LandingPopup;
