const MolitState = {
  NonTarget: 'N',
  Success: 'S',
  Failed: 'F',
  Retry: 'R',
};

Object.freeze(MolitState);

export default MolitState;
