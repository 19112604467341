import * as Titled from '../Titled';

import React, { useCallback } from 'react';

import { CommonUtil } from '../../../utils/commonUtil';
import { Controller } from 'react-hook-form';

export const ControlledInputPassword = ({
  item,
  control,
  setValue,
  getValues,
  inputConfig,
}) => {
  const onChangeInputValue = useCallback(
    (e, field) => {
      field.onChange(e);

      let value = e.target.value;
      if (item.type)
        CommonUtil.Formatter[item.type] &&
          (value = CommonUtil.Formatter[item.type](value));

      setValue(field.name, value);
    },
    [item.type, setValue]
  );
  return (
    <Controller
      control={control}
      name={item.code}
      defaultValue=""
      rules={{
        // required: item.required ? '필수 입력항목입니다.' : false,
        pattern: {
          value: CommonUtil.Pattern[item.type],
          message: '유효하지 않은 포맷입니다.',
        },
        // validate: (value) => {
        //   const { password } = getValues();
        //   return password === value || '비밀번호가 일치하지 않습니다.';
        // },
        required: item.required,
        validate: item.validate,
      }}
      render={({ field, fieldState, formState }) => (
        <Titled.Password
          id={field.name}
          {...item}
          {...field}
          {...inputConfig}
          onChange={(e) => onChangeInputValue(e, field)}
          error={formState.errors[field.name]}
        />
      )}
    />
  );
};
