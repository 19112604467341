import { BlockUI } from 'primereact/blockui';
import { Dialog } from 'primereact/dialog';
import React from 'react';

export const BaseModal = ({
  children,
  onHide,
  header,
  loading,
  footer,
  widthClass,
}) => {
  return (
    <Dialog
      modal
      breakpoints={{ '960px': '90vw' }}
      header={header}
      visible
      blockScroll
      style={widthClass ? { width: widthClass } : { width: '76vw' }}
      onHide={onHide}
      footer={footer}
    >
      <BlockUI
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
        blocked={loading}
      >
        {children}
      </BlockUI>
    </Dialog>
  );
};
