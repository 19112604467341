import AxiosUtil from '../utils/axiosUtil';
import { BaseService } from './BaseService';

export const PerformanceCheckPaymentService = (function () {
  let instance;
  const BASE_URI = '/apis/performance-checks/payments';
  const baseService = BaseService.getInstance(
    BASE_URI,
    'performanceCheckPayment'
  );

  function init() {
    return {
      ...baseService,
      registerBase: async function (data) {
        return await AxiosUtil.Async.post(BASE_URI + '/data/base', data);
      },
      registerDetail: async function (data, files) {
        return await AxiosUtil.Async.multipart(
          BASE_URI + '/data/detail',
          data,
          files
        );
      },
      getData: async function (statementNumber) {
        return await AxiosUtil.Async.get(BASE_URI + `/${statementNumber}`);
      },
      delete: async function (statementNumber) {
        return await AxiosUtil.Async.delete(BASE_URI + `/${statementNumber}`);
      },
      list: async function (params) {
        const queryString = Object.entries(params)
          .map((e) => e.join('='))
          .join('&');
        return await AxiosUtil.Async.get(BASE_URI, queryString);
      },
      summary: async function (params) {
        const queryString = Object.entries(params)
          .map((e) => e.join('='))
          .join('&');
        return await AxiosUtil.Async.get(BASE_URI + '/summary', queryString);
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();
