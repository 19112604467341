import * as Controlled from './../../Atoms/Controlled';
import * as Modal from './../../Atoms/Modal';

import { Controller, useForm, useWatch } from 'react-hook-form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { MyInfoUtil } from '../../../utils/myInfoUtil';
import { Panel } from 'primereact/panel';
import { ServiceProvider } from '../../../services';
import { useFormValid } from '../../../hooks/useFormValid';

const service = {
  code: ServiceProvider.code,
  association: ServiceProvider.association,
  enterprise: ServiceProvider.enterprise,
  shop: ServiceProvider.shop,
  user: ServiceProvider.user,
};

export const BaseAccountDialog = ({
  formField,
  userInfo,
  id = 0,
  onHide = null,
  children,
  title,
  childDataName,
  onClearData,
  onDeleteConfirm,
  onSubmitConfirm,
}) => {
  const [loading, setLoading] = useState(false);
  const [codes, setCodes] = useState({});
  const [data, setData] = useState(null);
  const [init, setInit] = useState(false);
  const [isAvailable, setIsAvailable] = useState({
    code: false,
    loginId: false,
  });

  const {
    myAssociationId,
    myEnterpriseId,
    myShopId,
    myUserId,
    myRoleCode,
    myUserPosition,
  } = useMemo(() => MyInfoUtil.unpack(userInfo), [userInfo]);

  const getFormDefaultValues = useCallback((formField) => {
    const defaultValues = {};

    formField.forEach((item) => {
      defaultValues[item.code] = item.defaultValue;
    });

    return defaultValues;
  }, []);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    trigger,
    setValue,
    getValues,
  } = useForm({
    defaultValues: getFormDefaultValues(formField),
    reValidateMode: 'onSubmit',
  });

  const initData = useCallback(
    (clear) => {
      setIsAvailable({ code: false, loginId: false });
      reset(getFormDefaultValues(formField));

      if (clear) {
        clear(null);
      }
      setInit(false);
    },
    [formField, getFormDefaultValues, reset]
  );
  const isModified = id > 0;
  const { isFormComplete } = useFormValid(watch(), formField, isModified);

  //* 초기 데이터 불러오고 세팅하기
  //1. 등록일 때 -> myInfo에서 받아온 데이터로 기초 데이터 받아오고 codes, form 데이터에 업데이트
  //2. 수정일 때 -> id로 getData 해서 받아온 데이터로 > 이후 데이터 받아와서 codes, data에 업데이트
  //3. 에러 핸들링 -> dialog 띄우기
  useEffect(() => {
    setLoading(true);
    // todo: async fetchData()
    if (isModified) {
      try {
      } catch (error) {}
    }
    setLoading(false);
  }, [isModified]);

  //* Init
  useEffect(() => {
    if (init) {
      initData(onClearData);
    }
  }, [init, initData, onClearData]);

  //* data가 있을때
  useEffect(() => {
    if (data) {
      setIsAvailable({ code: true, loginId: true });
      reset(data);
    }
  }, [data, reset]);
  return (
    <Modal.Form
      title={title}
      childDataName={childDataName}
      loading={loading}
      isModified={isModified}
      onHide={onHide}
      onDeleteConfirm={onDeleteConfirm}
      onSubmitConfirm={onSubmitConfirm}
      saveBtnEnable={
        !(
          Object.values(isAvailable).filter((valid) => !valid) > 0 ||
          isFormComplete
        )
      }
    >
      <form>
        <Panel
          headerTemplate={
            <div className="p-panel-header">
              <Controlled.InputSwitch
                control={control}
                watch={watch}
                inputData={{
                  inputLabel: formField.filter(
                    (item) => item.code === 'useYn'
                  )[0].title,
                  toggleLabel: {
                    trueLabel: '사용',
                    falseLabel: '미사용',
                  },
                  dataLabel: 'useYn',
                }}
                styleClass={'font-semibold'}
              />
            </div>
          }
        >
          {children}
        </Panel>
        {children}
      </form>
    </Modal.Form>
  );
};
