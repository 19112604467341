import { BaseConfirmDialog } from './BaseConfirmDialog';
import React from 'react';

export const RegisterConfirmDialog = ({
  title,
  visible,
  isModified,
  onAccept,
  onClose,
}) => {
  return (
    <BaseConfirmDialog
      visible={visible}
      message={
        isModified
          ? `${title} 정보를 수정하시겠습니까?`
          : `신규 ${title}을 등록하시겠습니까?`
      }
      acceptLabel={isModified ? '수정' : '등록'}
      rejectLabel={'취소'}
      onAccept={onAccept}
      onClose={onClose}
    />
  );
};
