import AxiosUtil from '../utils/axiosUtil';

export const PriceService = (function () {
  let instance;
  const BASE_URI = '/apis/prices';

  function init() {
    return {
      getCheckPrice: async function ({
        associationId = 0,
        enterpriseId = 0,
        shopId = 0,
        checkPriceOf = null,
        checkPriceTableId = null,
      } = {}) {
        let queryString = `aid=${associationId}&eid=${enterpriseId}&sid=${shopId}`;
        if (checkPriceOf !== null && checkPriceTableId !== null)
          queryString += `&cpof=${checkPriceOf}&cptid=${checkPriceTableId}`;
        return await AxiosUtil.Async.get(
          BASE_URI + '/check-price',
          queryString
        );
      },
      getInsurancePrices: async function ({
        associationId = 0,
        enterpriseId = 0,
        shopId = 0,
        insurancePriceOf = null,
        insurancePriceTableId = null,
      } = {}) {
        let queryString = `aid=${associationId}&eid=${enterpriseId}&sid=${shopId}`;
        if (insurancePriceOf !== null && insurancePriceTableId !== null)
          queryString += `&ipof=${insurancePriceOf}&iptid=${insurancePriceTableId}`;
        return await AxiosUtil.Async.get(
          BASE_URI + '/insurance-prices',
          queryString
        );
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();
