import { Controller } from 'react-hook-form';
import { ENTERPRISE_TYPES } from '../../constants/Constants';
import { InputSwitch } from 'primereact/inputswitch';
import { MAX_DATE } from '../../constants/Constants';
import { MultiSelect } from 'primereact/multiselect';
import { RadioButton } from 'primereact/radiobutton';
import RangeCalendar from '../Common/RangeCalendar';
import React from 'react';
import UserRoleType from '../../enums/UserRoleType';
import _ from 'lodash';
import dayjs from 'dayjs';

const BoardConfigMenu = ({ config, control, setValue, postType, date }) => {
  const authorityList = _.filter(UserRoleType, function (r) {
    return (
      _.startsWith(r.value, 'E_') ||
      _.startsWith(r.value, 'S_') ||
      _.startsWith(r.value, 'C_')
    );
  });

  const enterpriseTypeOptions = [
    { value: '', label: '전체' },
    ...ENTERPRISE_TYPES,
  ];

  return (
    <div className="card grid m-0 mb-4">
      <div className="col-12 sm:col-12 md:col-12 lg:col-6">
        <Controller
          control={control}
          name="config.access.authority"
          render={({ field }) => (
            <div className="flex align-items-center gap-2">
              <label
                htmlFor="authority"
                style={{
                  wordBreak: 'keep-all',
                }}
                className="font-bold"
              >
                공지대상
              </label>
              <MultiSelect
                {...field}
                className="w-full max-w-30rem"
                options={authorityList}
                display="chip"
              />
            </div>
          )}
        />
      </div>
      {/* <div className="col-12 sm:col-3 md:col-3 lg:col-3 flex align-items-center">
        <Controller
          control={control}
          name="config.isImportant"
          render={({ field }) => (
            <div className="flex align-items-center justify-content-start gap-2">
              <label htmlFor="isImportant" className="font-bold">
                상단 고정
              </label>
              <InputSwitch
                checked={_.get(config, 'isImportant') === 'Y'}
                onChange={({ value }) => {
                  setValue('config.isImportant', value === true ? 'Y' : 'N');
                }}
              />
            </div>
          )}
        />
      </div> */}
      <div className="col-12 sm:col-12 md:col-12 lg:col-6 flex align-items-center">
        <Controller
          control={control}
          name="config.access.enterpriseType"
          render={({ field }) => (
            <div className="flex align-items-center gap-2">
              <label
                className="w-max font-bold"
                htmlFor="config.access.enterpriseType"
                style={{
                  wordBreak: 'keep-all',
                }}
              >
                업체구분
              </label>
              <div className="radio_buttons_wrapper flex gap-2 w-full">
                {enterpriseTypeOptions.map((radio, idx) => (
                  <div key={`radio_enterpriseType_${idx}`}>
                    <RadioButton
                      className="mr-2"
                      inputId={`radio_enterpriseType_${_.get(radio, 'value')}`}
                      name="enterpriseType"
                      value={_.get(radio, 'value')}
                      onChange={({ value }) =>
                        setValue('config.access.enterpriseType', value)
                      }
                      checked={
                        _.get(config, 'access.enterpriseType') ===
                        _.get(radio, 'value')
                      }
                    />
                    <label
                      htmlFor={`radio_enterpriseType_${_.get(radio, 'value')}`}
                    >
                      {_.get(radio, 'label')}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          )}
        />
      </div>

      {postType === 'A' && (
        <>
          <div className="col-12 sm:col-12 md:col-6 lg:col-6 flex align-items-center">
            <Controller
              control={control}
              name="config.isPopup"
              render={({ field }) => (
                <div className="flex align-items-center justify-content-start gap-2">
                  <label htmlFor="isPopup" className="font-bold">
                    팝업 띄우기
                  </label>
                  <InputSwitch
                    checked={_.get(config, 'isPopup') === 'Y'}
                    onChange={({ value }) => {
                      setValue('config.isPopup', value === true ? 'Y' : 'N');
                    }}
                  />
                </div>
              )}
            />
          </div>
          <div className="col-12 sm:col-12 md:col-6 lg:col-6">
            <Controller
              control={control}
              name="popupRange"
              render={({ field }) => (
                <div className="flex align-items-center justify-content-start gap-2">
                  <label
                    htmlFor="range"
                    className="font-bold"
                    style={{
                      wordBreak: 'keep-all',
                    }}
                  >
                    팝업게시기간
                  </label>
                  <RangeCalendar
                    startDate={_.get(date, 'startDate')}
                    endDate={_.get(date, 'endDate')}
                    onStartDateChanged={(date) => {
                      setValue('popupStart', date);
                    }}
                    onEndDateChanged={(date) => {
                      setValue('popupEnd', date);
                    }}
                    // touchUI={touchUI}
                    showNavigators={true}
                    disabled={_.get(config, 'isPopup') === 'N'}
                    rangeEndDate={dayjs(MAX_DATE).toDate()}
                  />
                </div>
              )}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default BoardConfigMenu;
