import * as ExcelJS from 'exceljs-enhance';

import {
  COUNT_PER_PAGE,
  VEHICLE_TYPE_LEVEL1,
  VEHICLE_TYPE_LEVEL2,
  VEHICLE_TYPE_LEVEL3,
  YN,
} from '../../../constants/Constants';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  columnCheckPriceType,
  columnDateTime,
  columnEnterpriseType,
  columnUseYn,
} from '../../../utils/dataTableUtil';

import { BlockUI } from 'primereact/blockui';
import { Button } from 'primereact/button';
import Chance from 'chance';
import CheckPriceItemDialog from './CheckPriceItemDialog';
import CheckPriceSaveConfirmDialog from './CheckPriceSaveConfirmDialog';
import { CodeUtil } from '../../../utils/CodeUtil';
import { Column } from 'primereact/column';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { MyInfoUtil } from '../../../utils/myInfoUtil';
import { ProgressBar } from 'primereact/progressbar';
import { ServiceProvider } from '../../../services';
import { SplitButton } from 'primereact/splitbutton';
import UserPosition from '../../../enums/UserPosition';
import _ from 'lodash';
import dayjs from 'dayjs';
import { myInfoSelector } from '../../../recoil/selectors';
import { saveAs } from 'file-saver';
import { useDropzone } from 'react-dropzone';
import usePrevious from '../../../hooks/usePrevious';
import { useRecoilValueLoadable } from 'recoil';

const chance = new Chance();
const codeService = ServiceProvider.code;
const shopCheckPriceService = ServiceProvider.shopCheckPrice;

const ShopCheckPriceDialog = ({
  userInfo,
  checkPriceTable: {
    associationId = 0,
    enterpriseId = 0,
    shopId = 0,
    priceTableId = 0,
    isDeletable = 'N',
  },
  onHide = null,
}) => {
  const {
    myAssociationId,
    myEnterpriseId,
    myShopId,
    myUserId,
    myRoleCode,
    myUserPosition,
  } = useMemo(() => MyInfoUtil.unpack(userInfo), [userInfo]);
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);

  const [selectedAssociationId, setSelectedAssociationId] = useState(
    myAssociationId || 0
  );
  const [selectedEnterpriseId, setSelectedEnterpriseId] = useState(
    myEnterpriseId || 0
  );
  const [selectedShopId, setSelectedShopId] = useState(shopId || myShopId || 0);
  const [codes, setCodes] = useState({});

  const [checkPriceTable, setCheckPriceTable] = useState(null);
  const previousCheckPriceTable = usePrevious(checkPriceTable);
  const [currentItem, setCurrentItem] = useState(null);
  const [minStartDate, setMinStartDate] = useState(null);

  const [showConfirmPopup, setShowConfirmPopup] = useState({
    submit: false,
    delete: false,
  });
  const [loading, setLoading] = useState(false);
  const [isSubmitDone, setIsSubmitDone] = useState(false);

  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    noKeyboard: true,
    multiple: false,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xls',
        '.xlsx',
      ],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
    },
    onDrop: (acceptedFiles) => {
      console.log(acceptedFiles);

      if (acceptedFiles[0]) {
        const workbook = new ExcelJS.Workbook();
        const reader = new FileReader();

        reader.readAsArrayBuffer(acceptedFiles[0]);
        reader.onload = () => {
          const buffer = reader.result;

          workbook.xlsx.load(buffer).then((workbook) => {
            console.log(workbook, 'workbook instance');
            const worksheet =
              workbook.getWorksheet('점검료양식') ||
              workbook.getWorksheet('점검료테이블');

            if (worksheet) {
              let tableData = [];

              worksheet.eachRow((row, rowIndex) => {
                if (rowIndex > 3) {
                  // exceljs - row.values의 첫번째 데이터가 항상 null임
                  const [, priceType, title, price] = row.values;
                  tableData.push({
                    id: chance.hash(),
                    priceType: _.get(
                      _.find(VEHICLE_TYPE_LEVEL1, { label: priceType }),
                      'value'
                    ),
                    title: title || '이름없음',
                    price: _.toNumber(
                      `${_.isEmpty(_.toString(price)) ? 0 : price}`
                    ),
                  });
                }
              });

              setCheckPriceTable((ps) => ({ ...ps, tableData }));
            } else {
              window.cerp.toast.warn(
                '점검료 양식 오류',
                '올바른 점검료 양식이 아닙니다.'
              );
            }
          });
        };
      }
    },
  });

  async function onSubmit(confirmData) {
    // console.log(checkPriceTable, confirmData);
    setLoading(true);

    const requestBody = {
      associationId: selectedAssociationId,
      enterpriseId: selectedEnterpriseId,
      shopId: selectedShopId,
      ...confirmData,
      tableData: JSON.stringify(_.get(checkPriceTable, 'tableData')),
    };

    // console.log(checkPriceTable, requestBody);
    const isInitialSubmit = checkPriceTable === null;

    try {
      const { data } = await shopCheckPriceService.register(requestBody);
      const tableData = JSON.parse(data.tableData);

      window.cerp.toast.success(
        `점검료 ${isInitialSubmit ? '등록' : '수정'} 완료`,
        `점검료를 ${isInitialSubmit ? '등록' : '수정'}했습니다.`
      );

      setCheckPriceTable({ ...data, tableData });
      setIsSubmitDone(true);
    } catch (error) {
      window.cerp.dialog.error(
        `점검료 ${isInitialSubmit ? '등록' : '수정'} 실패`,
        `[${error?.code}] ${error?.message}`
      );
    }

    setLoading(false);
  }

  async function checkStartDate() {
    setLoading(true);

    try {
      const { data } = await shopCheckPriceService.checkStartDate(
        selectedShopId
      );
      const { minStartDate } = data;

      const current = dayjs();
      const resDate = dayjs(minStartDate);
      const diff = current.diff(resDate);

      if (diff >= 0) {
        const tomorrow = current
          .add(1, 'day')
          .startOf('day')
          .format('YYYY-MM-DD');
        setMinStartDate(tomorrow);
      } else {
        setMinStartDate(minStartDate);
      }
    } catch (error) {
      //TODO: error가 나는 경우 생각해보고 적절한 에러 다이얼로그 띄우기
      // window.cerp.dialog.error(
      //   `점검료 ${isInitialSubmit ? '등록' : '수정'} 실패`,
      //   `[${error?.code}] ${error?.message}`
      // );
    }

    setLoading(false);
  }

  function initCheckPriceTable() {
    setCheckPriceTable(null);
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      if (myInfoLoadable.state === 'hasValue') {
        const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);

        if (priceTableId === 0) {
          const [associations, associationId, association] =
            await CodeUtil.getAssociationCodes(myInfo, {
              preSelectedAssociationId: myAssociationId,
            });
          const [enterprises, enterpriseId, enterprise] =
            await CodeUtil.getEnterpriseCodes(myInfo, {
              isParentEmpty: _.isEmpty(associations),
              associationId: myAssociationId,
            });
          const [shops, shopId, shop] = await CodeUtil.getShopCodes(myInfo, {
            isParentEmpty: _.isEmpty(enterprises),
            enterpriseId: myEnterpriseId,
          });

          setCodes((ps) => ({ ...ps, associations, enterprises, shops }));
          setSelectedAssociationId(associationId);
          setSelectedEnterpriseId(enterpriseId);
          setSelectedShopId(shopId);
        } else {
          try {
            const { data } = await shopCheckPriceService.getData(priceTableId);
            const tableData = JSON.parse(data.tableData);

            const {
              associationId: preSelectedAssociationId,
              enterpriseId: preSelectedEnterpriseId,
              shopId: preSelectedShopId,
            } = data;

            // const [[associations], [enterprises], [shops]] = await Promise.all([
            //   getAssociationCodes({ preSelectedAssociationId: associationId }),
            //   getEnterpriseCodes({
            //     associationId,
            //     preSelectedEnterpriseId: enterpriseId,
            //   }),
            //   getShopCodes({ enterpriseId, preSelectedShopId: shopId }),
            // ]);

            const [
              [associations, associationId, association],
              [enterprises, enterpriseId, enterprise],
              [shops, shopId, shop],
            ] = await Promise.all([
              CodeUtil.getAssociationCodes(myInfo, {
                preSelectedAssociationId,
              }),
              CodeUtil.getEnterpriseCodes(myInfo, {
                isParentEmpty: false,
                associationId: myAssociationId,
                preSelectedEnterpriseId,
              }),
              CodeUtil.getShopCodes(myInfo, {
                isParentEmpty: false,
                enterpriseId: myEnterpriseId,
                preSelectedShopId,
              }),
            ]);

            setCodes({ associations, enterprises, shops });
            setSelectedAssociationId(associationId);
            setSelectedEnterpriseId(enterpriseId);
            setSelectedShopId(shopId);
            setCheckPriceTable({ ...data, tableData });
          } catch (error) {
            window.cerp.dialog.error(
              '점검료 조회 실패',
              `[${error?.code}] ${error?.message}`
            );
          }
        }
      }

      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myInfoLoadable.contents, priceTableId]);

  return (
    <>
      {(() => {
        switch (myInfoLoadable.state) {
          case 'hasValue':
            const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);
            return (
              <Dialog
                modal
                maximizable
                blockScroll
                breakpoints={{ '960px': '90vw' }}
                visible
                style={{ width: '70vw' }}
                onHide={onHide}
                header={
                  priceTableId === 0
                    ? '점검료 테이블 신규 등록'
                    : '점검료 테이블 수정'
                }
                footer={
                  <div className="flex flex-auto align-items-start justify-content-start pt-4">
                    <Button
                      type="button"
                      label="닫기"
                      className="p-button-outlined"
                      onClick={onHide}
                    />
                    {priceTableId === 0 && !isSubmitDone && (
                      <div className="flex flex-auto flex-wrap align-items-center justify-content-end gap-1">
                        <ConfirmDialog
                          visible={showConfirmPopup['reset']}
                          header="확인"
                          message="변경된 내용을 초기화 하시겠습니까?"
                          icon="pi pi-exclamation-triangle"
                          acceptLabel="네"
                          acceptIcon="pi pi-check"
                          accept={() => {
                            initCheckPriceTable();
                          }}
                          rejectLabel="아니오"
                          reject={() =>
                            setShowConfirmPopup((ps) => ({
                              ...ps,
                              reset: false,
                            }))
                          }
                          onHide={() =>
                            setShowConfirmPopup((ps) => ({
                              ...ps,
                              reset: false,
                            }))
                          }
                        />
                        <Button
                          id="btn-reset"
                          className="p-button-outlined"
                          label="초기화"
                          icon="pi pi-undo"
                          autoFocus
                          onClick={() =>
                            setShowConfirmPopup((ps) => ({
                              ...ps,
                              reset: true,
                            }))
                          }
                        />

                        {showConfirmPopup['submit'] && (
                          <CheckPriceSaveConfirmDialog
                            isUpdate={checkPriceTable !== null}
                            minStartDate={minStartDate}
                            onHide={(confirmData) => {
                              setShowConfirmPopup((ps) => ({
                                ...ps,
                                submit: false,
                              }));
                              setMinStartDate(null);
                              if (confirmData) onSubmit(confirmData);
                            }}
                          />
                        )}
                        <Button
                          id="btn-save"
                          label="저장"
                          icon="pi pi-save"
                          autoFocus
                          tooltip="적용할 점검장을 선택해주세요."
                          tooltipOptions={{
                            position: 'top',
                            autoHide: false,
                            showOnDisabled: true,
                          }}
                          disabled={
                            !(
                              selectedAssociationId > 0 &&
                              selectedEnterpriseId > 0 &&
                              selectedShopId > 0
                            )
                          }
                          onClick={async () => {
                            await checkStartDate();
                            setShowConfirmPopup((ps) => ({
                              ...ps,
                              submit: true,
                            }));
                          }}
                        />
                      </div>
                    )}
                    {priceTableId > 0 && isDeletable === YN.YES && (
                      <div className="flex flex-auto flex-wrap align-items-center justify-content-end gap-1">
                        <ConfirmDialog
                          visible={showConfirmPopup['delete']}
                          header="확인"
                          message="점검료 테이블을 삭제하시겠습니까?"
                          icon="pi pi-exclamation-triangle"
                          acceptLabel="네"
                          acceptIcon="pi pi-check"
                          acceptClassName="p-button-danger"
                          accept={async () => {
                            const { data, error } =
                              await shopCheckPriceService.delete(priceTableId);
                            console.log(data, error);

                            if (data) {
                              window.cerp.toast.success(
                                `점검료 테이블 삭제 완료`,
                                `점검료 테이블을 삭제했습니다.`
                              );
                              onHide();
                            }

                            if (error)
                              window.cerp.dialog.error(
                                '점검료 테이블 삭제 실패',
                                `[${error?.code}] ${error?.message}`
                              );
                          }}
                          rejectLabel="아니오"
                          reject={() =>
                            setShowConfirmPopup((ps) => ({
                              ...ps,
                              delete: false,
                            }))
                          }
                          onHide={() =>
                            setShowConfirmPopup((ps) => ({
                              ...ps,
                              delete: false,
                            }))
                          }
                        />
                        <Button
                          type="button"
                          label="삭제"
                          icon="pi pi-trash"
                          className="p-button-danger"
                          onClick={() =>
                            setShowConfirmPopup((ps) => ({
                              ...ps,
                              delete: true,
                            }))
                          }
                        />
                      </div>
                    )}
                  </div>
                }
              >
                <div className="bg-white sticky z-1 top-0 mb-3">
                  <div className="grid">
                    <div className="col-12 sm:col-6 lg:col-3">
                      <div className="field mb-0">
                        <label>소속협회</label>
                        <Dropdown
                          className="w-full"
                          value={selectedAssociationId}
                          options={codes.associations}
                          onChange={async ({ value }) => {
                            const [enterprises, enterpriseId, enterprise] =
                              await CodeUtil.getEnterpriseCodes(myInfo, {
                                isParentEmpty: _.isEmpty(
                                  _.get(codes, 'associations')
                                ),
                                associationId: value,
                              });
                            const [shops, shopId, shop] =
                              await CodeUtil.getShopCodes(myInfo, {
                                isParentEmpty: _.isEmpty(enterprises),
                              });
                            setCodes((ps) => ({ ...ps, enterprises, shops }));
                            setSelectedAssociationId(value);
                            setSelectedEnterpriseId(enterpriseId);
                            setSelectedShopId(shopId);
                          }}
                          disabled={
                            !_.includes([UserPosition.Erp], myUserPosition) ||
                            priceTableId > 0 ||
                            isSubmitDone
                          }
                          filter
                          placeholder={
                            loading ? (
                              <>
                                <i className="pi pi-spin pi-spinner m-0 mr-2" />
                                조회하고 있습니다...
                              </>
                            ) : (
                              '협회가 없습니다.'
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 sm:col-6 lg:col-3">
                      <div className="field mb-0">
                        <label>소속점검법인</label>
                        <Dropdown
                          className="w-full"
                          value={selectedEnterpriseId}
                          options={codes.enterprises}
                          onChange={async ({ value }) => {
                            const [shops, shopId, shop] =
                              await CodeUtil.getShopCodes(myInfo, {
                                isParentEmpty: _.isEmpty(
                                  _.get(codes, 'enterprises')
                                ),
                                enterpriseId: value,
                              });
                            setCodes((ps) => ({ ...ps, shops }));
                            setSelectedEnterpriseId(value);
                            setSelectedShopId(shopId);
                          }}
                          disabled={
                            !_.includes(
                              [UserPosition.Erp, UserPosition.Association],
                              myUserPosition
                            ) ||
                            priceTableId > 0 ||
                            isSubmitDone
                          }
                          filter
                          placeholder={
                            loading ? (
                              <>
                                <i className="pi pi-spin pi-spinner m-0 mr-2" />
                                조회하고 있습니다...
                              </>
                            ) : (
                              '점검법인이 없습니다.'
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 sm:col-6 lg:col-3">
                      <div className="field mb-0">
                        <label>소속점검장</label>
                        <Dropdown
                          className="w-full"
                          value={selectedShopId}
                          options={codes.shops}
                          onChange={async ({ value }) => {
                            setSelectedShopId(value);
                          }}
                          disabled={
                            !_.includes(
                              [
                                UserPosition.Erp,
                                UserPosition.Association,
                                UserPosition.Enterprise,
                              ],
                              myUserPosition
                            ) ||
                            priceTableId > 0 ||
                            isSubmitDone
                          }
                          filter
                          placeholder={
                            loading ? (
                              <>
                                <i className="pi pi-spin pi-spinner m-0 mr-2" />
                                조회하고 있습니다...
                              </>
                            ) : (
                              '점검장이 없습니다.'
                            )
                          }
                        />
                      </div>
                    </div>
                    <div className="col-12 sm:col-6 lg:col-3">
                      <div className="w-full h-full flex flex-row align-items-end justify-content-start">
                        {priceTableId === 0 && !isSubmitDone ? (
                          <>
                            <div
                              {...getRootProps({
                                className: '',
                                style: { wordBreak: 'keep-all' },
                              })}
                            >
                              <input {...getInputProps()} />
                            </div>
                            <SplitButton
                              className="w-full"
                              buttonClassName="w-full p-button-outlined"
                              menuButtonClassName="p-button-outlined"
                              label="엑셀불러오기"
                              icon="pi pi-folder-open"
                              onClick={open}
                              model={[
                                {
                                  label: '양식다운로드',
                                  icon: 'pi pi-file-excel',
                                  command: async (e) => {
                                    const workbook = new ExcelJS.Workbook({
                                      useStyles: true,
                                    });
                                    const worksheet =
                                      workbook.addWorksheet('점검료양식');

                                    worksheet.mergeCells('A1', 'C1');
                                    worksheet.getCell('A1').value =
                                      '※ 임의로 양식을 수정하지 마세요!';
                                    worksheet.getCell('A1').font = {
                                      size: 14,
                                      bold: true,
                                      color: { argb: 'ffb32d23' },
                                    };
                                    worksheet.getCell('A1').alignment = {
                                      horizontal: 'center',
                                      vertical: 'middle',
                                    };
                                    // worksheet.getCell('A1').fill = {
                                    //   type: 'pattern',
                                    //   pattern: 'solid',
                                    //   fgColor: {argb: 'FFFFFF00'},
                                    //   bgColor: {argb: 'FF0000FF'},
                                    // };

                                    worksheet.getRow(3).values = [
                                      '점검료 구분',
                                      '점검료 이름',
                                      '점검료',
                                    ];
                                    worksheet.getCell('A3').alignment = {
                                      horizontal: 'center',
                                      vertical: 'middle',
                                    };
                                    worksheet.getCell('B3').alignment = {
                                      horizontal: 'center',
                                      vertical: 'middle',
                                    };
                                    worksheet.getCell('C3').alignment = {
                                      horizontal: 'center',
                                      vertical: 'middle',
                                    };

                                    worksheet.columns = [
                                      {
                                        key: 'priceType',
                                        width: 15,
                                        style: {
                                          alignment: {
                                            horizontal: 'center',
                                            vertical: 'middle',
                                          },
                                        },
                                      },
                                      {
                                        key: 'title',
                                        width: 50,
                                        style: {
                                          alignment: {
                                            horizontal: 'center',
                                            vertical: 'middle',
                                          },
                                        },
                                      },
                                      {
                                        key: 'price',
                                        width: 20,
                                        style: {
                                          alignment: {
                                            horizontal: 'center',
                                            vertical: 'middle',
                                          },
                                        },
                                      },
                                    ];

                                    for (let i = 4; i < 100; i++) {
                                      worksheet.getCell(
                                        'A' + i
                                      ).dataValidation = {
                                        type: 'list',
                                        allowBlank: false,
                                        formulae: [
                                          `"${_.join(
                                            _.map(VEHICLE_TYPE_LEVEL1, 'label'),
                                            ','
                                          )}"`,
                                        ],
                                        showErrorMessage: true,
                                        errorStyle: 'error',
                                      };
                                    }

                                    const mimeType = {
                                      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                    };
                                    const buffer =
                                      await workbook.xlsx.writeBuffer();
                                    const blob = new Blob([buffer], mimeType);
                                    saveAs(blob, '점검료양식.xlsx');
                                  },
                                },
                              ]}
                            />
                          </>
                        ) : (
                          <Button
                            icon="pi pi-download"
                            label="점검료 테이블 내려받기"
                            className="w-full p-button-outlined"
                            onClick={async (e) => {
                              const workbook = new ExcelJS.Workbook();
                              const worksheet =
                                workbook.addWorksheet('점검료테이블');

                              worksheet.mergeCells('A1', 'C1');
                              worksheet.getCell('A1').value =
                                '※ 임의로 양식을 수정하지 마세요!';
                              worksheet.getCell('A1').font = {
                                size: 14,
                                bold: true,
                                color: { argb: 'ffb32d23' },
                              };
                              worksheet.getCell('A1').alignment = {
                                horizontal: 'center',
                                vertical: 'middle',
                              };

                              worksheet.getRow(3).values = [
                                '점검료 구분',
                                '점검료 이름',
                                '점검료',
                              ];
                              worksheet.getCell('A3').alignment = {
                                horizontal: 'center',
                                vertical: 'middle',
                              };
                              worksheet.getCell('B3').alignment = {
                                horizontal: 'center',
                                vertical: 'middle',
                              };
                              worksheet.getCell('C3').alignment = {
                                horizontal: 'center',
                                vertical: 'middle',
                              };

                              worksheet.columns = [
                                {
                                  key: 'priceType',
                                  width: 15,
                                  style: {
                                    alignment: {
                                      horizontal: 'center',
                                      vertical: 'middle',
                                    },
                                  },
                                },
                                {
                                  key: 'title',
                                  width: 50,
                                  style: {
                                    alignment: {
                                      horizontal: 'center',
                                      vertical: 'middle',
                                    },
                                  },
                                },
                                {
                                  key: 'price',
                                  width: 20,
                                  style: {
                                    alignment: {
                                      horizontal: 'center',
                                      vertical: 'middle',
                                    },
                                  },
                                },
                              ];

                              //* checkPriceTable.tableData 가져와서
                              _.forEach(checkPriceTable.tableData, (data) => {
                                let rowData = {
                                  priceType: '',
                                  title: '',
                                  price: '',
                                };

                                for (let field in data) {
                                  if (field !== 'id' && field !== 'priceType') {
                                    rowData[field] = data[field];
                                  }
                                  if (field === 'priceType') {
                                    rowData[field] = _.get(
                                      _.find(VEHICLE_TYPE_LEVEL1, {
                                        value: data[field],
                                      }),
                                      'label'
                                    );
                                  }
                                }
                                worksheet.addRow(rowData);
                              });

                              for (let i = 4; i < 100; i++) {
                                worksheet.getCell('A' + i).dataValidation = {
                                  type: 'list',
                                  allowBlank: false,
                                  formulae: [
                                    `"${_.join(
                                      _.map(VEHICLE_TYPE_LEVEL1, 'label'),
                                      ','
                                    )}"`,
                                  ],
                                  showErrorMessage: true,
                                  errorStyle: 'error',
                                };
                              }

                              const mimeType = {
                                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                              };
                              const buffer = await workbook.xlsx.writeBuffer();
                              const blob = new Blob([buffer], mimeType);
                              saveAs(blob, '점검료테이블.xlsx');
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <BlockUI
                  template={
                    loading && (
                      <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
                    )
                  }
                  blocked={loading}
                >
                  <DataTable
                    size="small"
                    value={_.get(checkPriceTable, 'tableData')}
                    header={
                      <div className="flex flex-row align-items-center justify-content-between">
                        <div className="font-bold text-lg">점검료 리스트</div>
                        {priceTableId === 0 && (
                          <div className="flex flex-row align-items-center justify-content-between gap-1">
                            <Button
                              type="button"
                              label="점검료 추가"
                              icon="pi pi-plus"
                              onClick={() => setCurrentItem({})}
                            />
                          </div>
                        )}
                      </div>
                    }
                    responsiveLayout="scroll"
                    showGridlines
                    resizableColumns
                    className="border-1 border-gray-50"
                    emptyMessage="등록된 점검료 없음"
                  >
                    {/*<Column field="id" header="ID" />*/}
                    <Column
                      field="priceType"
                      header="점검료 구분"
                      body={({ priceType }) => columnCheckPriceType(priceType)}
                    />
                    <Column field="title" header="점검료 이름" />
                    <Column
                      className="text-right"
                      field="price"
                      header="금액"
                      body={({ price }) =>
                        new Intl.NumberFormat('ko-KR', {
                          style: 'decimal',
                        }).format(price)
                      }
                    />
                    {priceTableId === 0 && (
                      <Column
                        className="w-1rem"
                        body={(rowData, column) => (
                          <div className="flex flex-row align-items-center justify-content-between gap-1">
                            <Button
                              type="button"
                              className="p-button-rounded"
                              icon="pi pi-pencil"
                              onClick={() => setCurrentItem(rowData)}
                            />
                            <Button
                              type="button"
                              className="p-button-rounded p-button-danger"
                              icon="pi pi-trash"
                              onClick={() => {
                                const clone = [
                                  ..._.get(checkPriceTable, 'tableData'),
                                ];
                                clone.splice(column.rowIndex, 1);
                                setCheckPriceTable((ps) => ({
                                  ...ps,
                                  tableData: clone,
                                }));
                              }}
                            />
                          </div>
                        )}
                      />
                    )}
                  </DataTable>

                  {currentItem !== null && (
                    <CheckPriceItemDialog
                      userInfo={userInfo}
                      priceItem={currentItem}
                      onHide={(isNew, data) => {
                        if (data) {
                          if (isNew) {
                            setCheckPriceTable((ps) => {
                              const tableData = _.get(ps, 'tableData') || [];
                              return { ...ps, tableData: [...tableData, data] };
                            });
                          } else {
                            const index = _.findIndex(
                              _.get(checkPriceTable, 'tableData') || [],
                              { id: data.id }
                            );
                            setCheckPriceTable((ps) => {
                              const tableData = _.get(ps, 'tableData');
                              tableData[index] = data;
                              return { ...ps, tableData };
                            });
                          }
                        }
                        setCurrentItem(null);
                      }}
                    />
                  )}
                </BlockUI>
              </Dialog>
            );

          case 'loading':
            return <ProgressBar mode="indeterminate" />;

          case 'hasError':
            return null;

          default:
        }
      })()}
    </>
  );
};

export default ShopCheckPriceDialog;
