import * as constants from '../constants/Constants';

import { YN } from '../constants/Constants';
import _ from 'lodash';
import dayjs from 'dayjs';

const columnUseYn = (columnData) =>
  columnData === YN.YES ? '사용중' : '미사용';
const columnCheckPriceType = (columnData) => {
  return _.find(constants.VEHICLE_TYPE_LEVEL1, { value: columnData })?.label;
};
const columnEnterpriseType = (columnData) => {
  return _.find(constants.ENTERPRISE_TYPES, { value: columnData })?.label;
};
const columnCheckState = (columnData) => {
  return _.find(constants.CHECK_STATES, { value: columnData })?.label;
};
const columnTransferState = (columnData) => {
  return _.find(constants.TRANSFER_STATES, { value: columnData })?.label;
};
const columnDateTime = (dateTime) =>
  dateTime ? dayjs(dateTime).format('YYYY-MM-DD HH:mm:ss') : '';
const columnNumberWithCommas = (columnData) =>
  _.toString(columnData).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const exportExcel = (data, fileName) => {
  // data가 비었을 때 다이얼로그 띄우고 엑셀 만들지 말기
  const ERROR_CODE = 'No data';
  const ERROR_MESSAGE = '데이터가 없습니다.';
  if (data.length === 0) {
    window.cerp.dialog.error(
      '엑셀 파일을 다운로드할 수 없습니다.',
      `[${ERROR_CODE}] ${ERROR_MESSAGE}`
    );
    return;
  }

  import('xlsx').then((xlsx) => {
    const worksheet = xlsx.utils.json_to_sheet(data);
    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
    const excelBuffer = xlsx.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    saveAsExcelFile(excelBuffer, fileName || 'export');
  });
};

const saveAsExcelFile = (buffer, fileName) => {
  import('file-saver').then((FileSaver) => {
    let EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(
      data,
      fileName + '_' + dayjs().format('YYYYMMDDHHmm') + EXCEL_EXTENSION
    );
  });
};

export {
  columnUseYn,
  columnCheckPriceType,
  columnCheckState,
  columnTransferState,
  columnDateTime,
  columnEnterpriseType,
  exportExcel,
  columnNumberWithCommas,
};
