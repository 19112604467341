import * as Button from './../../components/Atoms/Button';
import * as filesize from 'filesize';

import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { Badge } from 'primereact/badge';
import BoardPostType from '../../enums/BoardPostType';
import { ConfirmDialog } from 'primereact/confirmdialog';
import DOMPurify from 'dompurify';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import FileState from '../../enums/FileState';
import MultiEditor from '../../components/Editor/MultiEditor';
import { ProgressBar } from 'primereact/progressbar';
import { ServiceProvider } from '../../services';
import UserRoleType from '../../enums/UserRoleType';
import _ from 'lodash';
import { myInfoSelector } from '../../recoil/selectors';
import { touchUIState } from '../../recoil/atoms';

const boardService = ServiceProvider.board;

const Post = () => {
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const history = useHistory();
  const params = useParams();
  const { service, postId } = params;
  const postType = _.get(_.find(BoardPostType, { service }), 'notice');

  const touchUI = useRecoilValue(touchUIState);
  const [showConfirmPopup, setShowConfirmPopup] = useState({
    delete: {},
  });

  const [currentPostId, setCurrentPostId] = useState(null);

  const [data, setData] = useState({});
  const [fileData, setFileData] = useState([]);

  function checkRegistAuth(userInfo) {
    const { roleCode } = userInfo;
    // 어드민, 협회
    const ACCESS_PERMISSION = _.filter(UserRoleType, function (r) {
      return (
        r.value === 'CERP_ADM' ||
        r.value === 'A_MST' ||
        r.value === 'A_ADM' ||
        r.value === 'A_OFF'
      );
    });

    return _.findIndex(ACCESS_PERMISSION, { value: roleCode }) >= 0;
  }

  async function getData(postId) {
    try {
      const {
        data: { boardData, boardFilesData },
      } = await boardService.getData({
        seq: postId,
        postType: postType,
      });

      setData({ ...boardData });
      setFileData(
        _.map(boardFilesData, (file) => ({
          ...file,
          state: FileState.Loaded,
        })) || []
      );
    } catch (error) {
      window.cerp.dialog.error(
        '게시글 조회 실패',
        `[${error?.code}]${error?.message}`
      );
    }
  }

  function downloadFile(url) {
    window.open(process.env.REACT_APP_S3_BASE_URL + url);
  }

  async function onDelete(id) {
    try {
      const { data } = await boardService.delete({
        seq: id,
        postType,
      });

      window.cerp.toast.success(
        '게시글 삭제 완료',
        '선택하신 공지사항 게시글이 삭제되었습니다.'
      );

      // history.replace('/board/notice/list');
      history.replace(`/${service}/board/notice/list`);
    } catch (error) {
      window.cerp.dialog.error(
        '게시글 삭제 실패',
        `[${error?.code}]${error?.message}`
      );
    }
  }

  useEffect(() => {
    (async () => {
      if (postId) {
        await getData(postId);
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId]);

  return (
    <div className="grid">
      <div className="col-12">
        {(() => {
          switch (myInfoLoadable.state) {
            case 'hasValue':
              return (
                <>
                  {checkRegistAuth(myInfoLoadable.contents) && (
                    <div className="card flex justify-content-end gap-1">
                      <Button.Delete
                        onDelete={() =>
                          setShowConfirmPopup((ps) => ({
                            ...ps,
                            delete: { [`${postId}`]: true },
                          }))
                        }
                      />
                      <ConfirmDialog
                        visible={showConfirmPopup.delete[postId]}
                        header="확인"
                        message={<p>공지사항 게시글을 삭제하시겠습니까?</p>}
                        icon="pi pi-exclamation-triangle"
                        acceptLabel="네"
                        rejectLabel="아니오"
                        accept={async () => await onDelete(postId)}
                        reject={() =>
                          setShowConfirmPopup((ps) => ({
                            ...ps,
                            delete: false,
                          }))
                        }
                        onHide={() =>
                          setShowConfirmPopup((ps) => ({
                            ...ps,
                            delete: false,
                          }))
                        }
                      />
                      <Button.Default
                        label="수정"
                        icon="pi pi-pencil"
                        onClick={() => setCurrentPostId(postId)}
                      />
                    </div>
                  )}
                  <div className="card">
                    <div className="title_wrapper flex">
                      <div className="mr-4 text-center w-5rem font-bold">
                        제목
                      </div>
                      <span>{_.get(data, 'title')}</span>
                    </div>
                    <Divider />
                    <div className="writer flex">
                      <div className="mr-4 text-center w-5rem font-bold">
                        작성자
                      </div>
                      <span>{_.get(data, 'regName')}</span>
                    </div>
                    <Divider />
                    <div className="views flex">
                      <div className="mr-4 text-center w-5rem font-bold">
                        조회수
                      </div>
                      <span>{_.get(data, 'count')}</span>
                    </div>
                    <Divider />
                    <div className="files_wrapper flex">
                      <div className="mr-4 text-center w-5rem font-bold">
                        첨부파일
                      </div>
                      {fileData && fileData.length > 0 && (
                        <div className="flex flex-column">
                          {fileData.map((file, idx) => {
                            if (_.get(file, 'state') !== FileState.Deleted) {
                              const { originalFileName, fileSize, filePath } =
                                file;

                              return (
                                <div
                                  className="flex align-items-center gap-1 mb-2"
                                  key={`files_${idx}`}
                                  onClick={() => downloadFile(filePath)}
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                >
                                  <span>{originalFileName}</span>
                                  <Badge
                                    value={`${filesize(fileSize, {
                                      round: 1,
                                      standard: 'jedec',
                                    })}`}
                                    severity="info"
                                  />
                                  <i className="pi pi-download text-xl"></i>
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      )}
                    </div>
                    <Divider />
                    <div className="content_wrapper">
                      {/* <pre>{parseStringToDom(_.get(data, 'content'))}</pre> */}
                      <div
                        className="p-5"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(_.get(data, 'content')),
                        }}
                      />
                    </div>
                  </div>
                  <div className="button_wrapper flex justify-content-end w-full mb-4">
                    <Button.Default
                      icon="pi pi-list"
                      label="목록 보기"
                      onClick={() =>
                        history.replace(`/${service}/board/notice/list`)
                      }
                    />
                  </div>

                  {currentPostId !== null && (
                    <Dialog
                      visible
                      blockScroll
                      header={`${
                        service === BoardPostType.CustomerApp.service
                          ? '소비자 APP '
                          : ''
                      }공지사항 ${currentPostId === 0 ? '신규 등록' : '수정'}`}
                      style={{
                        width: '80vw',
                        height: '80vh',
                      }}
                      onHide={async () => {
                        await getData(postId);
                        setCurrentPostId(null);
                      }}
                    >
                      <MultiEditor
                        service={service}
                        postType={_.get(data, 'postType')}
                        postId={currentPostId}
                        userInfo={myInfoLoadable.contents}
                        onHide={async () => {
                          await getData(postId);
                          setCurrentPostId(null);
                        }}
                      />
                    </Dialog>
                  )}
                </>
              );
            case 'loading':
              return <ProgressBar mode="indeterminate" />;
            case 'hasError':
              return null;
            default:
          }
        })()}
      </div>
    </div>
  );
};

export default Post;
