import * as Button from './../../../components/Atoms/Button';
import * as ConfirmDialog from './../../../components/Dialog/ConfirmDialog';

import { COUNT_PER_PAGE, MAX_DATE, YN } from '../../../constants/Constants';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  columnDateTime,
  columnUseYn,
  exportExcel,
} from '../../../utils/dataTableUtil';

import { CodeUtil } from '../../../utils/CodeUtil';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { MultiSelect } from 'primereact/multiselect';
import { MyInfoUtil } from '../../../utils/myInfoUtil';
import { Panel } from 'primereact/panel';
import { ProgressBar } from 'primereact/progressbar';
import { RadioButton } from 'primereact/radiobutton';
import SearchMenu from '../../../components/Menu/SearchMenu';
import ServiceMenu from '../../../components/Menu/ServiceMenu';
import { ServiceProvider } from '../../../services';
import ShopCheckPriceDialog from './ShopCheckPriceDialog';
import UserPosition from '../../../enums/UserPosition';
import UserRoleType from '../../../enums/UserRoleType';
import _ from 'lodash';
import classNames from 'classnames';
import { myInfoSelector } from '../../../recoil/selectors';
import { useAuthorizedFetch } from '../../../hooks/useAuthorizedFetch';
import { useRecoilValueLoadable } from 'recoil';

const DEFAULT_SEARCH_CONDITIONS = {
  associations: [],
  enterprises: [],
  shops: [],
  useYN: 'Y',
};
const DEFAULT_LAZY_PARAMS = {
  first: 0,
  rows: 10,
  page: 0,
  sortField: null,
  sortOrder: null,
  filters: {
    name: { value: '', matchMode: 'contains' },
    'country.name': { value: '', matchMode: 'contains' },
    company: { value: '', matchMode: 'contains' },
    'representative.name': { value: '', matchMode: 'contains' },
  },
};

const shopCheckPriceService = ServiceProvider.shopCheckPrice;

const ShopCheckPriceList = () => {
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const {
    myAssociationId,
    myEnterpriseId,
    myShopId,
    myUserId,
    myRoleCode,
    myUserPosition,
  } = useMemo(
    () => MyInfoUtil.unpack(myInfoLoadable.contents),
    [myInfoLoadable.contents]
  );

  const [searchConditions, setSearchConditions] = useState({
    ...DEFAULT_SEARCH_CONDITIONS,
  });
  const [lazyParams, setLazyParams] = useState({ ...DEFAULT_LAZY_PARAMS });
  const { loading, totalRecords, data, getList, initList } = useAuthorizedFetch(
    shopCheckPriceService,
    searchConditions,
    setSearchConditions,
    DEFAULT_SEARCH_CONDITIONS,
    lazyParams.page,
    lazyParams.rows
  );

  const [codes, setCodes] = useState({});
  const [currentShopCheckPriceTable, setCurrentShopCheckPriceTable] =
    useState(null);

  const [isOpenDeletePopups, setIsOpenDeletePopups] = useState({});

  const onDeleteConfirm = async (priceTableId) => {
    try {
      const { data } = await shopCheckPriceService.delete(priceTableId);
      window.cerp.toast.success(
        `점검료 테이블 삭제 완료`,
        `점검료 테이블을 삭제했습니다.`
      );
      await getList(searchConditions);
    } catch (error) {
      window.cerp.dialog.error(
        '점검료 테이블 삭제 실패',
        `[${error?.code}] ${error?.message}`
      );
    }
  };

  function checkRegistAuth(userInfo) {
    const { roleCode } = userInfo;
    // not A_OFF, S_INS, C~
    const ACCESS_PERMISSION = _.filter(UserRoleType, function (r) {
      return (
        r.value === 'A_OFF' ||
        r.value === 'S_INS' ||
        _.startsWith(r.value, 'C_')
      );
    });

    return !(_.findIndex(ACCESS_PERMISSION, { value: roleCode }) >= 0);
  }

  function handleSelectAll(
    selected,
    field,
    updateCodes,
    updateSearchConditions
  ) {
    // 전체 값을 선택한 게 있으면
    // 나머지 옵션에 disabled 처리를 해서 더이상 선택하지 못하도록 함
    // 전체 값 선택 안 했으면
    // disabled 리셋해서 codes 업데이트
    const update = [];

    if (selected && selected.includes('')) {
      update.push(
        ..._.get(codes, `${field}`).map((option) => {
          if (option.value !== '') {
            option.disabled = true;
          }
          return option;
        })
      );

      setCodes((ps) => ({ ...ps, [field]: update, ...updateCodes }));
      setSearchConditions((ps) => ({
        ...ps,
        [field]: [_.get(codes[field], '0.value')],
        ...updateSearchConditions,
      }));
      return;
    }

    update.push(
      ..._.get(codes, `${field}`).map((option) => {
        if (option.disabled) delete option.disabled;
        return option;
      })
    );

    setCodes((ps) => ({ ...ps, [field]: update, ...updateCodes }));

    if (selected === null) {
      setSearchConditions((ps) => ({ ...ps, [field]: '' }));
      return;
    }

    setSearchConditions((ps) => ({
      ...ps,
      [field]: selected,
      ...updateSearchConditions,
    }));
  }

  useEffect(() => {
    (async () => {
      if (myInfoLoadable.state === 'hasValue') {
        const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);

        const [associations, associationId, association] =
          await CodeUtil.getAssociationCodes(myInfo, {
            preSelectedAssociationId: myAssociationId,
          });
        const [enterprises, enterpriseId, enterprise] =
          await CodeUtil.getEnterpriseCodes(myInfo, {
            isParentEmpty: _.isEmpty(associations),
            associationId: myAssociationId,
          });
        const [shops, shopId, shop] = await CodeUtil.getShopCodes(myInfo, {
          isParentEmpty: _.isEmpty(enterprises),
          enterpriseId: myEnterpriseId,
        });

        setCodes((ps) => ({ ...ps, associations, enterprises, shops }));

        await getList({
          ...searchConditions,
          associations: [associationId],
          enterprises: [enterpriseId],
          shops: [shopId],
        });
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myInfoLoadable.state]);

  return (
    <div className="grid">
      <div className="col-12">
        {(() => {
          switch (myInfoLoadable.state) {
            case 'hasValue':
              const myInfo = MyInfoUtil.unpack(myInfoLoadable.contents);
              return (
                <>
                  <SearchMenu
                    onInit={() => initList()}
                    onSearch={() =>
                      getList(
                        searchConditions,
                        _.get(DEFAULT_LAZY_PARAMS, 'page'),
                        _.get(DEFAULT_LAZY_PARAMS, 'rows')
                      )
                    }
                  >
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속협회</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.associations}
                        display="chip"
                        placeholder="전체 협회"
                        value={searchConditions.associations}
                        filter
                        showSelectAll={false}
                        onChange={async ({ value }) => {
                          const [enterprises, enterpriseId, enterprise] =
                            await CodeUtil.getEnterpriseCodes(myInfo, {
                              associationId: value,
                            });
                          const [shops, shopId, shop] =
                            await CodeUtil.getShopCodes(myInfo, {
                              isParentEmpty: _.isEmpty(enterprises),
                            });
                          handleSelectAll(
                            value,
                            'associations',
                            {
                              enterprises,
                              shops,
                            },
                            {
                              enterprises: [],
                              shops: [],
                            }
                          );
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속업체</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.enterprises}
                        display="chip"
                        placeholder="전체 업체"
                        value={searchConditions.enterprises}
                        filter
                        showClear
                        showSelectAll={false}
                        onChange={async ({ value }) => {
                          const [shops, shopId, shop] =
                            await CodeUtil.getShopCodes(myInfo, {
                              isParentEmpty: _.isEmpty(
                                _.get(codes, 'enterprises')
                              ),
                              enterpriseId: value,
                            });
                          handleSelectAll(
                            value,
                            'enterprises',
                            {
                              shops,
                            },
                            {
                              shops: [],
                            }
                          );
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속점검장</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.shops}
                        display="chip"
                        placeholder="전체 점검장"
                        value={searchConditions.shops}
                        filter
                        showSelectAll={false}
                        onChange={({ value }) => {
                          handleSelectAll(value, 'shops');
                        }}
                      />
                    </div>
                    {/* <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">점검장</label>
                      <InputText
                        className="w-full"
                        value={searchConditions.searchText}
                        placeholder="점검장 코드, 이름, 사업자번호로 검색"
                        onChange={({ target: { value } }) =>
                          setSearchConditions((ps) => ({
                            ...ps,
                            searchText: value,
                          }))
                        }
                      />
                    </div> */}
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">사용여부</label>
                      <div className="flex flex-auto flex-wrap align-items-center justify-content-start mt-2 gap-3">
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_useYN_all"
                            name="useYN"
                            value=""
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                useYN: value,
                              }));
                            }}
                            checked={searchConditions.useYN === ''}
                          />
                          <label htmlFor="radio_useYN_all">전체</label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_useYN_y"
                            name="useYN"
                            value="Y"
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                useYN: value,
                              }));
                            }}
                            checked={searchConditions.useYN === YN.YES}
                          />
                          <label htmlFor="radio_useYN_y">사용</label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_useYN_n"
                            name="useYN"
                            value="N"
                            onChange={({ value }) => {
                              setSearchConditions((ps) => ({
                                ...ps,
                                useYN: value,
                              }));
                            }}
                            checked={searchConditions.useYN === YN.NO}
                          />
                          <label htmlFor="radio_useYN_n">미사용</label>
                        </div>
                      </div>
                    </div>
                  </SearchMenu>

                  {checkRegistAuth(myInfoLoadable.contents) && (
                    <Panel className="shadow-1 mb-3">
                      <div className="flex flex-auto flex-wrap align-items-center justify-content-end gap-1">
                        <Button.Default
                          label="점검료 신규 등록"
                          onClick={() =>
                            setCurrentShopCheckPriceTable({
                              enterpriseId: 0,
                              priceTableId: 0,
                            })
                          }
                        />

                        {/* 추가 개발 */}
                        {/* <Button.Default
                        label="엑셀 다운로드"
                        className="p-button-outlined"
                        icon="pi pi-download"
                        onClick={() => exportExcel(data, '점검법인리스트')}
                      /> */}
                      </div>
                    </Panel>
                  )}
                  {/* <ServiceMenu
                    title={'점검료 테이블'}
                    onClickRegisterBtn={() => {
                      setCurrentShopCheckPriceTable({
                        enterpriseId: 0,
                        priceTableId: 0,
                      });
                    }}
                    excelData={{}}
                  /> */}

                  <Panel className="shadow-1 mb-3">
                    <DataTable
                      value={data}
                      lazy
                      rows={lazyParams.rows}
                      first={lazyParams.first}
                      totalRecords={totalRecords}
                      onPage={async (event) => {
                        setLazyParams(event);
                        await getList(searchConditions, event.page, event.rows);
                      }}
                      paginator
                      responsiveLayout="scroll"
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
                      rowsPerPageOptions={COUNT_PER_PAGE}
                      resizableColumns
                      showGridlines
                      loading={loading}
                      rowClassName={({ isNow }) =>
                        isNow === YN.YES ? 'bg-blue-50 font-bold' : ''
                      }
                      emptyMessage="데이터가 없습니다."
                    >
                      <Column
                        header="순번"
                        body={(rowData, column) => (
                          <span>{column.rowIndex + 1}</span>
                        )}
                      />
                      <Column
                        field="title"
                        header="점검료"
                        body={({
                          priceTableId,
                          associationId,
                          enterpriseId,
                          shopId,
                          title,
                          isNow,
                          isDeletable,
                        }) => (
                          <a
                            href="."
                            role="button"
                            onClick={(e) => {
                              e.preventDefault();
                              setCurrentShopCheckPriceTable({
                                associationId,
                                enterpriseId,
                                shopId,
                                priceTableId,
                                isDeletable,
                              });
                            }}
                          >
                            <i
                              className={classNames('mr-1', {
                                'pi pi-check': isNow === YN.YES,
                              })}
                            />
                            {title || '기본점검료테이블'}
                          </a>
                        )}
                      />
                      {(myInfoLoadable.contents.userPosition ===
                        UserPosition.Erp ||
                        myInfoLoadable.contents.userPosition ===
                          UserPosition.Association ||
                        myInfoLoadable.contents.userPosition ===
                          UserPosition.Enterprise) && (
                        <Column
                          field="startDate"
                          header="삭제"
                          className="text-center"
                          body={({
                            priceTableId,
                            enterpriseId,
                            title,
                            isDeletable,
                          }) =>
                            isDeletable === YN.YES && (
                              <>
                                <ConfirmDialog.Delete
                                  visible={
                                    isOpenDeletePopups[
                                      `${enterpriseId}_${priceTableId}`
                                    ]
                                  }
                                  title={`점검료 테이블 ${title}`}
                                  onDelete={() => onDeleteConfirm(priceTableId)}
                                  onClose={() =>
                                    setIsOpenDeletePopups((ps) => ({
                                      ...ps,
                                      [`${enterpriseId}_${priceTableId}`]: false,
                                    }))
                                  }
                                />
                                <Button.Delete
                                  onDelete={() =>
                                    setIsOpenDeletePopups((ps) => ({
                                      ...ps,
                                      [`${enterpriseId}_${priceTableId}`]: true,
                                    }))
                                  }
                                />
                              </>
                            )
                          }
                        />
                      )}
                      <Column
                        field="shopCode"
                        header={
                          <span>
                            점검장
                            <br />
                            점검장코드
                          </span>
                        }
                        body={({ shopCode, shopName }) => (
                          <span>
                            {shopName}
                            <br />[{shopCode}]
                          </span>
                        )}
                      />
                      <Column
                        field="associationCode"
                        header={
                          <span>
                            협회
                            <br />
                            협회코드
                          </span>
                        }
                        body={({ associationCode, associationName }) => (
                          <span>
                            {associationName}
                            <br />[{associationCode}]
                          </span>
                        )}
                      />
                      <Column
                        field="enterpriseCode"
                        header={
                          <span>
                            점검업체
                            <br />
                            점검업체코드
                          </span>
                        }
                        body={({ enterpriseCode, enterpriseName }) => (
                          <span>
                            {enterpriseName}
                            <br />[{enterpriseCode}]
                          </span>
                        )}
                      />
                      <Column
                        field="startDate"
                        header={
                          <span>
                            적용시작일
                            <br />
                            적용만료일
                          </span>
                        }
                        body={({ startDate, endDate }) => (
                          <span>
                            {startDate}
                            <br /> ~ {endDate === MAX_DATE ? '계속' : endDate}
                          </span>
                        )}
                      />
                      <Column field="description" header="비고" />
                      <Column
                        field="useYn"
                        header="사용여부"
                        body={({ useYn }) => columnUseYn(useYn)}
                        bodyClassName="text-center"
                      />
                      <Column
                        field="regTime"
                        header={
                          <div>
                            <span>등록사용자</span>
                            <br />
                            <span>등록일시</span>
                          </div>
                        }
                        body={({ regTime, regByUserName }) => (
                          <div>
                            <span>{regByUserName}</span>
                            <br />
                            <span>{columnDateTime(regTime)}</span>
                          </div>
                        )}
                      />
                      <Column
                        field="modTime"
                        header={
                          <div>
                            <span>수정사용자</span>
                            <br />
                            <span>수정일시</span>
                          </div>
                        }
                        body={({ modTime, modByUserName }) => (
                          <div>
                            <span>{modByUserName}</span>
                            <br />
                            <span>{columnDateTime(modTime)}</span>
                          </div>
                        )}
                      />
                      <Column
                        field="delTime"
                        header={
                          <div>
                            <span>삭제사용자</span>
                            <br />
                            <span>삭제일시</span>
                          </div>
                        }
                        body={({ delTime, delByUserName }) => (
                          <div>
                            <span>{columnDateTime(delTime)}</span>
                            <br />
                            <span>{delByUserName}</span>
                          </div>
                        )}
                      />
                    </DataTable>
                  </Panel>

                  {currentShopCheckPriceTable !== null && (
                    <ShopCheckPriceDialog
                      userInfo={myInfoLoadable.contents}
                      checkPriceTable={currentShopCheckPriceTable}
                      onHide={async () => {
                        setCurrentShopCheckPriceTable(null);
                        await getList(
                          searchConditions,
                          lazyParams.page,
                          lazyParams.rows
                        );
                      }}
                    />
                  )}
                </>
              );
            case 'loading':
              return <ProgressBar mode="indeterminate" />;

            case 'hasError':
              return null;

            default:
          }
        })()}
      </div>
    </div>
  );
};

const comparisonFn = (prevProps, nextProps) => {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ShopCheckPriceList, comparisonFn);
