import React, { useEffect, useState } from 'react';

import { Divider } from 'primereact/divider';
import { Link } from 'react-router-dom';
import { ProgressBar } from 'primereact/progressbar';
import { ServiceProvider } from './services';
import { myInfoSelector } from './recoil/selectors';
import { useRecoilValueLoadable } from 'recoil';

const boardService = ServiceProvider.board;

const StickyBoard = () => {
  const [data, setData] = useState([]);

  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);

  async function getList() {
    try {
      const { data } = await boardService.getMini();

      setData([...data]);
    } catch (error) {
      console.log(error, 'getMini');
    }
  }

  useEffect(() => {
    (async () => {
      await getList();
    })();
  }, []);
  return (
    <>
      {(() => {
        switch (myInfoLoadable.state) {
          case 'hasValue':
            return (
              <div className="shadow-2 border-round-md mt-5">
                <p className="p-3 font-bold bg-blue-900 text-white text-lg border-round-top-md">
                  최근 공지사항
                </p>
                <div className="px-3">
                  {data.length ? (
                    data.map((notice, idx) => {
                      const { seq, title, regDate } = notice;
                      return (
                        <div
                          className="flex flex-column"
                          key={`mini_notice_${idx}`}
                        >
                          <Link to={`/erp/post/${seq}`}>{title}</Link>
                          <small>게시일 : {regDate}</small>
                          <Divider />
                        </div>
                      );
                    })
                  ) : (
                    <div
                      className="empty_data"
                      style={{
                        minHeight: '5rem',
                      }}
                    >
                      <p>게시글이 없습니다.</p>
                    </div>
                  )}
                </div>
              </div>
            );
          case 'loading':
            return <ProgressBar mode="indeterminate" />;
          case 'hasError':
            return null;
          default:
        }
      })()}
    </>
  );
};

export default StickyBoard;
