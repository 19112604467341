import React, { forwardRef } from 'react';

import { InputNumber } from 'primereact/inputnumber';
import { classNames } from 'primereact/utils';

export const GroupTitledInputNumber = forwardRef((props, ref) => {
  const {
    id,
    title = '',
    value,
    onChange,
    readOnly = false,
    disabled = false,
    useGrouping = true,
    suffix = null,
    className = '',
    required = false,
    selectOnFocus = false,
    min,
    max,
    classNameProps,
    ...rest
  } = props;

  return (
    <div className="p-inputgroup h-full flex flex-column">
      <div className="flex w-full h-full">
        <span
          className={`${
            classNameProps ? 'input-border-primary' : 'p-inputgroup-addon'
          } h-full w-full font-medium text-center min-w-3 w-4 max-w-5`}
          style={{
            display: 'table',
          }}
        >
          <span
            style={{
              display: 'table-cell',
              verticalAlign: 'middle',
              wordBreak: 'keep-all',
            }}
          >
            {title}
          </span>
        </span>
        <InputNumber
          id={id}
          inputRef={ref}
          className="w-full"
          inputClassName={classNames('w-full text-right ' + className, {
            'bg-gray-100': readOnly && className === '',
            [className]: readOnly && className !== '',
          })}
          readOnly={readOnly}
          disabled={disabled}
          useGrouping={useGrouping}
          value={value}
          onChange={onChange && ((e) => onChange(e.value))}
          suffix={suffix ? ` ${suffix}` : ''}
          onFocus={selectOnFocus ? (e) => e.target.select() : null}
          inputmode="numeric"
          {...(min && { min })}
          {...(max && { max })}
          {...rest}
        />
      </div>
    </div>
  );
});
