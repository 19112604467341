export class DialogUtil {
  dialogRef = null;

  constructor(dialogRef) {
    this.dialogRef = dialogRef;
  }

  success(subject, content, errors) {
    this.showDialog('success', '정상적으로 처리되었습니다.', subject, content, errors);
  }

  error(subject, content, errors) {
    this.showDialog('error', '문제가 발생했습니다.', subject, content, errors);
  }

  warn(subject, content, errors) {
    this.showDialog('warn', '확인이 필요합니다.', subject, content, errors);
  }

  info(subject, content, errors) {
    this.showDialog('info', '알려드립니다.', subject, content, errors);
  }

  showDialog(severity, header, subject, content, errors) {
    this.dialogRef.current.show(severity, header, subject, content, errors);
  }
}
