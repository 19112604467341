import React, { useEffect, useState } from 'react';
import * as Modal from './../../components/Atoms/Modal';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Calendar } from 'primereact/calendar';
import { Tag } from 'primereact/tag';
import { Card } from 'primereact/card';
import { CommonUtil } from '../../utils/commonUtil';
import Claim from '../../services/ClaimService';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import dayjs from 'dayjs';
import { Tooltip } from 'primereact/tooltip';
import { ToggleButton } from 'primereact/togglebutton';

const WarrantyDiagnosis = ({
  detailData,
  carInfo,
  setWarrantyData,
  warrantyData,
  partnerAllList,
  partnerList,
  setShowSmsType,
  setShowSmsModal,
  setSmsData,
  smsData,
  checked,
  onToggle,
  partnerAllType,
  selectedPartner,
  setSelectedPartner,
}) => {
  const [showModal, setShowModal] = useState(false);
  const today = new Date();
  const [partnerText, setPartnerText] = useState('');
  const [holidays, setHolidays] = useState(0);

  const filteredSelectPartner = selectedPartner.filter(
    (partner) => partner.useYn === 'Y'
  );

  const differenceInDays = (day1, day2) => {
    const date1 = new Date(day1);
    const date2 = new Date(day2);
    const differenceInMillis = Math.abs(date2 - date1);
    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const differenceInDays = Math.floor(
      differenceInMillis / millisecondsPerDay
    );
    // console.log(differenceInDays, holidays);
    return differenceInDays - holidays;
  };

  const WorkDetailReport = () => {
    return (
      <>
        <Modal.Default
          header={`정비이력 상세`}
          onHide={() => setShowModal(!showModal)}
        >
          {carInfo?.workDetails.length > 0
            ? carInfo?.workDetails?.map((work, idx) => (
                <div key={idx} className="flex w-full border-1 mb-2">
                  <div className="border-right-1 p-3  surface-100">
                    <div>
                      수리 요청일 :{' '}
                      {dayjs(work.workRequestDate).format('YYYY-MM-DD')}
                    </div>
                    <div>
                      수리 완료일 :{' '}
                      {dayjs(work.workCompleteDate).format('YYYY-MM-DD')}
                    </div>
                  </div>
                  <div className=" p-3">
                    {work.workItems.map((item, idx) => (
                      <div key={idx}>{item.fullText}</div>
                    ))}
                  </div>
                </div>
              ))
            : '이력이 없습니다.'}
        </Modal.Default>
      </>
    );
  };

  useEffect(() => {
    setWarrantyData((ps) => ({ ...ps, selectedPartner: selectedPartner }));
  }, [selectedPartner]);

  useEffect(() => {
    if (!!carInfo) {
      const data = JSON.parse(carInfo.performanceCheckData.vehicleInformation);
      setWarrantyData((ps) => ({
        ...ps,
        producingCountry: data.madeAt,
        checkDistance: data.TRVL_DSTNC,
        carName: data.CNM,
      }));
    }
  }, [carInfo]);

  const holidayList = async (startDate, endDate) => {
    try {
      const data = await Claim.getHolidays(startDate, endDate);
      const filteredList = data.filter(
        (el) => el.name !== '토요일' && el.name !== '일요일'
      );
      if (data) {
        setHolidays(filteredList.length);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (typeof warrantyData.buyDate !== 'string') {
      const startDate = dayjs(warrantyData.buyDate).format('YYYYMMDD');
      const endDatae = detailData.receiptDate
        ? detailData.receiptDate
        : dayjs(today).format('YYYYMMDD');

      if (startDate !== 'Invalid Date') {
        holidayList(startDate, endDatae);
      }
    }
  }, [detailData.receiptDate, today, warrantyData.buyDate]);

  const headerTemplate = (
    <div className="flex justify-content-between">
      <div className="flex align-items-center">4. 보증 / 거절 / 입고안내</div>
      <div className="flex gap-2">
        <Button
          label="성능지 조회"
          icon="pi pi-file"
          className=""
          onClick={() =>
            carInfo?.performanceCheckData?.statementNumber
              ? CommonUtil.Window.popup(
                  `/performance-check/${carInfo.performanceCheckData.statementNumber}-${carInfo.performanceCheckData.dataId}/viewer`,
                  `pcv#${carInfo.performanceCheckData.statementNumber}`,
                  window.innerWidth * 0.8,
                  window.innerHeight * 0.8
                )
              : null
          }
          disabled={!carInfo?.performanceCheckData?.statementNumber}
        />
        <Button
          label="정비이력 조회"
          icon="pi pi-search"
          className=""
          onClick={() => setShowModal(!showModal)}
          disabled={!carInfo?.workDetails.length}
        />
        <ToggleButton
          checked={checked?.find((el) => el.status === 'Z5')?.value}
          onChange={(e) => onToggle('Z5', e.value)}
          onLabel="완료"
          offLabel="미확인"
          onIcon="pi pi-flag-fill"
          offIcon="pi pi-flag"
        />
      </div>
    </div>
  );

  const possible =
    warrantyData?.distanceDriven - warrantyData?.checkDistance < 2000;
  const errDistance =
    warrantyData?.distanceDriven - warrantyData?.checkDistance < 0;
  const dayPossible =
    warrantyData?.buyDate && !detailData.receiptDate
      ? differenceInDays(today, warrantyData.buyDate) < 31
      : warrantyData?.buyDate && detailData.receiptDate
      ? differenceInDays(
          dayjs(detailData.receiptDate, 'YYYYMMDD'),
          dayjs(warrantyData.buyDate, 'YYYYMMDD')
        ) < 31
      : '미입력';

  const handleDeletePartner = (id) => {
    if (id) {
      const list = selectedPartner.map((el, index) => {
        if (el.partnerId === Number(id)) {
          return { ...el, useYn: 'N' };
        }
        return el;
      });
      setSelectedPartner(list);
    }
  };

  const handleSelectPartner = (partner) => {
    if (filteredSelectPartner.length > 4) {
      window.cerp.toast.warn('진단점은 최대 5개까지 등록 가능합니다.');
      return;
    }

    const isExistedPartner = filteredSelectPartner.some(
      (el) => el.partnerId === partner.partnerId
    );
    if (isExistedPartner) {
      window.cerp.toast.warn('이미 선택된 진단점입니다.');
      return;
    }
    let newSelectPartner = {
      partnerId: partner.partnerId,
      partnerName: partner.partnerName,
      address: partner.address,
      cellphone: partner.cellphone,
      useYn: 'Y',
      repairCodes: [],
      isOriginal: false,
    };
    newSelectPartner.partnerTypeOptions = filterPartnerTypeOptions(
      partnerAllType,
      partner.partnerRepairType
    );

    setSelectedPartner([...selectedPartner, newSelectPartner]);
    setPartnerText(newSelectPartner);
  };

  useEffect(() => {
    if (partnerText) {
      setPartnerText('');
    }
  }, [partnerText]);

  const filterPartnerTypeOptions = (partnerAllType, repairCodes) => {
    if (!repairCodes) return [];
    return partnerAllType.filter((el) => repairCodes.includes(el.value));
  };

  return (
    <React.Fragment>
      <Panel header={headerTemplate} className="pt-3">
        <div className="grid">
          <div className="col-5 p-3">
            <div className="p-card-title field">보증 가능 여부 확인</div>
            <div className="field grid align-items-center">
              <div className="col-3">
                <div className="flex align-items-center gap-2">
                  <label className="font-bold" htmlFor="">
                    차량 인도일자
                  </label>
                  <Tooltip target=".custom-target-icon" />
                  <i
                    className="custom-target-icon pi pi-question-circle p-text-secondary p-overlay-badge"
                    data-pr-tooltip="매매계약서의 인도일자"
                    data-pr-position="top"
                    style={{ cursor: 'pointer' }}
                  />
                </div>
              </div>
              <div className="col">
                <div className="flex align-items-center">
                  <Calendar
                    maxDate={today}
                    value={
                      typeof warrantyData.buyDate === 'string' &&
                      !!warrantyData.buyDate
                        ? CommonUtil.Formatter.stringToDate(
                            warrantyData.buyDate
                          )
                        : !!warrantyData.buyDate
                        ? warrantyData.buyDate
                        : null
                    }
                    onChange={(e) =>
                      setWarrantyData((ps) => ({
                        ...ps,
                        buyDate: e.target.value,
                      }))
                    }
                    dateFormat="yy-mm-dd"
                    showButtonBar
                    showIcon
                    mask="9999-99-99"
                    placeholder="인도일자 선택"
                  />
                  <div className="flex align-items-center gap-2">
                    <span className="ml-2">{`(경과일수: ${
                      warrantyData?.buyDate && !detailData.receiptDate
                        ? differenceInDays(today, warrantyData.buyDate)
                        : warrantyData?.buyDate && detailData.receiptDate
                        ? differenceInDays(
                            dayjs(detailData.receiptDate, 'YYYYMMDD'),
                            dayjs(warrantyData.buyDate, 'YYYYMMDD')
                          )
                        : ''
                    } 일)`}</span>
                    <Tooltip target=".custom-target-icon" />
                    <i
                      className="custom-target-icon pi pi-question-circle p-text-secondary p-overlay-badge"
                      data-pr-tooltip="공휴일 제외한 경과일수"
                      data-pr-position="top"
                      style={{ cursor: 'pointer' }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="field grid align-items-center">
              <div className="col-3">
                <label className="font-bold" htmlFor="">
                  성능시 주행거리
                </label>
              </div>
              <div className="col">
                <InputText
                  value={warrantyData.checkDistance || ''}
                  className=" w-full"
                  type="number"
                  onChange={(e) =>
                    setWarrantyData({
                      ...warrantyData,
                      checkDistance: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="field grid align-items-center">
              <div className="col-3">
                <label className="font-bold" htmlFor="">
                  접수시 주행거리
                </label>
              </div>
              <div className="col">
                <InputText
                  value={warrantyData?.distanceDriven || ''}
                  onChange={(e) =>
                    setWarrantyData((ps) => ({
                      ...ps,
                      distanceDriven: e.target.value,
                    }))
                  }
                  className=" w-full"
                  placeholder="주행거리 입력"
                />
              </div>
            </div>
            <div className="field grid ">
              <div className="col-3 font-bold">
                <div>가능여부</div>
              </div>
              <div className="col text-left">
                <div className="">
                  {!possible || !dayPossible || errDistance ? (
                    <Tag severity="danger" value="불가능"></Tag>
                  ) : (
                    <Tag severity="success" value="가능"></Tag>
                  )}
                </div>
                {errDistance && (
                  <div className="mt-1">
                    접수시 주행거리가 성능시 주행거리보다 작습니다.
                  </div>
                )}
                {!dayPossible && (
                  <div className="mt-1">
                    인도일자로부터 30일이 넘어 보증이 되지 않습니다.
                  </div>
                )}
                {!possible && (
                  <div className="mt-1">
                    주행거리가 2000킬로가 넘어 보증이 되지 않습니다.
                  </div>
                )}
              </div>
            </div>
            <div className="flex justify-content-center">
              <Button
                label="거절사유 전송"
                icon="pi pi-comment"
                className="p-button-outlined mr-1"
                onClick={(e) => {
                  setShowSmsModal(true);
                  setShowSmsType('REJECTION');
                  setSmsData({
                    ...smsData,
                    warrnaty: { day: dayPossible, distance: possible },
                  });
                  // onToggle('Z4', true);
                }}
              />
            </div>
            {/* </Card> */}
          </div>

          <div className="col-7 border-left-1 border-gray-300 p-3 ">
            <div className="p-card-title field">입고안내</div>
            <div className="field grid align-items-center">
              <div className="col-2">
                <label className="font-bold" htmlFor="">
                  국산 / 수입
                </label>
              </div>
              <div className="col">
                <Dropdown
                  className="w-full"
                  placeholder=""
                  options={PRODUCING_COUNTRY}
                  onChange={(e) =>
                    setWarrantyData((ps) => ({
                      ...ps,
                      producingCountry: e.value,
                    }))
                  }
                  value={warrantyData.producingCountry || 'D'}
                />
              </div>
            </div>
            <div className="field grid align-items-center">
              <div className="col-2">
                <label className="font-bold" htmlFor="">
                  차량명
                </label>
              </div>
              <div className="col">
                <InputText
                  onChange={(e) =>
                    setWarrantyData((ps) => ({
                      ...ps,
                      carName: e.target.value,
                    }))
                  }
                  value={warrantyData.carName || ''}
                  className=" w-full"
                  id="carName"
                />
              </div>
            </div>

            <div className="field grid align-items-center">
              <div className="col-2">
                <label className="font-bold" htmlFor="">
                  입고점 선택
                </label>
              </div>
              <div className="col">
                <div className="col grid gap-2">
                  <Dropdown
                    className="col-3"
                    placeholder="반경(km)"
                    options={['5', '10', '15', '20', '25', '30', '40', '50']}
                    onChange={(e) =>
                      setWarrantyData((ps) => ({
                        ...ps,
                        radius: e.target.value,
                      }))
                    }
                    value={warrantyData.radius || ''}
                  />
                  <Dropdown
                    className="col"
                    value={partnerText || ''}
                    onChange={(e) => handleSelectPartner(e.value)}
                    options={partnerList}
                    optionLabel="partnerName"
                    filter
                    placeholder="반경 선택 후 입고점을 선택해주세요."
                    emptyMessage="조건에 맞는 입고점이 없습니다."
                  />
                </div>
              </div>
            </div>
            <div className="field grid align-items-center">
              {!!filteredSelectPartner.length && (
                <div className="col-2">
                  <label className="font-bold" htmlFor="">
                    입고점명
                  </label>
                </div>
              )}
              <div className="col align-items-center">
                {!!filteredSelectPartner.length &&
                  filteredSelectPartner.map((el) => (
                    <div key={el.partnerId} className="col grid mb-3 pr-0">
                      <InputText
                        className="col-6 mr-2"
                        value={el.partnerName || ''}
                      />
                      {/* <InputText
                        className="col-3"
                        value={el.cellphone || ''}
                        placeholder="입고점 연락처"
                      /> */}
                      <MultiSelect
                        className="col-2 mr-2"
                        title="진단 부위"
                        required={true}
                        placeholder="진단부위 선택"
                        options={el.partnerTypeOptions}
                        value={el.repairCodes || ''}
                        onChange={(e) => {
                          const updatedSelectedPartner = selectedPartner.map(
                            (item) => {
                              if (item.partnerId === el.partnerId) {
                                return {
                                  ...item,
                                  repairCodes: e.value,
                                };
                              }
                              return item;
                            }
                          );
                          setSelectedPartner(updatedSelectedPartner);
                        }}
                      />
                      <MultiSelect
                        className="col-2"
                        title=""
                        required={true}
                        placeholder="타입"
                        options={PARTNER_TYPE}
                        value={el.partnerType || ''}
                        onChange={(e) => {
                          const updatedSelectedPartner = selectedPartner.map(
                            (item) => {
                              if (item.partnerId === el.partnerId) {
                                return {
                                  ...item,
                                  partnerType: e.value,
                                };
                              }
                              return item;
                            }
                          );
                          setSelectedPartner(updatedSelectedPartner);
                        }}
                      />
                      <Button
                        id={el.partnerId}
                        onClick={(e) => handleDeletePartner(e.target.id)}
                        icon="pi pi-minus-circle"
                        className="p-button-rounded p-button-outlined p-button-text p-button-secondary"
                      />
                    </div>
                  ))}
              </div>
            </div>
            {/* <div className="field grid align-items-center">
                <div className="col-3">
                  <label className="font-bold" htmlFor="">
                    입고점 요청사항
                  </label>
                </div>
                <div className="col">
                  <InputText
                    value={warrantyData.partnerNote || ''}
                    onChange={(e) =>
                      setWarrantyData((ps) => ({
                        ...ps,
                        partnerNote: e.target.value,
                      }))
                    }
                    className="w-full"
                    placeholder="입고점 요청사항 입력"
                  />
                </div>
              </div> */}
            <div className="flex justify-content-center pt-2 gap-3">
              <Button
                label="고객 입고점 안내 전송"
                icon="pi pi-user"
                className="p-button-outlined mr-1"
                onClick={() => {
                  setShowSmsModal(true);
                  setShowSmsType('INFO_TO_CUSTOMER');
                  setSmsData({
                    ...smsData,
                    partnerInfo: filteredSelectPartner,
                  });
                }}
              />
              <Button
                label="입고점 고객방문 전송"
                icon="pi pi-car"
                className="p-button-outlined mr-1"
                onClick={() => {
                  setShowSmsModal(true);
                  setShowSmsType('INFO_TO_PARTNER');
                  setSmsData({
                    ...smsData,
                    partnerInfo: filteredSelectPartner,
                  });
                }}
              />
            </div>
            {/* </Card> */}
          </div>
        </div>
      </Panel>
      {showModal && <WorkDetailReport />}
    </React.Fragment>
  );
};

export default WarrantyDiagnosis;

const PRODUCING_COUNTRY = [
  { label: '국산', value: 'D' },
  { label: '수입', value: 'F' },
];

const PARTNER_TYPE = [
  { label: '공통', value: 'P_DR' },
  { label: '진단', value: 'P_D' },
  { label: '수리', value: 'P_R' },
];
