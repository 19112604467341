import AxiosUtil from '../utils/axiosUtil';
import { BaseService } from './BaseService';

export const AssociationService = (function () {
  let instance;
  const BASE_URI = '/apis/associations';

  function init() {
    const baseService = BaseService.getInstance(BASE_URI, 'association');

    return {
      ...baseService,
      checkAvailable: async function (code = '') {
        return await AxiosUtil.Async.get(
          BASE_URI + '/check/available-code',
          `ac=${code}`
        );
      },
      getAvailableStatementNumberPrefixes: async function () {
        return await AxiosUtil.Async.get(
          BASE_URI + `/check/available-prefixes`
        );
      },
      register: async function (data, file) {
        return await AxiosUtil.Async.multipart(BASE_URI, data, file);
      },
      update: async function(data, file) {
        return await AxiosUtil.Async.updateMultipart(BASE_URI, data, file);
      },
      list: async function (params) {
        const queryString = Object.entries(params)
          .map((e) => e.join('='))
          .join('&');
        return AxiosUtil.Async.get(BASE_URI, queryString);
      },
      getData: async function (associationId) {
        return await AxiosUtil.Async.get(BASE_URI + `/${associationId}`);
      },
      delete: async function (associationId) {
        return await AxiosUtil.Async.delete(BASE_URI + `/${associationId}`);
      },
      getDataMobile: async function (associationId) {
        return await AxiosUtil.Async.get(BASE_URI + `/viewer/${associationId}`);
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();
