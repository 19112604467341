import axios from 'axios';

export const ProductService = (function () {
  let instance;
  const BASE_URI = 'assets/demo/data';

  function init() {
    return {
      getProductsSmall: async function () {
        const {
          data: { data },
        } = await axios.get(BASE_URI + '/products-small.json');
        return data;
      },
      getProducts: async function () {
        const {
          data: { data },
        } = await axios.get(BASE_URI + '/products.json');
        return data;
      },
      getProductsWithOrdersSmall: async function () {
        const {
          data: { data },
        } = await axios.get(BASE_URI + '/products-orders-small.json');
        return data;
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();
