import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {Dialog} from 'primereact/dialog';
import _ from 'lodash';
import classNames from 'classnames';

const defaultData = {
  severity: '',
  header: '',
  subject: '',
  content: '',
  forDeveloper: '',
  isVisible: false,
};

const GlobalDialog = forwardRef((props, ref) => {
  const [data, setData] = useState({...defaultData});

  useImperativeHandle(ref, () => ({
    show(severity = '', header = '', subject = '', content = '', forDeveloper = null) {
      setData({severity, header, subject, content, forDeveloper, isVisible: true});
    },
  }));

  return (
    <Dialog
      ref={ref}
      modal
      style={{width: '50vw'}}
      breakpoints={{'960px': '75vw', '640px': '100vw'}}
      contentClassName="px-3 pb-3"
      header={
        <div className="text-xl flex flex-row align-items-center justify-content-start">
          <i className={classNames('text-xl mr-2 pi', {
            'pi-thumbs-up': _.get(data, 'severity') === 'success',
            'pi-exclamation-triangle': _.get(data, 'severity') === 'error',
            'pi-volume-up': _.get(data, 'severity') === 'warn',
            'pi-info-circle': _.get(data, 'severity') === 'info',
          })} />
          {_.get(data, 'header')}
        </div>
      }
      visible={_.get(data, 'isVisible')}
      onHide={() => setData({...defaultData})}
    >
      <div className={classNames('p-0 border-2 border-round', {
        'border-green-400': _.get(data, 'severity') === 'success',
        'border-red-400': _.get(data, 'severity') === 'error',
        'border-orange-400': _.get(data, 'severity') === 'warn',
        'border-yellow-400': _.get(data, 'severity') === 'info',
      })}>
        <table className="w-full h-full"
               cellSpacing="0"
               cellPadding="0"
               style={{borderCollapse: 'collapse'}}>
          <thead />
          <colgroup>
            <col className={classNames('w-6rem', {
              'bg-green-100': _.get(data, 'severity') === 'success',
              'bg-red-100': _.get(data, 'severity') === 'error',
              'bg-orange-100': _.get(data, 'severity') === 'warn',
              'bg-yellow-100': _.get(data, 'severity') === 'info',
            })} />
            <col />
          </colgroup>
          <tbody>
          <tr className={classNames('border-bottom-1', {
            'border-green-200': _.get(data, 'severity') === 'success',
            'border-red-200': _.get(data, 'severity') === 'error',
            'border-orange-200': _.get(data, 'severity') === 'warn',
            'border-yellow-200': _.get(data, 'severity') === 'info',
          })}>
            <th className="px-1 py-2">요약</th>
            <td className="px-2">{_.get(data, 'subject')}</td>
          </tr>
          <tr>
            <th className="px-1 py-2">상세</th>
            <td className="px-2" style={{wordBreak: 'break-word'}}>{_.get(data, 'content')}</td>
          </tr>
          {_.get(data, 'forDeveloper') !== null && (
            <tr className={classNames('border-top-1', {
              'border-green-200': _.get(data, 'severity') === 'success',
              'border-red-200': _.get(data, 'severity') === 'error',
              'border-orange-200': _.get(data, 'severity') === 'warn',
              'border-yellow-200': _.get(data, 'severity') === 'info',
            })}>
              <th className="px-1 py-2">개발자</th>
              <td className="px-2" style={{wordBreak: 'break-word'}}>
                {
                  _.isArray(_.get(data, 'forDeveloper'))
                    ? _.map(_.get(data, 'forDeveloper'), (item, idx) => (
                      <>
                        <span>{item}</span>
                        {idx < _.get(data, 'forDeveloper').length && <br />}
                      </>
                    ))
                    : _.get(data, 'forDeveloper')
                }
              </td>
            </tr>
          )}
          </tbody>
        </table>
      </div>
    </Dialog>
  );
});

export default GlobalDialog;
