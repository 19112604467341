import { Button } from 'primereact/button';
import React from 'react';

export const SearchInitButton = ({ onInit }) => {
  return (
    <Button
      label="검색조건 초기화"
      icon="pi pi-undo"
      className="p-button-outlined mr-1"
      onClick={onInit}
    />
  );
};
