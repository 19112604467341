import * as Titled from './../../../components/Atoms/Titled';

import React, { useCallback } from 'react';

import { CommonUtil } from '../../../utils/commonUtil';
import { Controller } from 'react-hook-form';

export const ControlledInputText = ({ item, control, setValue }) => {
  const onChangeInputValue = useCallback(
    (e, field) => {
      field.onChange(e);

      let value = e.target.value;
      if (item.type)
        CommonUtil.Formatter[item.type] &&
          (value = CommonUtil.Formatter[item.type](value));

      setValue(field.name, value);
    },
    [item.type, setValue]
  );

  return (
    <Controller
      control={control}
      name={item.code}
      defaultValue=""
      rules={{
        required: item.required ? '필수 입력 항목입니다.' : false,
        pattern: {
          value: CommonUtil.Pattern[item.type],
          message: '유효하지 않은 포맷입니다.',
        },
      }}
      render={({ field, fieldState, formState }) => (
        <Titled.InputText
          id={field.name}
          {...item}
          {...field}
          onChange={(e) => onChangeInputValue(e, field)}
          error={formState.errors[field.name]}
        />
      )}
    />
  );
};
