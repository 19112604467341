import imageCompression from 'browser-image-compression';

export const compressImages = async (images, options) => {
  const compressedImages = [];

  for (let image of images) {
    // console.log(image);
    try {
      // console.log('압축 전 사이즈 =====> ', `${image.size} / 1024 / 1024 MB`);

      const compressedBlob = await imageCompression(image, options);
      const compressedFile = new File([compressedBlob], image.name, {
        type: image.type,
      });

      // console.log(
      //   '압축 후 사이즈 ======>',
      //   compressedFile,
      //   `${compressedFile.size} / 1024 / 1024 MB`
      // );

      compressedImages.push(compressedFile);
    } catch (error) {
      console.log(error);
    }
  }

  return compressedImages;
};
