import * as Button from './../../components/Atoms/Button';

import { COUNT_PER_PAGE, YN } from '../../constants/Constants';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import CheckBaseDataDialog from '../../components/PerformanceCheck/CheckBaseDataDialog';
import { Checkbox } from 'primereact/checkbox';
import { CodeUtil } from '../../utils/CodeUtil';
import { Column } from 'primereact/column';
// import {
//   columnCheckState,
//   columnDateTime,
//   columnTransferState,
//   columnUseYn,
//   exportExcel,
//   columnNumberWithCommas,
// } from '../../utils/dataTableUtil';
import { ColumnGroup } from 'primereact/columngroup';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import { MultiSelect } from 'primereact/multiselect';
import { MyInfoUtil } from '../../utils/myInfoUtil';
import { PC_BASE_BLUEPRINT } from '../../constants/PerformanceCheckConstants';
import { ProgressBar } from 'primereact/progressbar';
import { RadioButton } from 'primereact/radiobutton';
import RangeCalendar from '../../components/Common/RangeCalendar';
import { Row } from 'primereact/row';
import SearchMenu from '../../components/Menu/SearchMenu';
import { ServiceProvider } from '../../services';
import { Tag } from 'primereact/tag';
import _ from 'lodash';
import { classNames } from 'primereact/utils';
import dayjs from 'dayjs';
import { myInfoSelector } from '../../recoil/selectors';
import { touchUIState } from '../../recoil/atoms';
import { useAuthorizedFetch } from '../../hooks/useAuthorizedFetch';

const today = dayjs();
const startDate = today.startOf('month');
const endDate = today;

const priceFormatter = new Intl.NumberFormat('ko-KR', { style: 'decimal' });

const DEFAULT_SEARCH_CONDITIONS = {
  associations: [],
  enterprises: [],
  shops: [],
  startDate: startDate.toDate(),
  endDate: endDate.toDate(),
  statementNumber: '',
  carSearchKeyword: '',
  customerSearchBy: '1',
  customerSearchKeyword: '',
  payYN: '',
};

const codeService = ServiceProvider.code;
const performanceCheckReceivableService =
  ServiceProvider.performanceCheckReceivable;

const ReceivableList = () => {
  const touchUI = useRecoilValue(touchUIState);
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const {
    myAssociationId,
    myEnterpriseId,
    myShopId,
    myUserId,
    myRoleCode,
    myUserPosition,
  } = useMemo(
    () => MyInfoUtil.unpack(myInfoLoadable.contents),
    [myInfoLoadable.contents]
  );

  const [searchConditions, setSearchConditions] = useState({
    ...DEFAULT_SEARCH_CONDITIONS,
  });
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: 10,
    page: 0,
    sortField: null,
    sortOrder: null,
    filters: {
      name: { value: '', matchMode: 'contains' },
      'country.name': { value: '', matchMode: 'contains' },
      company: { value: '', matchMode: 'contains' },
      'representative.name': { value: '', matchMode: 'contains' },
    },
  });
  const {
    loading,
    totalRecords,
    data,
    summary,
    getData,
    getSummary,
    initData,
  } = useAuthorizedFetch(
    performanceCheckReceivableService,
    searchConditions,
    setSearchConditions,
    DEFAULT_SEARCH_CONDITIONS,
    lazyParams.page,
    lazyParams.rows,
    true
  );
  const [showDetail, setShowDetail] = useState(false);
  const [currentStatementNumber, setCurrentStatementNumber] = useState(null);
  const [codes, setCodes] = useState({});

  const [exporting, setExporting] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    (async () => {
      if (myInfoLoadable.state === 'hasValue') {
        const [
          [insurances, insuranceId, insurance],
          [associations, associationId, association],
          [enterprises, enterpriseId, enterprise],
          [shops, shopId, shop],
        ] = await Promise.all([
          CodeUtil.getInsuranceCodes({}),
          CodeUtil.getAssociationCodes({}),
          CodeUtil.getEnterpriseCodes({
            isParentEmpty: false,
            associationId: myAssociationId,
          }),
          CodeUtil.getShopCodes({
            isParentEmpty: false,
            enterpriseId: myEnterpriseId,
          }),
        ]);

        setCodes((ps) => ({
          ...ps,
          insurances,
          associations,
          enterprises,
          shops,
        }));
      }
    })();

    return () => setCurrentStatementNumber(null);
  }, [myAssociationId, myEnterpriseId, myInfoLoadable.state]);

  async function exportToExcel() {
    setExporting(true);

    const params = {
      ...searchConditions,
      page: lazyParams.page + 1,
      size: lazyParams.rows,
    };
    params.startDate = dayjs(params.startDate).format('YYYYMMDD');
    params.endDate = dayjs(params.endDate).format('YYYYMMDD');

    await performanceCheckReceivableService.exportExcel(params);

    setExporting(false);
  }

  return (
    <div className="grid">
      <div className="col-12">
        {(() => {
          switch (myInfoLoadable.state) {
            case 'hasValue':
              return (
                <>
                  <SearchMenu
                    onInit={() => initData()}
                    onSearch={() => getData(searchConditions)}
                  >
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속협회</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.associations}
                        display="chip"
                        placeholder="전체 협회"
                        value={searchConditions.associations}
                        filter
                        onChange={async ({ value }) => {
                          const [enterprises, enterpriseId, enterprise] =
                            await CodeUtil.getEnterpriseCodes({
                              isParentEmpty: _.isEmpty(
                                _.get(codes, 'associations')
                              ),
                              associationId: value,
                            });
                          const [shops, shopId, shop] =
                            await CodeUtil.getShopCodes({
                              isParentEmpty: _.isEmpty(enterprises),
                            });
                          setCodes((ps) => ({ ...ps, enterprises, shops }));
                          setSearchConditions((ps) => ({
                            ...ps,
                            associations: value,
                            enterprises: [],
                            shops: [],
                          }));
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속점검법인</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.enterprises}
                        display="chip"
                        placeholder="전체 업체"
                        value={searchConditions.enterprises}
                        filter
                        onChange={async ({ value }) => {
                          const [shops, shopId, shop] =
                            await CodeUtil.getShopCodes({
                              isParentEmpty: _.isEmpty(
                                _.get(codes, 'enterprises')
                              ),
                              enterpriseId: value,
                            });
                          setCodes((ps) => ({ ...ps, shops }));
                          setSearchConditions((ps) => ({
                            ...ps,
                            enterprises: value,
                            shops: [],
                          }));
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">소속점검장</label>
                      <MultiSelect
                        className="w-full"
                        options={codes.shops}
                        display="chip"
                        placeholder="전체 점검장"
                        value={searchConditions.shops}
                        filter
                        onChange={({ value }) => {
                          setSearchConditions((ps) => ({
                            ...ps,
                            shops: value,
                          }));
                        }}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">성능점검일자</label>
                      <RangeCalendar
                        startDate={searchConditions.startDate}
                        endDate={searchConditions.endDate}
                        onStartDateChanged={(date) =>
                          setSearchConditions((ps) => ({
                            ...ps,
                            startDate: date,
                          }))
                        }
                        onEndDateChanged={(date) =>
                          setSearchConditions((ps) => ({
                            ...ps,
                            endDate: date,
                          }))
                        }
                        touchUI={touchUI}
                        showNavigators={true}
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="statementNumber">성능지번호</label>
                      <InputText
                        id="statementNumber"
                        className="w-full"
                        value={searchConditions.statementNumber}
                        onChange={({ target: { value } }) =>
                          setSearchConditions((ps) => ({
                            ...ps,
                            statementNumber: value,
                          }))
                        }
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">차량</label>
                      <InputText
                        className="w-full"
                        value={searchConditions.carSearchKeyword}
                        placeholder="차량번호, 모델, 차대번호로 검색"
                        onChange={({ target: { value } }) =>
                          setSearchConditions((ps) => ({
                            ...ps,
                            carSearchKeyword: value,
                          }))
                        }
                      />
                    </div>
                    <div className="field col-12 sm:col-6 lg:col-3">
                      <label htmlFor="range">고객</label>
                      <InputText
                        className="w-full"
                        value={searchConditions.customerSearchKeyword}
                        placeholder={
                          searchConditions.customerSearchBy === '1'
                            ? '고객 이름, 연락처로 검색'
                            : '매매상사 이름, 대표전화, 사업자등록번호로 검색'
                        }
                        onChange={({ target: { value } }) =>
                          setSearchConditions((ps) => ({
                            ...ps,
                            customerSearchKeyword: value,
                          }))
                        }
                      />
                      <div className="flex flex-auto flex-wrap align-items-center justify-content-start mt-2 gap-3">
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_dealerName"
                            name="customerSearchBy"
                            value="1"
                            onChange={({ value }) =>
                              setSearchConditions((ps) => ({
                                ...ps,
                                customerSearchBy: value,
                              }))
                            }
                            checked={searchConditions.customerSearchBy === '1'}
                          />
                          <label htmlFor="radio_dealerName">고객</label>
                        </div>
                        <div className="flex flex-auto flex-grow-0 flex-shrink-0 align-items-center justify-content-start gap-1">
                          <RadioButton
                            inputId="radio_companyName"
                            name="customerSearchBy"
                            value="2"
                            onChange={({ value }) =>
                              setSearchConditions((ps) => ({
                                ...ps,
                                customerSearchBy: value,
                              }))
                            }
                            checked={searchConditions.customerSearchBy === '2'}
                          />
                          <label htmlFor="radio_companyName">매매상사</label>
                        </div>
                      </div>
                    </div>
                  </SearchMenu>

                  <div className="card flex flex-auto flex-wrap align-items-center justify-content-end gap-1">
                    <div className="mr-3">
                      <Checkbox
                        className="mr-1"
                        inputId="chk_showDetail"
                        onChange={({ checked }) => setShowDetail(checked)}
                        checked={showDetail}
                      />
                      <label
                        htmlFor="chk_showDetail"
                        className="p-checkbox-label"
                      >
                        상세보기
                      </label>
                    </div>
                    <Button.Default
                      label="일괄수납처리"
                      className="p-button-outlined"
                      icon="pi pi-credit-card"
                    />
                    <Button.Default
                      label="엑셀 다운로드"
                      className="p-button-outlined"
                      icon="pi pi-download"
                      disabled={exporting}
                      loading={exporting}
                      onClick={async () => await exportToExcel()}
                    />
                  </div>

                  <div className="card">
                    <DataTable
                      loading={loading}
                      value={data}
                      lazy
                      rows={lazyParams.rows}
                      first={lazyParams.first}
                      totalRecords={totalRecords}
                      paginator
                      responsiveLayout="scroll"
                      emptyMessage="데이터가 없습니다."
                      // responsiveLayout="stack"
                      // breakpoint="640px"
                      footerColumnGroup={
                        <ColumnGroup>
                          <Row>
                            <Column
                              colSpan={8}
                              footerClassName="text-right"
                              footer="현재 리스트 합계"
                            />
                            <Column
                              footerClassName="text-right"
                              footer={() => (
                                <div>
                                  {showDetail && (
                                    <>
                                      <span>
                                        {priceFormatter.format(
                                          _.sumBy(data, 'checkPrice')
                                        )}
                                      </span>
                                      <br />
                                      <span>
                                        +{' '}
                                        {priceFormatter.format(
                                          _.sumBy(data, 'checkPriceVat')
                                        )}
                                      </span>
                                      <br />
                                      <span>
                                        -{' '}
                                        {priceFormatter.format(
                                          _.sumBy(data, 'discountedCheckPrice')
                                        )}
                                      </span>
                                      <br />
                                      <hr className="p-0 m-0" />
                                    </>
                                  )}
                                  <span>
                                    {priceFormatter.format(
                                      _.sumBy(data, 'totalCheckPrice')
                                    )}
                                  </span>
                                </div>
                              )}
                            />
                            <Column
                              footerClassName="text-right"
                              footer={() => {
                                const counts = _.countBy(
                                  data,
                                  (item) =>
                                    _.get(item, 'checkPricePaidYn') === 'Y'
                                );
                                return (
                                  <span>결제대기 : {counts[false] || 0}</span>
                                );
                              }}
                            />
                            <Column
                              footerClassName="text-right"
                              footer={() => {
                                const counts = _.countBy(
                                  data,
                                  (item) => _.get(item, 'assuranceType') === '2'
                                );
                                return (
                                  <div>
                                    <span>
                                      보험사보증 : {counts[true] || 0}
                                    </span>
                                    <br />
                                    <span>자가보증 : {counts[false] || 0}</span>
                                  </div>
                                );
                              }}
                            />
                            <Column
                              footerClassName="text-right"
                              footer={() => (
                                <div>
                                  {showDetail && (
                                    <>
                                      <span>
                                        {priceFormatter.format(
                                          _.sumBy(data, 'insurancePrice')
                                        )}
                                      </span>
                                      <br />
                                      <span>
                                        +{' '}
                                        {priceFormatter.format(
                                          _.sumBy(data, 'insurancePriceVat')
                                        )}
                                      </span>
                                      <br />
                                      <br />
                                      <hr className="p-0 m-0" />
                                    </>
                                  )}
                                  <span>
                                    {priceFormatter.format(
                                      _.sumBy(data, 'totalInsurancePrice')
                                    )}
                                  </span>
                                </div>
                              )}
                            />
                            <Column
                              footerClassName="text-right"
                              footer={() => {
                                const counts = _.countBy(
                                  data,
                                  (item) =>
                                    _.get(item, 'insurancePricePaidYn') === 'Y'
                                );
                                return (
                                  <span>결제대기 : {counts[false] || 0}</span>
                                );
                              }}
                            />
                            <Column
                              footerClassName="text-right"
                              footer={() => (
                                <div>
                                  {showDetail && (
                                    <>
                                      <span>
                                        {priceFormatter.format(
                                          _.sumBy(data, 'summaryPrice')
                                        )}
                                      </span>
                                      <br />
                                      <span>
                                        +{' '}
                                        {priceFormatter.format(
                                          _.sumBy(data, 'vat')
                                        )}
                                      </span>
                                      <br />
                                      <br />
                                      <hr className="p-0 m-0" />
                                    </>
                                  )}
                                  <span>
                                    {priceFormatter.format(
                                      _.sumBy(data, 'totalPrice')
                                    )}
                                  </span>
                                </div>
                              )}
                            />
                            <Column
                              footerClassName="text-right"
                              footer={() =>
                                priceFormatter.format(
                                  _.sumBy(data, 'paidPrice')
                                )
                              }
                            />
                            <Column
                              footerClassName="text-right"
                              footer={() =>
                                priceFormatter.format(
                                  _.sumBy(data, 'unpaidPrice')
                                )
                              }
                            />
                            <Column
                              footerClassName="text-right"
                              footer={() => {
                                const counts = _.countBy(
                                  data,
                                  (item) => _.get(item, 'isRecheck') === 'Y'
                                );
                                return (
                                  <div>
                                    <span>신규성능 : {counts[false] || 0}</span>
                                    <br />
                                    <span>재성능 : {counts[true] || 0}</span>
                                  </div>
                                );
                              }}
                            />
                            <Column colSpan={2} />
                          </Row>
                          <Row>
                            <Column
                              colSpan={8}
                              footerClassName="text-right bg-yellow-200"
                              footer="전체 합계"
                            />
                            <Column
                              footerClassName="text-right bg-yellow-200"
                              footer={() =>
                                priceFormatter.format(
                                  _.get(summary, 'sumTotalCheckPrice')
                                )
                              }
                            />
                            <Column
                              footerClassName="text-right bg-yellow-200"
                              footer={() => (
                                <span>
                                  결제대기 :{' '}
                                  {_.get(summary, 'countCheckPricePaidN') || 0}
                                </span>
                              )}
                            />
                            <Column
                              footerClassName="text-right bg-yellow-200"
                              footer={() => {
                                return (
                                  <div>
                                    <span>
                                      보험사보증 :{' '}
                                      {_.get(
                                        summary,
                                        'countInsuranceCompany'
                                      ) || 0}
                                    </span>
                                    <br />
                                    <span>
                                      자가보증 :{' '}
                                      {_.get(summary, 'countInsuranceSelf') ||
                                        0}
                                    </span>
                                  </div>
                                );
                              }}
                            />
                            <Column
                              footerClassName="text-right bg-yellow-200"
                              footer={() =>
                                priceFormatter.format(
                                  _.get(summary, 'sumTotalInsurancePrice')
                                )
                              }
                            />
                            <Column
                              footerClassName="text-right bg-yellow-200"
                              footer={() => (
                                <span>
                                  결제대기 :{' '}
                                  {_.get(summary, 'countInsurancePricePaidN') ||
                                    0}
                                </span>
                              )}
                            />
                            <Column
                              footerClassName="text-right bg-yellow-200"
                              footer={() =>
                                priceFormatter.format(
                                  _.get(summary, 'sumTotalPrice')
                                )
                              }
                            />
                            <Column
                              footerClassName="text-right bg-yellow-200"
                              footer={() =>
                                priceFormatter.format(
                                  _.get(summary, 'sumPaidPrice')
                                )
                              }
                            />
                            <Column
                              footerClassName="text-right bg-yellow-200"
                              footer={() =>
                                priceFormatter.format(
                                  _.get(summary, 'sumUnpaidPrice')
                                )
                              }
                            />
                            <Column
                              footerClassName="text-right bg-yellow-200"
                              footer={() => {
                                return (
                                  <div>
                                    <span>
                                      신규성능 :{' '}
                                      {_.get(summary, 'countNewcheck') || 0}
                                    </span>
                                    <br />
                                    <span>
                                      재성능 :{' '}
                                      {_.get(summary, 'countRecheck') || 0}
                                    </span>
                                  </div>
                                );
                              }}
                            />
                            <Column footerClassName="text-right bg-yellow-200" />
                            <Column footerClassName="text-right bg-yellow-200" />
                          </Row>
                        </ColumnGroup>
                      }
                      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                      currentPageReportTemplate="전체 {totalRecords}건 중 {first} ~ {last}"
                      rowsPerPageOptions={COUNT_PER_PAGE}
                      resizableColumns
                      showGridlines
                      onPage={(event) => setLazyParams(event)}
                      onRowDoubleClick={(event) => console.log(event)}
                      selectionMode="checkbox"
                      selection={selectedRows}
                      onSelectionChange={(e) => setSelectedRows(e.value)}
                    >
                      {/*<Column field="dataId" header="ID" />*/}
                      <Column selectionMode="multiple" />
                      <Column
                        header="순번"
                        body={(rowData, column) => (
                          <span>{column.rowIndex + 1}</span>
                        )}
                      />
                      <Column
                        field="associationId"
                        header="협회"
                        body={({
                          associationId,
                          associationCode,
                          associationName,
                        }) => (
                          <span>
                            {associationName}
                            <br />[{associationCode}]
                          </span>
                        )}
                      />
                      <Column
                        field="enterpriseId"
                        header="점검업체"
                        body={({
                          enterpriseId,
                          enterpriseCode,
                          enterpriseName,
                        }) => (
                          <span>
                            {enterpriseName}
                            <br />[{enterpriseCode}]
                          </span>
                        )}
                      />
                      <Column
                        field="shopId"
                        header="점검장"
                        body={({ shopId, shopCode, shopName }) => (
                          <span>
                            {shopName}
                            <br />[{shopCode}]
                          </span>
                        )}
                      />
                      {/*<Column field="inspectorId" header="" />*/}
                      <Column
                        field="inspectorName"
                        header="점검자"
                        body={({
                          inspectorId,
                          inspectorLoginId,
                          inspectorName,
                        }) => (
                          <span>
                            {inspectorName}
                            <br />[{inspectorLoginId}]
                          </span>
                        )}
                      />
                      {/*<Column field="customerCompanyId" header="" />*/}
                      <Column
                        field="customerCompanyName"
                        header="소속상사"
                        body={({
                          customerCompanyName,
                          customerCompanyContact,
                        }) => (
                          <span>
                            {customerCompanyName}
                            <br />({customerCompanyContact})
                          </span>
                        )}
                      />
                      <Column
                        field="customerName"
                        header="고객명"
                        body={({ customerName, customerContact }) => (
                          <span>
                            {customerName}
                            <br />({customerContact})
                          </span>
                        )}
                      />
                      <Column
                        field="checkPrice"
                        className="text-right"
                        header={
                          <div>
                            {showDetail && (
                              <>
                                <span>점검료</span>
                                <br />
                                <span>+ VAT</span>
                                <br />
                                <span>- 할인</span>
                                <hr className="p-0 m-0" />
                              </>
                            )}
                            <span>점검료 합계</span>
                          </div>
                        }
                        body={({
                          checkPrice,
                          checkPriceVat,
                          discountedCheckPrice,
                          totalCheckPrice,
                        }) => (
                          <div>
                            {showDetail && (
                              <>
                                <span>{priceFormatter.format(checkPrice)}</span>
                                <br />
                                <span>
                                  + {priceFormatter.format(checkPriceVat)}
                                </span>
                                <br />
                                <span>
                                  -{' '}
                                  {priceFormatter.format(discountedCheckPrice)}
                                </span>
                                <hr className="p-0 m-0" />
                              </>
                            )}
                            <span>
                              {priceFormatter.format(totalCheckPrice)}
                            </span>
                          </div>
                        )}
                      />
                      <Column
                        field="checkPricePaidYn"
                        header="점검료 결제여부"
                        className="text-center"
                        body={({ checkPricePaidYn }) => (
                          <Tag
                            value={
                              checkPricePaidYn === YN.YES
                                ? '결제완료'
                                : '결제대기'
                            }
                            className={classNames('text-sm', {
                              'bg-green-400': checkPricePaidYn === YN.YES,
                              'bg-orange-400': checkPricePaidYn !== YN.YES,
                            })}
                          />
                        )}
                      />
                      <Column
                        field="assuranceType"
                        header={
                          <div>
                            보증구분
                            <br />
                            보험사
                          </div>
                        }
                        body={({ assuranceType, insuranceCompany }) => (
                          <div>
                            <span>
                              {_.get(
                                _.find(
                                  PC_BASE_BLUEPRINT.ASSRNC_TY_SE_CODE
                                    .valueItems,
                                  { value: assuranceType }
                                ),
                                'label'
                              )}
                            </span>
                            <br />
                            <span>
                              {_.get(
                                _.find(codes.insurances, {
                                  value: insuranceCompany,
                                }),
                                'label'
                              )}
                            </span>
                          </div>
                        )}
                      />
                      <Column
                        field="insurancePrice"
                        className="text-right"
                        header={
                          <div>
                            {showDetail && (
                              <>
                                <span>보험료</span>
                                <br />
                                <span>+ VAT</span>
                                <br />
                                <br />
                                <hr className="p-0 m-0" />
                              </>
                            )}
                            <span>보험료 합계</span>
                          </div>
                        }
                        body={({
                          insurancePrice,
                          insurancePriceVat,
                          totalInsurancePrice,
                        }) => (
                          <div>
                            {showDetail && (
                              <>
                                {priceFormatter.format(insurancePrice)}
                                <br />+{' '}
                                {priceFormatter.format(insurancePriceVat)}
                                <br />
                                <br />
                                <hr className="p-0 m-0" />
                              </>
                            )}
                            {priceFormatter.format(totalInsurancePrice)}
                          </div>
                        )}
                      />
                      <Column
                        field="insurancePricePaidYn"
                        header="보험료 결제여부"
                        className="text-center"
                        body={({ insurancePricePaidYn }) => (
                          <Tag
                            value={
                              insurancePricePaidYn === YN.YES
                                ? '결제완료'
                                : '결제대기'
                            }
                            className={classNames('text-sm', {
                              'bg-green-400': insurancePricePaidYn === YN.YES,
                              'bg-orange-400': insurancePricePaidYn !== YN.YES,
                            })}
                          />
                        )}
                      />
                      <Column
                        field="summaryPrice"
                        className="text-right"
                        header={
                          <div>
                            {showDetail && (
                              <>
                                <span>합계</span>
                                <br />
                                <span>+ VAT 합계</span>
                                <br />
                                <br />
                                <hr className="p-0 m-0" />
                              </>
                            )}
                            총합
                          </div>
                        }
                        body={({ summaryPrice, vat, totalPrice }) => (
                          <div>
                            {showDetail && (
                              <>
                                {priceFormatter.format(summaryPrice)}
                                <br />+ {priceFormatter.format(vat)}
                                <br />
                                <br />
                                <hr className="p-0 m-0" />
                              </>
                            )}
                            {priceFormatter.format(totalPrice)}
                          </div>
                        )}
                      />
                      <Column
                        field="paidPrice"
                        className="text-right"
                        header="결제금액"
                        body={({ paidPrice }) =>
                          priceFormatter.format(paidPrice)
                        }
                      />
                      <Column
                        field="unpaidPrice"
                        className="text-right"
                        header="미결제금액"
                        body={({ unpaidPrice }) =>
                          priceFormatter.format(unpaidPrice)
                        }
                      />
                      <Column
                        field="isRecheck"
                        header="점검구분"
                        className="text-center"
                        body={({ isRecheck }) =>
                          isRecheck === YN.YES && (
                            <Tag value="재성능" className="text-sm" />
                          )
                        }
                      />
                      <Column
                        field="statementNumber"
                        header={
                          <div>
                            성능점검일자
                            <br />
                            성능점검번호
                          </div>
                        }
                        body={({ statementNumber, checkDate }) => (
                          <div>
                            {dayjs(checkDate).format('YYYY-MM-DD')}
                            <br />
                            {statementNumber}
                          </div>
                        )}
                      />
                      <Column
                        field="carName"
                        header={
                          <div>
                            차량명
                            <br />
                            차량번호
                          </div>
                        }
                        body={({ carName, licensePlateNumber }) => (
                          <div>
                            {carName}
                            <br />
                            {licensePlateNumber}
                          </div>
                        )}
                      />
                      {/*<Column*/}
                      {/*  field="initialTransferTime"*/}
                      {/*  header={<div>최초전송일시<br />최근전송일시</div>}*/}
                      {/*  body={({initialTransferTime, latestTransferTime}) => (*/}
                      {/*    <div>*/}
                      {/*      {initialTransferTime ? (<span>{dayjs(initialTransferTime).format('YYYY-MM-DD HH:mm:ss')}</span>) : <span />}*/}
                      {/*      <br />*/}
                      {/*      {latestTransferTime ? (<span>{dayjs(latestTransferTime).format('YYYY-MM-DD HH:mm:ss')}</span>) : <span />}*/}
                      {/*    </div>*/}
                      {/*  )}*/}
                      {/*/>*/}
                    </DataTable>
                  </div>

                  {currentStatementNumber !== null && (
                    <CheckBaseDataDialog
                      visible
                      statementNumber={currentStatementNumber}
                      onHide={async () => {
                        setCurrentStatementNumber(null);
                        await getData(
                          searchConditions,
                          lazyParams.page,
                          lazyParams.rows
                        );
                      }}
                    />
                  )}
                </>
              );

            case 'loading':
              return <ProgressBar mode="indeterminate" />;

            case 'hasError':
              return null;

            default:
          }
        })()}
      </div>
    </div>
  );
};

const comparisonFn = function (prevProps, nextProps) {
  return prevProps.location.pathname === nextProps.location.pathname;
};

export default React.memo(ReceivableList, comparisonFn);
