import * as Button from './../../components/Atoms/Button';

import React from 'react';

const ServiceMenu = ({
  title,
  onClickRegisterBtn,
  excelData,
  buttonVisible,
}) => {
  const { type, params } = excelData;

  return (
    <div className="card">
      <div className="flex flex-auto flex-wrap align-items-center justify-content-end gap-1">
        {buttonVisible && (
          <Button.Default
            label={`${title} 신규 등록`}
            onClick={onClickRegisterBtn}
          />
        )}
        <Button.Export type={type} params={params} />
      </div>
    </div>
  );
};

export default ServiceMenu;
