import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import * as Modal from './../../components/Atoms/Modal';
import * as Button from './../../components/Atoms/Button';
import Counseling from '../../components/Accident/Counseling';
import CustomerDocument from '../../components/Accident/CustomerDocument';
import PartnerDocument from '../../components/Accident/PartnerDocument';
import Repair from '../../components/Accident/Repair';
import WarrantyDiagnosis from '../../components/Accident/WarrantyDiagnosis';
import Claim from '../../services/ClaimService';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { CommonUtil } from '../../utils/commonUtil';
import { BlockUI } from 'primereact/blockui';
import { Dropdown } from 'primereact/dropdown';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { useQuery } from 'react-query';
import { Calendar } from 'primereact/calendar';
import { useRecoilValueLoadable } from 'recoil';
import { myInfoSelector } from '../../recoil/selectors';
import { ServiceProvider } from '../../services/index';
import SendSMS from './SendSMS';
import { Partner } from '../../services/PartnerService';
import RepariDocument from '../../components/Accident/RepariDocument';
import { RepairApi } from '../../services/PerformanceCheckService';
import { useParams } from 'react-router-dom';
import dayjs from 'dayjs';
const kakaoService = ServiceProvider.kakao;
const partnerService = ServiceProvider.partner;

function ClaimDialog() {
  const myInfo = useRecoilValueLoadable(myInfoSelector);
  const partner = myInfo.contents.roleCode === 'D_ADM';
  const aid = myInfo.contents.associationInfo?.associationId;
  const history = useHistory();
  const today = new Date();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [customerInfo, setCustomerInfo] = useState({
    carNbr: '',
    carVIN: '',
    newCarNbr: '',
    customerName: '',
    customerContact: '',
    association: { value: '' },
    warranty: { value: '' },
    receiptDate: null,
    addType: { value: '' },
  }); // 신규 등록
  const [detailData, setDetailData] = useState({}); // claimData 기존고객
  const [docs, setDocs] = useState([]); // claimDocs
  const [carDetailData, setCarDetailData] = useState(null); // 자동자 상세정보
  const [carAddress, setCarAddress] = useState(''); // 차량 위치
  const [warrantyData, setWarrantyData] = useState({
    buyDate: null,
    checkDistance: 0,
    distanceDriven: 0,
    diagnosisType: [],
    reservationDate: '',
    producingCountry: 'D',
    carName: '',
    partnerInfo: [
      {
        partnerName: '',
        partnerType: '', // 진단점 P_D, 수리점 P_R, 둘다 P_DR
        claimStatusCode: '',
        receiptDate: '',
        dueDate: '',
        paymentAmt: 0,
        carNbr: '',
        statementNumber: '',
        checkDate: '',
        partnerId: 0,
        repairCode: '',
        reservationDate: '',
        documentGroupId: '',
        useYn: '',
        fileData: [],
      },
    ],
    radius: 0,
    partnerNote: '', // opt
  });
  const [funnelsType, setFunnelsType] = useState({
    value: 'WC',
    label: '전화 / 소비자',
  });
  const [carYX, setCarYX] = useState('');
  const [partnerList, setPartnerList] = useState([]);
  const [partnerAllList, setPartnerAllList] = useState([]);
  const [fileItems, setFileItems] = useState([]); // 모든 사진 파일
  const [deleteImgs, setDeleteImgs] = useState([]); // 모든 삭제하는 사진 파일
  const [copyFile, setCopyFile] = useState([]);
  const [partnerFile, setPartnerFile] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState([]);
  const [statusCode, setStatusCode] = useState('Z1');
  const [toggleStates, setToggleStates] = useState([
    { idx: 3, status: 'Z3', value: false }, // 서류완료
    { idx: 4, status: 'Z4', value: false }, // 거절완료
    { idx: 5, status: 'Z5', value: false }, // 입고안내 완료
    { idx: 6, status: 'Z6', value: false }, // 입고점검 완료
    { idx: 7, status: 'Z7', value: false }, // 수리중  7
    { idx: 8, status: 'Z8', value: false }, // 수리완료 8
  ]);

  const [repairData, setRepairData] = useState({
    date: '',
    maintenanceCode: '',
    workYN: '',
    workFee: null, // int
    repairNote: '',
  }); // 수리정보

  const [insuranceInfo, setInsuranceInfo] = useState({
    payDate: '',
    paymentAmt: null, // int
    deductibleAmt: null, // int
    completeDate: '',
    insurancePolicyNumber: '', // 증권번호
    code: '10',
  });

  const [consultationList, setConsultationList] = useState([]);
  const [showSmsMoal, setShowSmsModal] = useState(false);
  const [showSmsType, setShowSmsType] = useState('');
  const [smsData, setSmsData] = useState({
    msgType: '',
    sourceAddr: '15332729',
    msgSubType: null,
    destAddr: '',
    subject: null, //opt,
    contents: '',
    fileData: null, //opt
    url: null, // opt
  });
  const [oriCarAddress, setOriCarAddress] = useState('');
  const [newStatusCode, setNewStatusCode] = useState(null);
  const { id } = useParams();

  const handleToggleButton = (name, checked) => {
    if (!detailData?.dataId) return;
    setToggleStates((prev) =>
      prev.map((state) =>
        state.status === name ? { ...state, value: checked } : state
      )
    );

    if (!checked) {
      const idx = Number(name[1]);
      if (idx === 4 || idx === 5) {
        setStatusCode(`Z3`);
        setNewStatusCode(`Z3`);
      } else {
        setStatusCode(`Z${idx - 1}`);
        setNewStatusCode(`Z${idx - 1}`);
      }
    } else {
      setStatusCode(name);
      setNewStatusCode(name);
    }
  };

  useEffect(() => {
    if (!!newStatusCode) {
      registerClaim('수정');
    }
  }, [newStatusCode]);

  useEffect(() => {
    if (statusCode) {
      const index = Number(statusCode[1]);

      setToggleStates((prevStates) =>
        prevStates.map((state) => {
          if (state.idx <= index) {
            return { ...state, value: true };
          } else {
            return { ...state, value: false };
          }
        })
      );
    }
  }, [statusCode]);

  //TODO - 차량가액 가져오기
  const getCarHistoryDatas = async () => {
    setLoading(true);
    try {
      const data = await RepairApi
        .getInsurance
        // currentValues.licensePlateNumber
        ();

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getAddressInfo = async (address) => {
    try {
      const {
        documents: [res],
      } = await kakaoService.getAddressInfo({
        query: address,
      });

      if (res) {
        const coordinates = `${res.y},${res.x}`;
        setCarYX(coordinates);
        return coordinates;
      } else {
        setCarYX('');
        return null;
      }
    } catch (error) {
      console.error(error);
      setCarYX('');
      return null;
    }
  };
  const [partnerAllType, setPartnerAllType] = useState([]);
  const getPartnerAllType = async () => {
    try {
      const data = await Claim.getDocsType('R');
      setPartnerAllType(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPartnerAllType();
  }, []);

  const getPartnerList = async (location, radius, aid) => {
    try {
      const data = await Partner.nearList({
        aids: aid ?? 1,
        page: 1,
        size: 99,
        carRadius: Number(radius),
        carLocation: location,
      });

      if (data) {
        const filter = data.data.list.filter((el) => el.useYn === 'Y');
        const list = [];
        filter.map((el) =>
          list.push({
            partnerName: `${el.partnerName} (${partnerAllType
              .filter((item) =>
                el.partnerRepairType.split(',').includes(item.value)
              )
              .map((item) => item.label)})`,
            partnerId: el.partnerId,
            partnerRepairType: el.partnerRepairType,
            cellphone: el.cellphone,
            address: el.address,
          })
        );
        setPartnerList(list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getPartnerAllList = async (aid) => {
    try {
      const data = await partnerService.list({
        aids: aid ?? 1,
        page: 1,
        size: 99,
        useYN: 'Y',
      });
      if (data) {
        setPartnerAllList(data.data.list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (carYX && warrantyData.radius && !!aid) {
      getPartnerList(carYX, warrantyData.radius, aid);
    }
  }, [carYX, warrantyData.radius, aid]);

  useEffect(() => {
    if (!!aid) {
      getPartnerAllList(aid);
    }
  }, [aid]);

  const [statusList, setStatusList] = useState([]);
  const getStatusList = async () => {
    try {
      const data = await Claim.getDocsType('Z');
      setStatusList(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [insuranceList, setInsuranceList] = useState([]);
  const getInsuranceList = async () => {
    try {
      const data = await Claim.getInsurances();
      const list = data.filter(
        (el) => el.label?.includes('KB') || el.label?.includes('DB')
      );
      setInsuranceList(list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getStatusList();
    getInsuranceList();
  }, []);

  useEffect(() => {
    if (history.location.state) {
      setCustomerInfo(history.location.state.customerInfo);
    }
  }, [history.location.state]);

  const getDetailData = async (dataId) => {
    setLoading(true);
    try {
      const data = await Claim.getDetail(dataId);
      if (data) {
        if (data.claimData.carLocation) {
          setCarYX(data.claimData.carLocation);
        } else if (data.claimData.carAddress) {
          getAddressInfo(data.claimData.carAddress);
        }
        if (data.claimData.carAddress) {
          setCarAddress(data.claimData.carAddress);
          setOriCarAddress(data.claimData.carAddress);
        }
        if (data.claimData.funnelsType) {
          const type = FUNNELS_TYPE.find(
            (el) => el.value === data.claimData.funnelsType
          );
          setFunnelsType(type ? type : { label: '', value: '' });
        }
        setDetailData(data.claimData);
        setDocs(data.claimDocuments);
        setCustomerInfo({
          newCarNbr: data.claimData.newCarNbr,
          customerName: data.claimData.customerName,
          customerContact: data.claimData.customerContact,
          carVIN: data.claimData.vin,
          carNbr: data.claimData.carNbr,
          association: {
            value: !!data.claimData.checkFlag
              ? data.claimData.checkFlag === 'Y'
                ? 'association'
                : 'non_association'
              : null,
          },
          warranty: {
            value: !!data.claimData.assuranceType
              ? data.claimData.assuranceType === '2'
                ? 'warranty'
                : 'warranty_s'
              : null,
          },
          carVIN: data.claimData.vin,
          receiptDate: data.claimData.receiptDate
            ? CommonUtil.Formatter.stringToDate(data.claimData.receiptDate)
            : today,
        });
        setCustomerInfo({
          newCarNbr: data.claimData.newCarNbr,
          customerName: data.claimData.customerName,
          customerContact: data.claimData.customerContact,
          carVIN: data.claimData.vin,
          carNbr: data.claimData.carNbr,
          association: {
            value: !!data.claimData.checkFlag
              ? data.claimData.checkFlag === 'Y'
                ? 'association'
                : 'non_association'
              : null,
          },
          warranty: {
            value: !!data.claimData.assuranceType
              ? data.claimData.assuranceType === '2'
                ? 'warranty'
                : 'warranty_s'
              : null,
          },
          carVIN: data.claimData.vin,
          receiptDate: data.claimData.receiptDate
            ? CommonUtil.Formatter.stringToDate(data.claimData.receiptDate)
            : today,
          addType: {
            value: data.claimData.addType,
          },
        });
        setStatusCode(data.claimData.claimStatus);
        setRepairData({
          date: data.claimData.partnerReceiptDate
            ? CommonUtil.Formatter.stringToDate(
                data.claimData.partnerReceiptDate
              )
            : '',
          maintenanceCode: data.claimData.maintenanceCode
            ? data.claimData.maintenanceCode.split(',')
            : '',
          workYN: data.claimData.workYN,
          workFee: data.claimData.workFee,
          repairNote: data.claimData.repairNote,
          repairOpinion: data.claimData.repairOpinion
            ? data.claimData.repairOpinion
            : '',
        });
        setInsuranceInfo({
          payDate: data.claimData.insurancePurchaseDate
            ? CommonUtil.Formatter.stringToDate(
                data.claimData.insurancePurchaseDate
              )
            : '',
          paymentAmt: data.claimData.insurancePaymentAmt, // int
          deductibleAmt: data.claimData.deductibleAmt, // int
          completeDate: data.claimData.completeDate
            ? CommonUtil.Formatter.stringToDate(data.claimData.completeDate)
            : '',
          insurancePolicyNumber: data.claimData.insurancePolicyNumber
            ? data.claimData.insurancePolicyNumber
            : '',
          code: data.claimData.insuranceCode
            ? data.claimData.insuranceCode
            : insuranceInfo.code,
        });
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const filterPartnerTypeOptions = (partnerAllType, repairCodes) => {
    if (!repairCodes) return [];
    return partnerAllType.filter((el) => repairCodes.includes(el.value));
  };

  //ANCHOR - set partner info
  useEffect(() => {
    if (!!warrantyData.partnerInfo.length && !!partnerAllList.length) {
      const data = warrantyData.partnerInfo
        .map((el) => {
          const partner = partnerAllList.find(
            (el2) => Number(el.partnerId) === el2.partnerId
          );
          if (partner) {
            let newSelectPartner = {
              partnerName: partner?.partnerName,
              partnerType: el?.partnerType.split(',') ?? [], // 진단점 P_D, 수리점 P_R, 둘다 P_DR
              claimStatusCode: el?.claimStatusCode ?? null,
              receiptDate: el?.receiptDate
                ? CommonUtil.Formatter.stringToDate(el?.receiptDate)
                : null,
              dueDate: el.dueDate
                ? CommonUtil.Formatter.stringToDate(el.dueDate)
                : '',
              paymentAmt: el?.paymentAmt ?? null,
              checkDate: el?.checkDate ?? null,
              partnerId: partner.partnerId,
              reservationDate: el?.reservationDate
                ? CommonUtil.Formatter.stringToDate(el?.reservationDate)
                : null,
              documentGroupId: el?.documentCode ?? null,
              useYn: 'Y',
              partnerName: partner.partnerName,
              address: partner.address,
              cellphone: partner.cellphone,
              isOriginal: true,
              partnerDocuments: el.partnerDocuments,
            };
            newSelectPartner.partnerTypeOptions = filterPartnerTypeOptions(
              partnerAllType,
              partner.partnerRepairType
            );
            newSelectPartner.repairCodes = el.repairCode
              ? el.repairCode.split(',')
              : [];
            return newSelectPartner;
          }
          return null;
        })
        .filter(Boolean);
      setSelectedPartner(data.flat());
    }
  }, [warrantyData.partnerInfo, partnerAllList, partnerAllType]);

  useEffect(() => {
    if (docs) {
      setFileItems(docs);
      setCopyFile(docs);
    }
  }, [docs]);

  // 클레임 POST, PUT
  const registerClaim = async (type) => {
    setLoading(true);

    const fileData = [];
    const files = [];
    const pfiles = [];

    let fileDataList = [];
    let filesList = [];
    let pfileData = [];

    if (type === '저장') {
      fileItems.map((el) =>
        fileData.push({
          documentCode: el.documentCode,
          state: el.state,
        })
      );
      fileItems.map((el) => files.push(el.file));
    } else if (type === '수정') {
      const del = [];
      if (!!deleteImgs.length) {
        const imgs = deleteImgs.filter((el) => !!el.imgId);

        if (!!imgs.length) {
          imgs.map((el) =>
            del.push({
              imgId: el.imgId,
              documentCode: el.documentCode,
              state: 'D',
            })
          );
        }
        fileDataList = del;
      }

      const findFile = fileItems.find((el) => el.file);
      const findImgId = fileItems.find((el) => el.imgId);
      const idFile = [];
      fileItems.filter((el) => el.imgId && idFile.push(el));
      const imgFile = [];
      fileItems.filter((el) => el.file && imgFile.push(el));

      if (findFile && !findImgId) {
        fileItems.map((el) =>
          fileDataList.push({
            documentCode: el.documentCode,
            state: el.state,
          })
        );
        fileItems.map((el) => filesList.push(el.file));
      } else if (findFile && findImgId) {
        imgFile.map((el) =>
          fileDataList.push({
            documentCode: el.documentCode,
            state: el.state,
          })
        );
        const { fileData, files } =
          CommonUtil.File.convertForServerClaim(deleteImgs);
        files.map((el) => filesList.push(el));
        imgFile.map((el) => filesList.push(el.file));
      }
    }

    const partnerFiles = [];
    selectedPartner.map((partner) =>
      partner.partnerDocuments?.filter(
        (el) => el.file && partnerFiles.push(el.file)
      )
    );

    const modifyPartners = selectedPartner.map((partner) => {
      partner.partnerDocuments = partner.partnerDocuments
        ?.map((el) => {
          const { file, imgId, ...rest } = el;
          if (imgId) {
            return null;
          } else {
            return rest;
          }
        })
        .filter((el) => el !== null);
      return partner;
    });

    const filteredPartner = warrantyData?.selectedPartner?.filter(
      (partner) => !(partner.useYn === 'N' && partner.isOriginal === false)
    );
    if (
      !!filteredPartner &&
      filteredPartner.some(
        (el) => !el.repairCodes || el.repairCodes.length === 0
      )
    ) {
      setLoading(false);
      window.cerp.toast.warn('진단점의 진단부위를 선택해주세요.');
      return;
    }

    let partnerInfoData = [];
    if (!!modifyPartners) {
      partnerInfoData = modifyPartners.map((el) => ({
        partnerName: el?.partnerName?.split(' (')[0],
        carNbr: customerInfo.carNbr,
        statementNumber: !!carDetailData
          ? carDetailData?.performanceCheckData.statementNumber
          : null,
        checkDate: !!carDetailData
          ? carDetailData.performanceCheckData.checkDate
          : null,
        partnerId: el.partnerId,
        repairCode: el.repairCodes?.toString(),
        reservationDate: !!el.reservationDate
          ? CommonUtil.Formatter.dateToString(el.reservationDate)
          : null,
        useYn: el.useYn,
        documentGroupId: detailData?.documentGroupId ?? null,
        fileData: el.partnerDocuments ?? [],
        partnerType: !!el.partnerType ? el.partnerType?.toString() : null,
        claimStatusCode: el?.claimStatusCode ?? null,
        receiptDate: !!el.receiptDate
          ? CommonUtil.Formatter.dateToString(el.receiptDate)
          : null,
        dueDate: el?.dueDate ? dayjs(el?.dueDate).format('YYYYMMDD') : null,
        paymentAmt: el?.paymentAmt ?? null,
      }));
    }

    let coordinates = '';
    if (type === '수정') {
      if (carAddress !== '') {
        if (oriCarAddress !== carAddress) {
          coordinates = await getAddressInfo(carAddress);
          if (!coordinates) {
            setLoading(false);
            window.cerp.toast.warn(
              '존재하지 않는 주소입니다. 주소를 확인해주세요.'
            );
            return;
          }
        } else {
          coordinates = carYX; // 동일한 주소
        }
      }
    }

    const Data = {
      dataId: detailData?.dataId ?? null,
      associationId: aid,
      addType:
        customerInfo?.addType?.value === 'null'
          ? null
          : customerInfo?.addType?.value, // B 법인, L 리스, F 외판 opt
      funnelsType: funnelsType.value, // W or A
      claimStatus: statusCode,
      customerName: customerInfo?.customerName,
      customerContact: customerInfo?.customerContact.replace(/[^0-9]/g, ''),
      checkFlag: customerInfo?.association.value === 'association' ? 'Y' : 'N',
      assuranceType: customerInfo?.warranty.value === 'warranty' ? '2' : '1',
      statementNumber: !!carDetailData
        ? carDetailData.performanceCheckData?.statementNumber
        : null, // opt
      carNbr: customerInfo.carNbr ?? null,
      vin: !!customerInfo?.carVIN ? customerInfo?.carVIN : null,
      newCarNbr: customerInfo.newCarNbr
        ? customerInfo.newCarNbr
        : detailData.newCarNbr
        ? detailData.newCarNbr
        : null,
      checkDate: !!carDetailData
        ? carDetailData.performanceCheckData?.checkDate
        : null, // opt
      buyDate:
        warrantyData.buyDate && typeof warrantyData.buyDate !== 'string'
          ? CommonUtil.Formatter.dateToString(warrantyData.buyDate)
          : null,
      checkDistance: warrantyData.checkDistance
        ? warrantyData.checkDistance
        : null, //vehicleInformation > TRVL_DSTNC
      distanceDriven: warrantyData.distanceDriven
        ? Number(warrantyData.distanceDriven)
        : null,
      producingCountry: warrantyData.producingCountry
        ? warrantyData.producingCountry
        : null, // D or F // opt
      carName: warrantyData.carName ? warrantyData.carName : null,
      carAddress: carAddress ? carAddress : null,
      carLocation: coordinates, //Opt
      diagnosisType:
        warrantyData.diagnosisType &&
        typeof warrantyData.diagnosisType === 'string'
          ? warrantyData.diagnosisType
          : warrantyData.diagnosisType && warrantyData.diagnosisType.length > 0
          ? warrantyData.diagnosisType.join()
          : null, // 진단타입 B, D..
      consultationGroupId: detailData?.consultationGroupId
        ? detailData?.consultationGroupId
        : null,
      partnerInfo: !!partnerInfoData.length ? partnerInfoData : null, //Opt 진단점 목록
      receiptId: myInfo.contents.userId ? myInfo.contents.userId : null,
      receiptDate: customerInfo.receiptDate
        ? CommonUtil.Formatter.dateToString(customerInfo.receiptDate)
        : today,
      documentGroupId: type === '저장' ? null : detailData?.documentGroupId, //Opt
      note: null, //Opt
      partenerReceiptDate: detailData?.partenerReceiptDate ?? null, //Opt 수리 입고일자
      maintenanceCode:
        repairData.maintenanceCode.length > 0
          ? repairData.maintenanceCode.join()
          : null,
      workYN: repairData.workYN ? repairData.workYN : null, //Opt 수리여부
      workFee: repairData.workFee ? Number(repairData.workFee) : null, //Opt 협의비용
      insuranceCode: insuranceInfo.code, // opt 보험코드
      insurancePolicyNumber: insuranceInfo.insurancePolicyNumber ?? null, //opt 증권번호
      insurancePurchaseDate: insuranceInfo.insurancePolicyNumber ?? null, //Opt 보험료 지급일
      insurancePaymentAmt: insuranceInfo.paymentAmt ?? null, //Opt 보험료
      deductibleAmt: insuranceInfo.deductibleAmt ?? null, // opt 자기부담금
      partnerNote: warrantyData.partnerNote ? warrantyData.partnerNote : null, //Opt
      repairNote: repairData.repairNote ? repairData.repairNote : null,
      repairOpinion: repairData.repairOpinion ? repairData.repairOpinion : null, // Opt 소견내용
      completeYN: insuranceInfo.completeDate ? 'Y' : 'N', //Opt 완료여부
      completeDate: insuranceInfo.completeDate
        ? CommonUtil.Formatter.dateToString(insuranceInfo.completeDate)
        : null, //Opt 완료일
      completeLoginId: myInfo.contents.loginId, //Opt 로그인유저 아이디
      completeName: myInfo.contents.userName, //Opt 로그인유저 이름
      completeLoginContact: myInfo.contents.contact, //opt 로그인유저 연락처
      fileData: type === '저장' ? fileData : fileDataList, //Otp
    };

    const checkData =
      !!Data.funnelsType &&
      !!Data.checkFlag &&
      !!Data.assuranceType &&
      !!Data.customerName &&
      !!Data.customerContact &&
      !!Data.carNbr &&
      !!Data.receiptDate;

    if (!checkData) {
      setLoading(false);
      window.cerp.toast.warn('필수 입력값을 입력해주세요.');
      return;
    }

    // console.log(Data);

    try {
      if (type === '저장') {
        const data = await Claim.registerClaim(Data, files, pfiles);
        if (data) {
          window.cerp.toast.info('등록이 완료 되었습니다.');
          history.replace(
            `/compensation/accident/claim/detail/${data.data.dataId}`
          );
          sessionStorage.removeItem('images');
          setLoading(false);
        }
      } else if (type === '수정') {
        const data = await Claim.updateClaim(Data, filesList, partnerFiles);
        if (data) {
          window.cerp.toast.info('수정이 완료 되었습니다.');
          history.push(`/compensation/accident/claim/detail/${id}`);
          getDetailData(data.dataId);
          setDeleteImgs([]);
          sessionStorage.removeItem('images');
          setLoading(false);
          setNewStatusCode(null);
        }
      }
    } catch (error) {
      console.log(error);
      window.cerp.toast.error(error?.message);
      setLoading(false);
    }
  };

  const deleteClaim = async (id) => {
    try {
      const data = await Claim.deleteClaim(id);
      // console.log(data);
      if (data) {
        window.cerp.toast.info('삭제가 완료 되었습니다.');
        history.push('/compensation/accident/list');
      }
    } catch (error) {
      console.log(error);
      window.cerp.toast.error(error?.response?.data?.error?.message);
    }
  };

  useEffect(() => {
    if (!!id && id !== 'new') {
      getDetailData(id);
    }
  }, [id]);

  const NEW = id === 'new';

  const handleInput = (event) => {
    const target = event.target;
    const { name, value } = target;
    setCustomerInfo({ ...customerInfo, [name]: value });
  };

  const checkWarranty = async (carNum, VIN) => {
    setLoading(true);
    try {
      const data = await Claim.getCheckWarranty(carNum, VIN);
      const vehicleInformation = JSON.parse(
        data.performanceCheckData.vehicleInformation
      );
      if (data) {
        setLoading(false);
        setCarDetailData(data);
        setCustomerInfo({
          ...customerInfo,
          carVIN: vehicleInformation.VIN + vehicleInformation.vinRemain,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      window.cerp.toast.warn(error?.response.data.error?.message);
    }
  };

  useEffect(() => {
    if (customerInfo && customerInfo.association?.value === 'association') {
      // checkWarranty(customerInfo.carNbr);
    } else if (customerInfo.carNbr) {
      // checkWarranty(customerInfo.carNum);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerInfo.dataId, customerInfo.carNbr]);

  const getList = async (id) => {
    try {
      const data = await Claim.getConsultation({
        page: 1,
        size: 10,
        consultationGroupId: id,
      });
      setConsultationList(data.list);
    } catch (error) {
      console.log(error);
    }
  };

  const registerConsultation = async (text) => {
    const id = detailData?.consultationGroupId
      ? detailData?.consultationGroupId
      : `CON_${customerInfo.carNbr}`;
    try {
      const data = await Claim.posetConsultation({
        consultationGroupId: id,
        consultationDetails: text,
      });
      if (data) {
        getList(id);
      }
    } catch (error) {
      console.log(error);
      window.cerp.toast.warn(error?.response.data.error?.message);
    }
  };

  const changeConsultation = async (dataId, text, useYN) => {
    setLoading(true);
    try {
      const data = await Claim.updateConsultation({
        dataId: dataId,
        consultationDetails: text,
        useYN: useYN,
      });
      if (data) {
        if (useYN === 'Y') {
          window.cerp.toast.info('수정이 완료 되었습니다.');
        } else {
          window.cerp.toast.info('삭제가 완료 되었습니다.');
        }
        getList(detailData?.consultationGroupId);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!!detailData?.consultationGroupId) {
      getList(detailData?.consultationGroupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailData?.consultationGroupId]);

  const saveFilesAsZip = async () => {
    window.cerp.toast.info('다운로드를 시작합니다.');

    const partnerFiles = [];
    selectedPartner.map((partner) =>
      partner.partnerDocuments.map((doc) => partnerFiles.push(doc))
    );

    const folderName = customerInfo.carNbr;
    const files = fileItems.concat(partnerFiles);
    const haveIds = [];
    files.map((el) => (el.imgId ? haveIds.push(el.imgId) : null));
    if (!files.length) return window.cerp.toast.warn('서류가 없습니다.');
    if (!haveIds.length)
      return window.cerp.toast.warn('클레임 저장 후 가능합니다.');
    const zip = new JSZip();
    const imagesFolder = zip.folder(folderName);
    const imagePromises = files.map(async (file, index) => {
      const imgBlob = await Claim.getBlobFromUrl(file.filePath);
      imagesFolder.file(`img_${index + 1}.jpg`, imgBlob, { binary: true });
    });
    await Promise.all(imagePromises);
    zip.generateAsync({ type: 'blob' }).then(function (content) {
      saveAs(content, `${folderName}.zip`);
    });
    window.cerp.toast.info('다운로드가 완료되었습니다.');
  };

  const postSms = async (title) => {
    setLoading(true);
    const noUrl =
      title === '고객 서류 재요청 안내' ||
      title === '성능접수 안내' ||
      title === '보증 거절 안내' ||
      title === '진단점 정보 안내';

    const postData = {
      msgType: smsData.msgType ? smsData.msgType : 'lms',
      msgSubType: smsData.msgType === 'at' ? smsData.msgSubType : null, //opt
      sourceAddr: smsData.sourceAddr,
      destAddr: smsData.destAddr.replaceAll('-', ''),
      subject: smsData.subject, // opt
      contents: smsData.contents,
      fileData: smsData.fileData,
      url: noUrl ? null : smsData.url,
    };
    // console.log(postData);
    try {
      const data = await Claim.postSMS(postData);
      if (data) {
        window.cerp.toast.info('메세지 전송이 완료되었습니다.');
        setShowSmsModal(false);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      window.cerp.toast.warn(error?.response?.data.error?.message);
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="px-3 py-2 fixed surface-200 w-full z-5 border-bottom-1 border-400">
        <div className="flex justify-content-between">
          <div className="flex align-items-center gap-2">
            <div className="font-bold ml-2 text-xl">클레임 관리</div>
          </div>
          <div className="flex gap-4">
            {partner ? null : (
              <div className="flex gap-2">
                <div className="flex align-items-center">*업무 진행상태 :</div>
                <Dropdown
                  value={statusCode}
                  onChange={(e) => {
                    setStatusCode(e.target.value);
                  }}
                  options={statusList}
                  optionLabel="label"
                  placeholder="상태 선택"
                  className="w-full md:w-16rem font-bold"
                />
              </div>
            )}
            <Button.Default
              className="text-lg"
              icon="pi pi-save"
              label={'저장'}
              onClick={() => registerClaim(NEW ? '저장' : '수정')}
            />
            <Button.Default
              className="p-button-outlined p-button-danger"
              icon="pi pi-times"
              label=""
              onClick={() => {
                sessionStorage.removeItem('images');
                sessionStorage.removeItem('data');
                history.push('/compensation/accident/list');
              }}
            />
          </div>
        </div>
      </div>
      <BlockUI
        fullScreen
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
        blocked={loading}
      >
        <div className="p-3 pt-8 gap-2 h-screen" id="claim-dialog">
          <div className="grid pb-3">
            <div className={partner ? 'col-12' : 'col-8'}>
              <Panel
                className="mb-3"
                header={
                  <div className="flex justify-content-between align-items-center">
                    <div>1. 보험 정보</div>
                  </div>
                }
              >
                <div className="grid lg:col-12 sm:col p-3">
                  <div className=" grid lg:col-4 align-items-center">
                    <div className="w-6rem font-bold">
                      <label htmlFor="">보험사</label>
                    </div>
                    <div className="w-18rem">
                      <Dropdown
                        value={insuranceInfo.code}
                        onChange={(e) =>
                          setInsuranceInfo({
                            ...insuranceInfo,
                            code: e.value,
                          })
                        }
                        options={insuranceList}
                        optionLabel="label"
                        placeholder="보험사 선택"
                        className="w-full"
                      />
                    </div>
                  </div>
                  <div className="grid lg:col-4 align-items-center">
                    <div className="w-6rem font-bold">
                      <label htmlFor="">성능점검장</label>
                    </div>
                    <div className="w-18rem">
                      <Dropdown
                        // value={insuranceInfo.code}
                        // onChange={(e) =>
                        //   setInsuranceInfo({
                        //     ...insuranceInfo,
                        //     code: e.value,
                        //   })
                        // }
                        // options={insuranceList}
                        optionLabel="label"
                        placeholder="점검장 선택"
                        className="w-full"
                      />
                    </div>
                  </div>

                  <div className=" grid lg:col-4 align-items-center">
                    <div className=" w-6rem font-bold">
                      <label htmlFor="">담당자</label>
                    </div>
                    <div className="w-18rem">
                      <InputText
                        readOnly
                        value={myInfo.contents.userName}
                        className=" w-full"
                      />
                    </div>
                  </div>
                </div>
              </Panel>
              <Panel
                header={
                  <div className="flex justify-content-between align-items-center">
                    <div>2. 접수 내역</div>
                    <Button.Default
                      label="앱 다운로드 SMS 재전송"
                      onClick={
                        customerInfo.customerName &&
                        customerInfo.customerContact
                          ? () => {
                              setSmsData({
                                ...smsData,
                                destAddr: `${customerInfo.customerContact}`,
                              });
                              setShowSmsModal(true);
                              setShowSmsType('APP_DOWNLOAD');
                            }
                          : () =>
                              window.cerp.toast.warn(
                                '고객명, 고객연락처를 입력하세요.'
                              )
                      }
                    />
                  </div>
                }
                className=" sm:text-base text-2xl"
              >
                <div className="grid">
                  <div className="col-5 p-3">
                    <div className="p-card-title field">기본정보</div>
                    <div className="field grid align-items-center">
                      <div className="col-3">
                        <label className="font-bold" htmlFor="">
                          접수자명
                        </label>
                      </div>
                      <div className="col">
                        <InputText
                          value={customerInfo?.customerName || ''}
                          onChange={(e) => handleInput(e)}
                          onClick={() => {
                            !NEW &&
                              window.cerp.toast.warn(
                                '앱에서 등록된 정보입니다. 수정하시겠습니까?'
                              );
                          }}
                          name="customerName"
                          className=" w-full"
                          placeholder="접수자명 입력"
                        />
                      </div>
                    </div>
                    <div className="field grid align-items-center">
                      <div className="col-3">
                        <label className="font-bold" htmlFor="">
                          접수자 연락처
                        </label>
                      </div>
                      <div className="col">
                        <InputText
                          // readOnly={!NEW}
                          value={
                            CommonUtil.Formatter.phone(
                              customerInfo?.customerContact
                            ) || ''
                          }
                          maxLength={13}
                          onChange={(e) => handleInput(e)}
                          onClick={() => {
                            !NEW &&
                              window.cerp.toast.warn(
                                '앱에서 등록된 정보입니다. 수정하시겠습니까?'
                              );
                          }}
                          name="customerContact"
                          className=" w-full"
                          placeholder="연락처 입력"
                        />
                      </div>
                    </div>
                    <div className="field grid align-items-center">
                      <div className="col-3">
                        <label className="font-bold" htmlFor="">
                          접수일자
                        </label>
                      </div>
                      <div className="col">
                        <Calendar
                          value={customerInfo?.receiptDate || ''}
                          onChange={(e) => handleInput(e)}
                          dateFormat="yy-mm-dd"
                          name="receiptDate"
                          maxDate={today}
                          className="w-full"
                          placeholder="접수일자 선택"
                        />
                      </div>
                    </div>
                    <div className="field grid align-items-center">
                      <div className="col-3">
                        <label className="font-bold" htmlFor="">
                          접수방법
                        </label>
                      </div>
                      <div className="col">
                        <Dropdown
                          value={funnelsType.value}
                          onChange={(e) => {
                            const type = FUNNELS_TYPE.find(
                              (el) => el.value === e.target.value
                            );
                            setFunnelsType(type);
                          }}
                          options={FUNNELS_TYPE}
                          optionLabel="label"
                          placeholder="접수유형 선택"
                          className="w-full"
                        />
                      </div>
                    </div>

                    {/* </Card> */}
                  </div>
                  {/* <div className="col-6"> */}
                  <div className="col-7 border-left-1 border-gray-300 p-3">
                    {/* <Card title="차량 정보"> */}
                    <div className="p-card-title field">차량정보</div>
                    <div className="field grid align-items-center">
                      <div className="col-3">
                        <label className="font-bold" htmlFor="">
                          차량번호
                        </label>
                      </div>
                      <div className="col">
                        <InputText
                          value={
                            NEW
                              ? customerInfo?.carNbr
                              : detailData?.carNbr || ''
                          }
                          onChange={(e) => handleInput(e)}
                          name="carNbr"
                          className=" w-full font-bold"
                        />
                      </div>

                      <div className="">
                        <label className="font-bold" htmlFor="">
                          차량번호(변경)
                        </label>
                      </div>
                      <div className="col">
                        <InputText
                          value={
                            NEW
                              ? customerInfo?.newCarNbr
                              : detailData?.newCarNbr || ''
                          }
                          onChange={(e) => handleInput(e)}
                          name="newCarNbr"
                          className=" w-full"
                          placeholder="차량번호 변경된 경우"
                        />
                      </div>
                    </div>
                    <div className="field grid align-items-center">
                      <div className="col-3">
                        <label className="font-bold" htmlFor="">
                          차대번호
                        </label>
                      </div>
                      <div className="col">
                        <InputText
                          value={customerInfo?.carVIN || ''}
                          onChange={(e) => handleInput(e)}
                          name="carVIN"
                          className=" w-full font-bold"
                        />
                      </div>
                    </div>

                    <div className="field grid">
                      <div className="col-3 font-bold">보증가능</div>
                      <div className="col">
                        <RadioButton
                          inputId="association"
                          name="customerSearchBy"
                          value="association"
                          className="mr-2"
                          checked={
                            customerInfo?.association?.value === 'association'
                          }
                          onChange={(e) =>
                            setCustomerInfo({
                              ...customerInfo,
                              association: { value: e.value },
                            })
                          }
                        />
                        <label htmlFor="association">SR 진단차량</label>
                        <RadioButton
                          inputId="non_association"
                          name="customerSearchBy"
                          value="non_association"
                          className="ml-4 mr-2"
                          checked={
                            customerInfo?.association?.value ===
                            'non_association'
                          }
                          onChange={(e) =>
                            setCustomerInfo({
                              ...customerInfo,
                              association: { value: e.value },
                            })
                          }
                        />
                        <label htmlFor="non_association">미 진단차량</label>
                      </div>
                    </div>
                    <div className="field grid ">
                      <div className="col-3 font-bold">보증구분</div>
                      <div className="col">
                        <RadioButton
                          inputId="warranty"
                          name="customerSearchBy"
                          value="warranty"
                          className="mr-2"
                          checked={customerInfo?.warranty?.value === 'warranty'}
                          onChange={(e) =>
                            setCustomerInfo({
                              ...customerInfo,
                              warranty: { value: e.value },
                            })
                          }
                        />
                        <label htmlFor="warranty">보험사 보증</label>
                        <RadioButton
                          inputId="warranty_s"
                          name="customerSearchBy"
                          value="warranty_s"
                          className="mr-2 ml-4 "
                          checked={
                            customerInfo?.warranty?.value === 'warranty_s'
                          }
                          onChange={(e) =>
                            setCustomerInfo({
                              ...customerInfo,
                              warranty: { value: e.value },
                            })
                          }
                        />
                        <label htmlFor="warranty_s">자가보증</label>
                      </div>
                    </div>
                    <div className="field grid ">
                      <div className="col-3 font-bold">추가구분</div>
                      <div className="col">
                        <RadioButton
                          inputId="addTypeB"
                          name="customerSearchBy"
                          value="B"
                          className="mr-2"
                          checked={customerInfo?.addType?.value === 'B'}
                          onChange={(e) =>
                            setCustomerInfo({
                              ...customerInfo,
                              addType: { value: e.value },
                            })
                          }
                        />
                        <label htmlFor="addTypeB">법인</label>
                        <RadioButton
                          inputId="addTypeL"
                          name="customerSearchBy"
                          value="L"
                          className="mr-2 ml-3"
                          checked={customerInfo?.addType?.value === 'L'}
                          onChange={(e) =>
                            setCustomerInfo({
                              ...customerInfo,
                              addType: { value: e.value },
                            })
                          }
                        />
                        <label htmlFor="addTypeL">리스</label>
                        <RadioButton
                          inputId="addTypeF"
                          name="customerSearchBy"
                          value="F"
                          className="mr-2 ml-3"
                          checked={customerInfo?.addType?.value === 'F'}
                          onChange={(e) =>
                            setCustomerInfo({
                              ...customerInfo,
                              addType: { value: e.value },
                            })
                          }
                        />
                        <label htmlFor="addTypeF">외판</label>
                        <RadioButton
                          inputId="addType"
                          name="customerSearchBy"
                          value="null"
                          className="mr-2 ml-3"
                          checked={customerInfo?.addType?.value === 'null'}
                          onChange={(e) =>
                            setCustomerInfo({
                              ...customerInfo,
                              addType: { value: e.value },
                            })
                          }
                        />
                        <label htmlFor="addType">없음</label>
                      </div>
                    </div>
                    <div className="field grid mb-0 align-items-center">
                      <div className="col-3">
                        <label className="font-bold" htmlFor="">
                          차량위치
                        </label>
                      </div>
                      <div className="col">
                        <InputText
                          readOnly={partner}
                          value={carAddress}
                          onChange={(e) => setCarAddress(e.target.value)}
                          className=" w-full"
                          placeholder="고객 차량위치 입력"
                        />
                      </div>
                    </div>
                  </div>
                  {/* </Card> */}
                </div>
              </Panel>
              {partner ? null : (
                <>
                  <CustomerDocument
                    deleteImgs={deleteImgs}
                    setDeleteImgs={setDeleteImgs}
                    fileItems={fileItems}
                    setFileItems={setFileItems}
                    copyFile={copyFile}
                    setShowSmsType={setShowSmsType}
                    setShowSmsModal={setShowSmsModal}
                    carNbr={customerInfo?.carNbr}
                    checked={toggleStates}
                    onToggle={handleToggleButton}
                  />
                  <WarrantyDiagnosis
                    detailData={detailData}
                    carInfo={carDetailData}
                    setWarrantyData={setWarrantyData}
                    warrantyData={warrantyData}
                    partnerAllType={partnerAllType}
                    partnerAllList={partnerAllList}
                    partnerList={partnerList}
                    setShowSmsType={setShowSmsType}
                    setShowSmsModal={setShowSmsModal}
                    setSmsData={setSmsData}
                    smsData={smsData}
                    checked={toggleStates}
                    onToggle={handleToggleButton}
                    selectedPartner={selectedPartner}
                    setSelectedPartner={setSelectedPartner}
                  />
                </>
              )}

              <PartnerDocument
                deleteImgs={deleteImgs}
                setDeleteImgs={setDeleteImgs}
                fileItems={partnerFile}
                setFileItems={setPartnerFile}
                copyFile={copyFile}
                carNbr={customerInfo?.carNbr}
                checked={toggleStates}
                onToggle={handleToggleButton}
                selectedPartner={selectedPartner}
                setSelectedPartner={setSelectedPartner}
                repairData={repairData}
                setRepairData={setRepairData}
                setShowSmsType={setShowSmsType}
                setShowSmsModal={setShowSmsModal}
                setSmsData={setSmsData}
                smsData={smsData}
              />

              {partner ? null : (
                <>
                  {/* <Repair
                    repairData={repairData}
                    setRepairData={setRepairData}
                    setShowSmsType={setShowSmsType}
                    setShowSmsModal={setShowSmsModal}
                    checked={toggleStates}
                    onToggle={handleToggleButton}
                  /> */}
                  {/* <RepariDocument
                    setShowSmsType={setShowSmsType}
                    setShowSmsModal={setShowSmsModal}
                    selectedPartner={selectedPartner}
                    setSelectedPartner={setSelectedPartner}
                    partnerAllList={partnerAllList}
                    deleteImgs={deleteImgs}
                    setDeleteImgs={setDeleteImgs}
                    fileItems={partnerFile}
                    setFileItems={setPartnerFile}
                    copyFile={copyFile}
                    carNbr={customerInfo?.carNbr}
                    repairData={repairData}
                    setRepairData={setRepairData}
                    checked={toggleStates}
                    onToggle={handleToggleButton}
                  /> */}
                  <Panel
                    header={
                      <div className="flex justify-content-between align-items-center">
                        <div>6. 마감정보</div>
                      </div>
                    }
                    className="pt-3"
                  >
                    {/* <Card> */}
                    <div className="grid lg:col-12 sm:col p-3">
                      <div className=" grid lg:col-4 align-items-center">
                        <div className="w-6rem font-bold">
                          <label htmlFor="">보험사</label>
                        </div>
                        <div className="w-18rem">
                          <Dropdown
                            value={insuranceInfo.code}
                            onChange={(e) =>
                              setInsuranceInfo({
                                ...insuranceInfo,
                                code: e.value,
                              })
                            }
                            options={insuranceList}
                            optionLabel="label"
                            placeholder="보험사 선택"
                            className="w-full"
                          />
                        </div>
                      </div>

                      <div className=" grid lg:col-4 align-items-center">
                        <div className=" w-6rem font-bold">
                          <label htmlFor="">증권번호</label>
                        </div>
                        <div className="w-18rem">
                          <InputText
                            onChange={(e) =>
                              setInsuranceInfo({
                                ...insuranceInfo,
                                insurancePolicyNumber: e.target.value,
                              })
                            }
                            value={insuranceInfo.insurancePolicyNumber}
                            className=" w-full"
                          />
                        </div>
                      </div>
                      <div className=" grid lg:col-4 align-items-center">
                        <div className=" w-6rem font-bold">
                          <label htmlFor="">보험료 총액</label>
                        </div>
                        <div className="w-18rem">
                          <InputText
                            onChange={(e) =>
                              setInsuranceInfo({
                                ...insuranceInfo,
                                paymentAmt: e.target.value,
                              })
                            }
                            value={insuranceInfo.paymentAmt}
                            className=" w-full"
                          />
                        </div>
                      </div>
                      <div className=" grid lg:col-4 align-items-center">
                        <div className=" w-6rem font-bold">
                          <label htmlFor="">자기부담금</label>
                        </div>
                        <div className="w-18rem">
                          <InputText
                            onChange={(e) =>
                              setInsuranceInfo({
                                ...insuranceInfo,
                                deductibleAmt: e.target.value,
                              })
                            }
                            value={insuranceInfo.deductibleAmt}
                            className=" w-full"
                          />
                        </div>
                      </div>
                      <div className=" grid lg:col-4 align-items-center">
                        <div className=" w-6rem font-bold">
                          <label htmlFor="">작성자</label>
                        </div>
                        <div className="w-18rem">
                          <InputText
                            readOnly
                            value={myInfo.contents.userName}
                            className=" w-full"
                          />
                        </div>
                      </div>
                      <div className=" grid lg:col-4 align-items-center">
                        <div className=" w-6rem font-bold">
                          <label htmlFor="">마감일</label>
                        </div>
                        <div className="w-18rem">
                          <Calendar
                            value={insuranceInfo.completeDate}
                            onChange={(e) =>
                              setInsuranceInfo({
                                ...insuranceInfo,
                                completeDate: e.target.value,
                              })
                            }
                            dateFormat="yy-mm-dd"
                            showButtonBar
                            showIcon
                            mask="9999-99-99"
                            placeholder="마감일 선택"
                            className="w-18rem"
                          />
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                    {/* </Card> */}
                  </Panel>
                  <div className="flex justify-content-between pt-4	pb-2">
                    <Button.Default
                      className="p-button-danger"
                      icon="pi pi-trash"
                      label="클레임 삭제"
                      onClick={() => setShowModal(true)}
                    />
                    <Button.Default
                      className=""
                      icon="pi pi-download"
                      label="이미지 전체 다운로드"
                      onClick={() => saveFilesAsZip()}
                    />
                  </div>
                </>
              )}
            </div>
            {partner ? null : (
              <div className="col-4 fixed right-0 z-5 top-10">
                <Counseling
                  NEW={NEW}
                  consultationList={consultationList}
                  registerConsultation={registerConsultation}
                  changeConsultation={changeConsultation}
                />
              </div>
            )}
          </div>
        </div>
      </BlockUI>

      {showModal && (
        <Modal.Default
          onHide={() => setShowModal(!showModal)}
          header={'클레임 삭제'}
          widthClass="30rem"
        >
          <div className="pb-4">클레임을 정말 삭제하시겠습니까?</div>
          <div className="flex gap-4 justify-content-center	">
            <Button.Default
              label="삭제"
              className=" p-button-danger"
              onClick={() => {
                deleteClaim(detailData?.dataId);
                setShowModal(false);
              }}
            />
            <Button.Default
              label="취소"
              className=""
              onClick={() => setShowModal(false)}
            />
          </div>
        </Modal.Default>
      )}

      {showSmsMoal && showSmsType && (
        <SendSMS
          showSmsType={showSmsType}
          smsData={smsData}
          setSmsData={setSmsData}
          customerInfo={customerInfo}
          onHide={() => setShowSmsModal(!showSmsMoal)}
          warrantyData={warrantyData}
          postSms={postSms}
        />
      )}
    </>
  );
}

export default ClaimDialog;

const FUNNELS_TYPE = [
  { value: 'WC', label: '전화 / 소비자' },
  { value: 'WS', label: '전화 / 딜러' },
  { value: 'WE', label: '전화 / 기타' },
  { value: 'AC', label: '앱 / 소비자' },
  { value: 'AS', label: '앱 / 딜러' },
  { value: 'AE', label: '앱 / 기타' },
];
