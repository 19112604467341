import { ProgressBar } from 'primereact/progressbar';
import React from 'react';

export class ToastUtil {
  toastRef = null;

  constructor(toastRef) {
    this.toastRef = toastRef;
  }

  success(summary, detail) {
    this.showToast('success', summary, detail);
  }

  error(summary, detail) {
    this.showToast('error', summary, detail);
  }

  warn(summary, detail) {
    this.showToast('warn', summary, detail);
  }

  info(summary, detail) {
    this.showToast('info', summary, detail);
  }

  showToast(severity, summary, detail) {
    this.toastRef.current.show({
      severity,
      summary,
      detail,
    });
  }

  showStickyToast(summary, detail) {
    this.toastRef.current.show({
      severity: 'info',
      sticky: true,
      summary,
      detail,
      content: (props) => (
        <div className="flex flex-column" style={{ flex: '1' }}>
          <div className="flex flex-row justify-content-around align-items-center gap-2">
            <i
              className="pi pi-clock"
              style={{ fontSize: '2rem', color: 'var(--blue-700)' }}
            />
            <div
              className="flex flex-column align-items-center"
              style={{ flex: '1' }}
            >
              <div className="font-semibold text-base my-3 text-blue-800 text-center">
                {props.message.summary}
              </div>
              <ProgressBar
                mode="indeterminate"
                style={{ width: '90%', height: '6px', marginTop: '0.5rem' }}
              />
            </div>
          </div>
        </div>
      ),
    });
  }

  clear() {
    this.toastRef.current.clear();
  }
}
