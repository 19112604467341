import _ from 'lodash';
import axios from 'axios';

export const BaseService = (function () {
  const instances = {
    association: null,
    baseCheckPrice: null,
    baseInsurancePriceService: null,
    customerCompanyService: null,
    customer: null,
    enterprise: null,
    performanceCheckPayment: null,
    performanceCheckReceivable: null,
    shop: null,
    user: null,
    claim: null,
  };
  const BASE_URI = '';

  function init(baseUrl) {
    return {
      exportExcel: async function (params) {
        const PREFIX = '/apis';
        const [, API_URL] = _.split(baseUrl, PREFIX);
        const excelUrl = PREFIX + '/excel' + API_URL;
        const queryString = Object.entries(params)
          .map((e) => e.join('='))
          .join('&');

        //TODO: 응답 받아오는 동안 sticky toast 띄우기
        try {
          window.cerp.toast.showStickyToast('엑셀 파일을 전송받고 있습니다.');
          const response = await axios.get(excelUrl + `?${queryString}`, {
            responseType: 'blob',
          });

          const contentDisposition = response.headers['content-disposition'];
          const [, fileNameData] = _.split(contentDisposition, ';');
          const [, fileName] = _.split(fileNameData, '=');

          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers['content-type'],
            })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute(
            'download',
            decodeURIComponent(_.replace(fileName, /"/g, ''))
          );
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          window.cerp.toast.clear();
          window.cerp.toast.success('엑셀 파일 다운로드에 성공했습니다.');
        } catch (error) {
          window.cerp.toast.clear();
          window.cerp.dialog.error(
            '엑셀 다운로드에 실패했습니다.',
            `[${error?.code}] ${error?.message}`
          );
          // window.cerp.toast.error(
          //   '엑셀 다운로드에 실패했습니다.',
          //   `[${error?.code}] ${error?.message}`
          // );
        }
      },
    };
  }

  return {
    getInstance: function (baseUrl, type) {
      if (!instances[type]) instances[type] = init(baseUrl);
      return instances[type];
    },
    // getInstance: function (baseUrl) {
    //   init(baseUrl);
    // }
  };
})();
