import AxiosUtil from '../utils/axiosUtil';
import { BaseService } from './BaseService';

export const UserService = (function () {
  let instance;
  const BASE_URI = '/apis/users';
  const baseService = BaseService.getInstance(BASE_URI, 'user');

  function init() {
    return {
      ...baseService,
      checkAvailable: async function (loginId) {
        return await AxiosUtil.Async.get(
          BASE_URI + '/check/available-id',
          `ulid=${loginId}`
        );
      },
      register: async function (data, file) {
        return await AxiosUtil.Async.multipart(BASE_URI, data, file);
      },
      update: async function (data, file) {
        return await AxiosUtil.Async.updateMultipart(BASE_URI, data, file);
      },
      list: async function (params) {
        const queryString = Object.entries(params)
          .map((e) => e.join('='))
          .join('&');
        return AxiosUtil.Async.get(BASE_URI, queryString);
      },
      getData: async function (userId) {
        return await AxiosUtil.Async.get(BASE_URI + `/${userId}`);
      },
      getDataExternal: async function (userId) {
        return await AxiosUtil.Async.get(BASE_URI + `/viewer/${userId}`);
      },
      delete: async function (userId) {
        return await AxiosUtil.Async.delete(BASE_URI + `/${userId}`);
      },
      changePassword: async function (data) {
        return await AxiosUtil.Async.post('/apis/change-password', data);
      },
      resetPassword: async function (data) {
        return await AxiosUtil.Async.post('/apis/reset-password', data);
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();
