import { ConfirmDialog } from 'primereact/confirmdialog';
import React from 'react';

export const BaseConfirmDialog = ({
  header = '확인',
  message,
  icon = 'pi pi-exclamation-triangle',
  acceptLabel,
  rejectLabel = '취소',
  visible,
  onAccept,
  onClose,
}) => {
  return (
    <ConfirmDialog
      visible={visible}
      header={header}
      message={message}
      icon={icon}
      acceptLabel={acceptLabel}
      rejectLabel={rejectLabel}
      accept={onAccept}
      reject={onClose}
      onHide={onClose}
    />
  );
};
