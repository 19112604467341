import { useCallback, useEffect, useState } from 'react';

export const useFormValid = (observer, formField, isModified) => {
  const [isFormComplete, setIsFormComplete] = useState(false);
  const getRequiredObserver = useCallback(
    (formField) => {
      const required = [];

      formField.forEach((item) => {
        if (item.required) {
          if (isModified && item.code.includes('password')) {
            return;
          }
          required.push(item.code);
        }
      });
      return required.map((item) => observer[item]);
    },
    [isModified, observer]
  );

  useEffect(() => {
    const checkAllInputFilled = (values) => {
      // watch로 form 내부의 모든 input에 대한 값을 가져와서, 값만 배열로 만든다
      //! modified일 때 password 관련값은 예외
      const filledData = values.filter((val) => val !== '');
      return values.length === filledData.length && values.length;
    };
    // 모두 빈 값이 아닌 값일 때 isActive = true 로 업데이트한다
    if (checkAllInputFilled(getRequiredObserver(formField))) {
      setIsFormComplete(true);
    } else {
      setIsFormComplete(false);
    }
  }, [formField, getRequiredObserver]);

  return {
    isFormComplete,
  };
};
