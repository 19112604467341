import { FormatUtil } from './formatUtil';
import { ServiceProvider } from '../services';

const registerService = {
  association: ServiceProvider.association,
  enterprise: ServiceProvider.enterprise,
  shop: ServiceProvider.shop,
  user: ServiceProvider.user,
};

const FormUtil = {
  Async: {
    async onSubmit(submitData, isModified, field, fieldName, reset) {
      const { formData, files } = submitData;
      const formatForm = FormatUtil.Convert.exceptHyphen(formData);

      try {
        const { data } = isModified
          ? await registerService[field].update(formatForm, files)
          : await registerService[field].register(formatForm, files);
        // data 결과값은 구조 분해 하여 res 라는 alias로 받아오는 방법
        // const { [field]: res, masterUser } = data;

        const res = field === 'user' ? data : data[field];

        // association, enterprise, shop -> data: { [field]: data }
        // user -> data: data

        if (isModified) {
          window.cerp.toast.success(
            `${fieldName} 정보 수정 완료`,
            `[${res[`${field}Name`]}] ${fieldName} 정보를 수정했습니다.`
          );
        } else {
          window.cerp.toast.success(
            `신규 ${fieldName} 등록 완료`,
            `[${res[`${field}Name`]}] ${fieldName}을(를) 등록했습니다.`
          );
          reset(true);
        }
      } catch (error) {
        window.cerp.dialog.error(
          isModified
            ? `${fieldName} 정보 수정 실패`
            : `신규 ${fieldName} 등록 실패`,
          `[${error?.code}] ${error?.message}`
        );
      }
    },
    async onSubmitModified(origin, modified) {
      //* modified fields key 값을 순회하면서 default fields의 값과 비교,
      // 다르면 put 요청으로 올릴 데이터에 업데이트
      // 같으면 pass
      const modifiedData = {};

      for (let field in modified) {
        if (
          (origin[field] && modified[field] === origin[field]) ||
          !origin[field]
        ) {
          modifiedData[field] = modified[field];
        }
      }

      return modifiedData;
    },
  },
  getModifiedData: (defaultFields, modifiedFields) => {
    //* modified fields key 값을 순회하면서 default fields의 값과 비교,
    // 다르면 put 요청으로 올릴 데이터에 업데이트
    // 같으면 pass
    const modifiedData = {};

    for (let field in modifiedFields) {
      if (
        (defaultFields[field] &&
          modifiedFields[field] === defaultFields[field]) ||
        !defaultFields[field]
      ) {
        modifiedData[field] = modifiedFields[field];
      }
    }

    return modifiedData;
  },
};

export default FormUtil;
