import axios from 'axios';

export const EventService = (function () {
  let instance;
  // const BASE_URI = 'base service uri'

  function init() {
    return {
      getEvents: async function () {
        const {
          data: { data },
        } = await axios.get('assets/demo/data/events.json');
        return data;
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();
