export const KakaoService = (function () {
  let instance;
  const BASE_URI = 'https://dapi.kakao.com/v2/local/search/address';

  function init() {
    return {
      getAddressInfo: async function (params) {
        const queryString = Object.entries(params).map((e) => e.join('='));
        return await fetch(`${BASE_URI}?${queryString}`, {
          method: 'GET',
          headers: {
            Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_API_APP_KEY}`,
          },
        })
          .then((res) => res.json())
          .then((data) => data);
      },
      getCodeAddressInfo: async (x, y) => {
        return await fetch(
          `https://dapi.kakao.com/v2/local/geo/coord2regioncode?x=${x}&y=${y}`,
          {
            method: 'GET',
            headers: {
              Authorization: `KakaoAK ${process.env.REACT_APP_KAKAO_API_APP_KEY}`,
            },
          }
        )
          .then((res) => res.json())
          .then((data) => data);
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();
