import * as Button from '../Atoms/Button';
import * as filesize from 'filesize';

import { Controller, useForm, useWatch } from 'react-hook-form';
import React, { useEffect, useMemo, useState } from 'react';

import { Badge } from 'primereact/badge';
import { CommonUtil } from '../../utils/commonUtil';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Editor } from 'primereact/editor';
import FileState from '../../enums/FileState';
import { Image } from 'primereact/image';
import { InputText } from '../Atoms/Titled';
import { MyInfoUtil } from '../../utils/myInfoUtil';
import { Panel } from 'primereact/panel';
import { Password } from 'primereact/password';
import { ServiceProvider } from '../../services';
import _ from 'lodash';
import dayjs from 'dayjs';
import { useDropzone } from 'react-dropzone';
import { useHistory } from 'react-router-dom';

const qnaService = ServiceProvider.qna;

const defaultValues = {
  title: '',
  content: '',
  password: '',
};

const QNAEditor = ({ postType, postId, userInfo, onHide = null }) => {
  const isModified = postId > 0;
  const {
    myAssociationId,
    myEnterpriseId,
    myShopId,
    myUserId,
    myRoleCode,
    myUserPosition,
  } = useMemo(() => MyInfoUtil.unpack(userInfo), [userInfo]);
  const history = useHistory();

  const [showConfirmPopup, setShowConfirmPopup] = useState({
    cancle: false,
    submit: false,
    update: false,
  });

  const [fileItems, setFileItems] = useState([]);
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    multiple: true,
    maxSize: 8 * 1024 * 1024,
    accept: {
      'image/*': [],
      'application/pdf': [],
      'application/vnd.ms-powerpoint': [],
      'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        [],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
        '.xls',
        '.xlsx',
      ],
      'application/vnd.ms-excel': ['.xls', '.xlsx'],
      'application/hwp': ['.hwp'],
    },
    onDrop: (acceptedFiles) => {
      // acceptedFiles.forEach((file) => {
      //   Object.assign(file, {
      //     preview: URL.createObjectURL(file),
      //   });
      // });

      const update = acceptedFiles.map((file) => {
        const fileObj = Object.assign(file, {
          preview: URL.createObjectURL(file),
        });

        return {
          file: fileObj,
          state: FileState.Inserted,
        };
      });

      // setFileItems((ps) => [...ps, ...acceptedFiles]);
      setFileItems((ps) => [...ps, ...update]);
    },
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({ defaultValues });

  const currentValues = useWatch({ control });

  const editorHeader = (() => {
    return (
      <div id="toolbar">
        <span className="ql-formats">
          <button className="ql-bold" aria-label="Bold"></button>
          <button className="ql-italic" aria-label="Italic"></button>
          <button className="ql-underline" aria-label="Underline"></button>
        </span>
        <select className="ql-size" defaultValue="medium">
          <option value="small">Small</option>
          <option value="medium">Medium</option>
          <option value="large">Large</option>
          <option value="huge">Huge</option>
        </select>
        <span className="ql-formats">
          <select className="ql-align" />
        </span>
        <span className="ql-formats">
          <select className="ql-color" />
          <select className="ql-background" />
        </span>
        {/* <span className="ql-formats">
          <button className="ql-image" aria-label="Insert Image"></button>
        </span> */}
        <span className="ql-formats">
          <button className="ql-clean" />
        </span>
      </div>
    );
  })();

  async function getData(postId) {
    try {
      const {
        data: { qnaData, qnaFilesData },
      } = await qnaService.getData(postId);

      reset({
        ...qnaData,
      });
      setFileItems([...qnaFilesData]);
    } catch (error) {
      window.cerp.dialog.error(
        '게시글 조회 실패',
        `[${error?.code}]${error?.message}`
      );
    }
  }

  async function onSubmit(formData) {
    const { fileData, files } = CommonUtil.File.convertForServer(fileItems);

    console.log(fileData, files);

    const submitData = {
      ...formData,
      authority: _.get(formData, 'config.access.authority'),
      title: _.get(formData, 'title'),
      content: _.get(formData, 'content'),
      isPopup: _.get(formData, 'config.isPopup'),
      isImportant: _.get(formData, 'config.isImportant'),
      associationId: myAssociationId,
      enterpriseType: _.get(formData, 'config.access.enterpriseType'),
      popupStart: dayjs(_.get(formData, 'popupStart')).format(
        'YYYY-MM-DD HH:mm:ss'
      ),
      popupEnd: dayjs(_.get(formData, 'popupEnd')).format(
        'YYYY-MM-DD HH:mm:ss'
      ),
      postType,
      fileData,
    };

    if (isModified) {
      // 수정
      // file state 업로드 필요

      try {
        const {
          data: { qnaData, qnaFilesData },
        } = await qnaService.updateData(submitData, files);

        reset({
          ...qnaData,
        });
        setFileItems([...qnaFilesData]);

        window.cerp.toast.success(
          `게시글 수정 완료`,
          // `${_.get(message, `${postType}`)} 수정이 완료 되었습니다.`
          `QnA 수정이 완료 되었습니다.`
        );
      } catch (error) {
        console.log(error);
        window.cerp.dialog.error(
          '게시글 수정 실패',
          `[${error?.code}]${error?.message}`
        );
      }
    } else {
      try {
        const {
          data: { qnaData, qnaFilesData },
        } = await qnaService.registerData(submitData, files);

        const { seq } = qnaData;

        reset({
          ...qnaData,
        });
        setFileItems([...qnaFilesData]);

        // 에디터 종료하고 리스트 조회 다시 하기
        onHide();

        window.cerp.toast.success(
          '게시글 등록 완료',
          // `${_.get(message, `${postType}`)} 등록이 완료 되었습니다.`
          `QnA 등록이 완료 되었습니다.`
        );
      } catch (error) {
        window.cerp.dialog.error(
          '게시글 등록 실패',
          `[${error?.code}] ${error?.message}`
        );
      }
    }
  }

  useEffect(() => {
    (async () => {
      if (isModified) {
        await getData(postId);
      }
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId]);

  return (
    <form autoComplete="off">
      <div className="grid">
        <div className="col-12 sm:col-6 md:col-8 lg:col-8">
          <Controller
            control={control}
            name="title"
            render={({ field }) => (
              <InputText
                className="w-full"
                {...field}
                id={field.name}
                placeholder="글제목을 입력하세요."
              />
            )}
          />
        </div>
        <div className="col-12 sm:col-6 md:col-4 lg:col-4">
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <div className="flex align-items-center gap-2 font-bold">
                <label
                  htmlFor="qna-password"
                  style={{
                    wordBreak: 'keep-all',
                  }}
                >
                  비밀번호
                </label>
                <Password
                  id={field.name}
                  {...field}
                  feedback={false}
                  className="w-full"
                  inputClassName="w-full"
                  placeholder="비밀번호(숫자 4자리)"
                  onChange={({ target: { value } }) => {
                    const number = value.replace(/[^0-9]/g, '');
                    const length = number.length;
                    let result = '';

                    result = number;

                    if (length > 4) {
                      result = number.slice(0, 4);
                    }

                    field.onChange(result);
                  }}
                />
              </div>
            )}
          />
        </div>
        <div className="col-12">
          <Controller
            control={control}
            name="content"
            render={({ field }) => (
              <Editor
                // className="mb-6"
                id={field.name}
                value={field.value}
                onTextChange={(e) => field.onChange(e.htmlValue)}
                headerTemplate={editorHeader}
                style={{
                  height: '40rem',
                }}
              />
            )}
          />
        </div>
        <div className="col-12">
          <Panel header="파일 업로드" className="shadow-2 mb-4">
            <div className="file_upload_wrapper">
              <div
                {...getRootProps({
                  className:
                    'p-2 lg:p-3 bg-gray-50 border-round border-1 border-400 border-dashed cursor-pointer',
                  style: { wordBreak: 'keep-all' },
                })}
              >
                <input {...getInputProps()} />
                <div className="flex flex-auto flex-column align-items-center justify-content-center gap-2">
                  <i
                    className="pi pi-arrow-circle-down"
                    style={{ fontSize: '1.5rem' }}
                  />
                  이 영역에 점검 파일을 끌어다 놓거나, 영역을 클릭하여 파일을
                  선택하세요.
                </div>
              </div>

              <div className="grid gap-2 p-2">
                {fileItems.length > 0 &&
                  fileItems.map((item, idx) => {
                    if (_.get(item, 'state') !== FileState.Deleted) {
                      return (
                        <div
                          className="col-2 sm:col-3 md:col-2 lg:col-1"
                          key={`file_container_${idx}`}
                        >
                          <div className="flex flex-column justify-content-center">
                            <div
                              className="max-w-max"
                              style={{
                                position: 'relative',
                              }}
                            >
                              <Image
                                src={
                                  _.has(item, 'filePath')
                                    ? process.env.REACT_APP_S3_BASE_URL +
                                      _.get(item, 'filePath')
                                    : _.get(item, 'file.preview')
                                }
                                alt={
                                  _.has(item, 'filePath')
                                    ? _.get(item, 'originalFileName')
                                    : _.get(item, 'file.name')
                                }
                                preview
                                imageClassName="border-round border-1 border-200"
                                imageStyle={{
                                  width: 44,
                                  height: 44,
                                }}
                              />
                              <Button.Default
                                role="button"
                                className="border-circle border-red-600 bg-red-600	"
                                icon="pi pi-times"
                                severity="danger"
                                aria-label="delete"
                                style={{
                                  width: '1rem',
                                  height: '1rem',
                                  position: 'absolute',
                                  top: '0',
                                  right: '-5%',
                                }}
                                onClick={(e) => {
                                  e.preventDefault();

                                  const clonedFiles = [...fileItems];
                                  const clonedFile = clonedFiles[idx];

                                  if (_.has(clonedFile, 'seq')) {
                                    clonedFiles[idx] = {
                                      ...clonedFile,
                                      state: FileState.Deleted,
                                    };
                                  } else {
                                    clonedFiles.splice(idx, 1);
                                  }

                                  setFileItems(clonedFiles);
                                }}
                              />
                            </div>

                            <div className="flex flex-column align-items-start justify-content-center gap-1">
                              <div
                                className="max-w-6rem"
                                style={{
                                  wordBreak: 'break-all',
                                }}
                              >
                                {_.get(item, 'file.name') ||
                                  _.get(item, 'originalFileName')}
                              </div>
                              <Badge
                                value={`${filesize(
                                  _.get(item, 'fileSize') ||
                                    _.get(item, 'file.size'),
                                  {
                                    round: 1,
                                    standard: 'jedec',
                                  }
                                )}`}
                                severity="info"
                              />
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
              </div>
            </div>
          </Panel>
        </div>
      </div>
      <div className="button_wrapper flex flex-auto justify-content-center w-full gap-1">
        <Button.Default
          label="뒤로 가기"
          className="p-button p-button-outlined"
          onClick={() => setShowConfirmPopup((ps) => ({ ...ps, cancle: true }))}
        />
        <ConfirmDialog
          visible={showConfirmPopup['cancle']}
          header="확인"
          message={
            <>
              <p>작성 중인 내용이 모두 삭제됩니다.</p>
              <p>작성을 취소하시겠습니까?</p>
            </>
          }
          icon="pi pi-exclamation-triangle"
          acceptLabel="네"
          rejectLabel="아니오"
          accept={() => setShowConfirmPopup((ps) => ({ ...ps, cancle: false }))}
          reject={() => setShowConfirmPopup((ps) => ({ ...ps, cancle: false }))}
          onHide={() => setShowConfirmPopup((ps) => ({ ...ps, cancle: false }))}
        />

        <Button.Default
          type="button"
          label={isModified ? '수정' : '작성완료'}
          icon="pi pi-check"
          onClick={() => {
            if (isModified) {
              // 수정
              setShowConfirmPopup((ps) => ({ ...ps, update: true }));
              return;
            }
            setShowConfirmPopup((ps) => ({ ...ps, submit: true }));
          }}
        />
        <ConfirmDialog
          visible={showConfirmPopup['submit']}
          header="확인"
          message={'작성을 완료하시겠습니까?'}
          icon="pi pi-exclamation-triangle"
          acceptLabel="네"
          rejectLabel="아니오"
          accept={() => handleSubmit((data) => onSubmit(data))()}
          reject={() => setShowConfirmPopup((ps) => ({ ...ps, submit: false }))}
          onHide={() => setShowConfirmPopup((ps) => ({ ...ps, submit: false }))}
        />

        <ConfirmDialog
          visible={showConfirmPopup['update']}
          header="확인"
          message={'수정을 완료하시겠습니까?'}
          icon="pi pi-exclamation-triangle"
          acceptLabel="네"
          rejectLabel="아니오"
          accept={() => handleSubmit((data) => onSubmit(data))()}
          reject={() => setShowConfirmPopup((ps) => ({ ...ps, update: false }))}
          onHide={() => setShowConfirmPopup((ps) => ({ ...ps, update: false }))}
        />
      </div>
    </form>
  );
};

export default QNAEditor;
