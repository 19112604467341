import { BaseConfirmDialog } from './BaseConfirmDialog';
import React from 'react';

export const DeleteConfirmDialog = ({
  title,
  childDataName,
  visible,
  onDelete,
  onClose,
}) => {
  return (
    <BaseConfirmDialog
      visible={visible}
      message={
        <span className="font-bold">
          {`${title}을 삭제하시겠습니까?`}
          <br />
          {childDataName && (
            <span className="text-red-700">
              {`※ 해당 ${title} 아래의 ${childDataName}도 함께 삭제됩니다!`}
            </span>
          )}
        </span>
      }
      acceptLabel={'네'}
      rejectLabel={'아니오'}
      onAccept={onDelete}
      onClose={onClose}
    />
  );
};
