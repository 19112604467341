import * as Button from './../../components/Atoms/Button';
import * as filesize from 'filesize';

import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Badge } from 'primereact/badge';
import { ConfirmDialog } from 'primereact/confirmdialog';
import DOMPurify from 'dompurify';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import FileState from '../../enums/FileState';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressBar } from 'primereact/progressbar';
import QNAEditor from '../../components/Editor/QNAEditor';
import { ServiceProvider } from '../../services';
import UserRoleType from '../../enums/UserRoleType';
import _ from 'lodash';
import { myInfoSelector } from '../../recoil/selectors';
import { useRecoilValueLoadable } from 'recoil';

const qnaService = ServiceProvider.qna;

const QNA = () => {
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const history = useHistory();
  const params = useParams();
  const { service, postId } = params;

  const [showConfirmPopup, setShowConfirmPopup] = useState({
    delete: false,
  });

  const [showCommentConfirmPopup, setShowCommentConfirmPopup] = useState({
    submit: false,
    delete: false,
    update: false,
  });

  const [isEditable, setIsEditable] = useState(false);

  const [currentPostId, setCurrentPostId] = useState(null);

  const [data, setData] = useState({});
  const [fileData, setFileData] = useState([]);
  const [comment, setComment] = useState('');

  function checkRegistAuth(userInfo) {
    const { roleCode } = userInfo;
    // 어드민, 협회
    const ACCESS_PERMISSION = _.filter(UserRoleType, function (r) {
      return (
        r.value === 'CERP_ADM' ||
        r.value === 'A_MST' ||
        r.value === 'A_ADM' ||
        r.value === 'A_OFF'
      );
    });

    return _.findIndex(ACCESS_PERMISSION, { value: roleCode }) >= 0;
  }

  async function getData(postId) {
    try {
      const {
        data: { qnaData, qnaReplyData, qnaFilesData },
      } = await qnaService.getData(postId);

      setData({ data: qnaData, comment: qnaReplyData });
      setComment(_.get(qnaReplyData, 'content') || '');
      setFileData(
        _.map(qnaFilesData, (file) => ({
          ...file,
          state: FileState.Loaded,
        })) || []
      );
    } catch (error) {
      window.cerp.dialog.error(
        '게시글 조회 실패',
        `[${error?.code}]${error?.message}`
      );
    }
  }

  async function deleteQnA(id) {
    try {
      const { data } = await qnaService.deleteData(id);

      if (data) {
        window.cerp.toast.success(
          '게시글 삭제 완료',
          '선택하신 QnA 게시글이 삭제되었습니다. '
        );

        history.replace(`/${service}/board/qna/list`);
      }
    } catch (error) {
      window.cerp.dialog.error(
        '게시글 삭제 실패',
        `[${error?.code}]${error?.message}`
      );
    }
  }

  async function submitComment(postId, content) {
    try {
      const { data } = await qnaService.registerComment({
        seq: null,
        replyTo: postId,
        content,
      });

      if (data) {
        window.cerp.toast.success(
          '답변 등록 완료',
          `질문에 대한 답변이 등록되었습니다.`
        );
      }
    } catch (error) {
      window.cerp.dialog.error(
        '답변 등록 실패',
        `[${error?.code}]${error?.message}`
      );
    }
  }

  async function deleteComment(commentId, postId) {
    try {
      const { data } = await qnaService.deleteComment(commentId);

      if (data) {
        window.cerp.toast.success(
          '답변 삭제 완료',
          `질문에 대한 답변이 삭제되었습니다.`
        );

        await getData(postId);
      }
    } catch (error) {
      window.cerp.dialog.error(
        '답변 삭제 실패',
        `[${error?.code}]${error?.message}`
      );
    }
  }

  async function updateComment(commentId, postId, content) {
    try {
      const { data } = await qnaService.updateComment({
        seq: commentId,
        replyTo: postId,
        content,
      });

      if (data) {
        setIsEditable(false);
        window.cerp.toast.success(
          '답변 수정 완료',
          `게시글의 답변을 수정했습니다.`
        );
      }
    } catch (error) {
      console.log(error);
      window.cerp.dialog.error(
        '답변 수정 실패',
        `[${error?.code}]${error?.message}`
      );
    }
  }

  function downloadFile(url) {
    window.open(process.env.REACT_APP_S3_BASE_URL + url);
  }

  useEffect(() => {
    (async () => {
      if (postId) {
        await getData(postId);
      }
    })();
  }, [postId]);

  return (
    <div className="grid">
      <div className="col-12">
        {(() => {
          switch (myInfoLoadable.state) {
            case 'hasValue':
              return (
                <>
                  <div className="card flex justify-content-end gap-1">
                    <Button.Delete
                      onDelete={() =>
                        setShowConfirmPopup((ps) => ({
                          ...ps,
                          delete: { [`${postId}`]: true },
                        }))
                      }
                    />
                    <ConfirmDialog
                      visible={showConfirmPopup.delete[postId]}
                      header="확인"
                      message={<p>QnA 게시글을 삭제하시겠습니까?</p>}
                      icon="pi pi-exclamation-triangle"
                      acceptLabel="네"
                      rejectLabel="아니오"
                      accept={async () => await deleteQnA(postId)}
                      reject={() =>
                        setShowConfirmPopup((ps) => ({
                          ...ps,
                          delete: false,
                        }))
                      }
                      onHide={() =>
                        setShowConfirmPopup((ps) => ({
                          ...ps,
                          delete: false,
                        }))
                      }
                    />
                    <Button.Default
                      label="수정"
                      icon="pi pi-pencil"
                      onClick={() => setCurrentPostId(postId)}
                    />
                  </div>

                  <div className="card">
                    <div className="title_wrapper flex">
                      <div className="mr-4 text-center w-5rem font-bold">
                        제목
                      </div>
                      <span>{_.get(data, 'data.title')}</span>
                    </div>
                    <Divider />
                    <div className="writer flex">
                      <div className="mr-4 text-center w-5rem font-bold">
                        작성자
                      </div>
                      <span>{_.get(data, 'data.regName')}</span>
                    </div>
                    <Divider />
                    <div className="files_wrapper flex">
                      <div className="mr-4 text-center w-5rem font-bold">
                        첨부파일
                      </div>
                      {fileData && fileData.length > 0 && (
                        <div className="flex flex-column">
                          {fileData.map((file, idx) => {
                            if (_.get(file, 'state') !== FileState.Deleted) {
                              const { originalFileName, fileSize, filePath } =
                                file;

                              return (
                                <div
                                  className="flex align-items-center gap-1 mb-2"
                                  key={`files_${idx}`}
                                  onClick={() => downloadFile(filePath)}
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                >
                                  <span>{originalFileName}</span>
                                  <Badge
                                    value={`${filesize(fileSize, {
                                      round: 1,
                                      standard: 'jedec',
                                    })}`}
                                    severity="info"
                                  />
                                  <i className="pi pi-download text-xl"></i>
                                </div>
                              );
                            }
                            return null;
                          })}
                        </div>
                      )}
                    </div>
                    <Divider />
                    <div className="content_wrapper">
                      {/* <pre>{parseStringToDom(_.get(data, 'content'))}</pre> */}
                      <div
                        className="p-5"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            _.get(data, 'data.content')
                          ),
                        }}
                      />
                    </div>
                    <Divider />
                    <div className="comments_wrapper flex surface-100	p-3 border-round-sm	">
                      <div className="mr-4 text-center vertical-center w-5rem font-bold ">
                        답변
                      </div>
                      {/* 답변이 없을땐 뭐라고 띄우지? */}
                      {_.get(data, 'comment') &&
                      _.get(data, 'comment.seq') !== null ? (
                        isEditable ? (
                          <div className="write_area flex gap-2 w-full">
                            <InputTextarea
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                              rows={5}
                              autoResize
                              className="w-full"
                            />
                            <Button.Default
                              label="수정완료"
                              className="p-button-outlined p-2 min-w-max bg-white"
                              style={{
                                wordBreak: 'keep-all',
                              }}
                              icon="pi pi-check"
                              onClick={(e) =>
                                setShowCommentConfirmPopup((ps) => ({
                                  ...ps,
                                  update: true,
                                }))
                              }
                            />

                            <ConfirmDialog
                              visible={showCommentConfirmPopup['update']}
                              header="확인"
                              message={'답변을 수정하시겠습니까?'}
                              icon="pi pi-exclamation-triangle"
                              acceptLabel="네"
                              rejectLabel="아니오"
                              accept={async () => {
                                await updateComment(
                                  _.get(data, 'comment.seq'),
                                  postId,
                                  comment
                                );
                                await getData(postId);
                              }}
                              reject={() =>
                                setShowCommentConfirmPopup((ps) => ({
                                  ...ps,
                                  update: false,
                                }))
                              }
                              onHide={() =>
                                setShowCommentConfirmPopup((ps) => ({
                                  ...ps,
                                  update: false,
                                }))
                              }
                            />
                          </div>
                        ) : (
                          <div className="comment_view flex flex-column w-full px-2">
                            <div className="write_info flex gap-2 mb-3">
                              <span className="font-bold">
                                {_.get(data, 'comment.regName')}
                              </span>
                              <span>{_.get(data, 'comment.regDate')}</span>
                              {checkRegistAuth(myInfoLoadable.contents) && (
                                <div className="buttons">
                                  <Button.Default
                                    icon="pi pi-pencil"
                                    className="p-button-info p-button-rounded p-button-text p-0 h-auto"
                                    onClick={(e) => setIsEditable(true)}
                                  />

                                  <Button.Default
                                    icon="pi pi-trash"
                                    className="p-button-danger p-button-rounded p-button-text p-0 h-auto"
                                    onClick={(e) =>
                                      setShowCommentConfirmPopup((ps) => ({
                                        ...ps,
                                        delete: true,
                                      }))
                                    }
                                  />

                                  <ConfirmDialog
                                    visible={showCommentConfirmPopup['delete']}
                                    header="확인"
                                    message={'답변을 삭제하시겠습니까?'}
                                    icon="pi pi-exclamation-triangle"
                                    acceptLabel="네"
                                    rejectLabel="아니오"
                                    accept={async () =>
                                      await deleteComment(
                                        _.get(data, 'comment.seq'),
                                        postId
                                      )
                                    }
                                    reject={() =>
                                      setShowCommentConfirmPopup((ps) => ({
                                        ...ps,
                                        delete: false,
                                      }))
                                    }
                                    onHide={() =>
                                      setShowCommentConfirmPopup((ps) => ({
                                        ...ps,
                                        delete: false,
                                      }))
                                    }
                                  />
                                </div>
                              )}
                            </div>
                            <div className="comment">
                              <pre>{_.get(data, 'comment.content')}</pre>
                            </div>
                          </div>
                        )
                      ) : (
                        <span>답변이 등록되지 않았습니다.</span>
                      )}
                    </div>
                    {!_.get(data, 'comment.seq') &&
                      !isEditable &&
                      checkRegistAuth(myInfoLoadable.contents) && (
                        <>
                          <Divider />
                          <div className="write_area flex gap-2">
                            <InputTextarea
                              value={comment}
                              onChange={(e) => setComment(e.target.value)}
                              rows={5}
                              autoResize
                              className="w-full"
                              placeholder="답변을 입력해주세요."
                            />
                            <Button.Default
                              label="작성완료"
                              // className="p-button-outlined flex flex-column justify-content-center align-items-center"
                              className="p-button-outlined p-2 min-w-max"
                              style={{
                                wordBreak: 'keep-all',
                              }}
                              icon="pi pi-check"
                              onClick={(e) =>
                                setShowCommentConfirmPopup((ps) => ({
                                  ...ps,
                                  submit: true,
                                }))
                              }
                            />

                            {/* comment dialog */}
                            <ConfirmDialog
                              visible={showCommentConfirmPopup['submit']}
                              header="확인"
                              message={'답변 작성을 완료하시겠습니까?'}
                              icon="pi pi-exclamation-triangle"
                              acceptLabel="네"
                              rejectLabel="아니오"
                              accept={async () => {
                                await submitComment(postId, comment);
                                await getData(postId);
                              }}
                              reject={() =>
                                setShowCommentConfirmPopup((ps) => ({
                                  ...ps,
                                  submit: false,
                                }))
                              }
                              onHide={() =>
                                setShowCommentConfirmPopup((ps) => ({
                                  ...ps,
                                  submit: false,
                                }))
                              }
                            />
                          </div>
                        </>
                      )}
                  </div>
                  <div className="button_wrapper flex justify-content-end w-full mb-4">
                    <Button.Default
                      icon="pi pi-list"
                      label="목록 보기"
                      onClick={() =>
                        history.replace(`/${service}/board/qna/list`)
                      }
                    />
                  </div>

                  {currentPostId !== null && (
                    <Dialog
                      visible
                      blockScroll
                      header={
                        currentPostId === 0 ? 'QnA 신규 등록' : 'QnA 수정'
                      }
                      style={{
                        width: '80vw',
                        height: '80vh',
                      }}
                      onHide={async () => {
                        await getData(postId);
                        setCurrentPostId(null);
                      }}
                    >
                      <QNAEditor
                        postId={currentPostId}
                        userInfo={myInfoLoadable.contents}
                      />
                    </Dialog>
                  )}
                </>
              );
            case 'loading':
              return <ProgressBar mode="indeterminate" />;
            case 'hasError':
              return null;
            default:
          }
        })()}
      </div>
    </div>
  );
};

export default QNA;
