import AxiosUtil from '../utils/axiosUtil';

export const DealerService = (function () {
  let instance;
  const BASE_URI = 'base service uri';

  function init() {
    return {
      test: async function () {
        return BASE_URI;
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();
