import AxiosUtil from '../utils/axiosUtil';

export const MolitService = (function () {
  let instance;
  // const BASE_URI = 'base service uri';

  function init() {
    return {
      vehicleInformation: async function (licensePlateNumbers) {
        const queryString = `lpns=${Array.from(licensePlateNumbers).join(',')}`;
        return await AxiosUtil.Async.get(
          '/apis/molit/vehicle/information',
          queryString
        );
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();
