import { Controller, useForm, useWatch } from 'react-hook-form';
import React, { useState } from 'react';

import { Button } from 'primereact/button';
import CheckState from '../../enums/CheckState';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { ServiceProvider } from '../../services';
import { YN } from '../../constants/Constants';
import _ from 'lodash';
import dayjs from 'dayjs';
import performanceCheckUtil from '../../utils/performanceCheckUtil';

const startDate = dayjs('20240401');
const endDate = dayjs('20240401');

const defaultValues = {
  password: '',
};

const DEFAULT_SEARCH_CONDITIONS = {
  associations: [],
  enterprises: [],
  shops: [],
  startDate: startDate.toDate(),
  endDate: endDate.toDate(),
  statementNumber: '',
  carSearchKeyword: '',
  customerSearchBy: '1',
  customerSearchKeyword: '',
  checkType: '',
  payYN: '',
  delYN: YN.NO,
};

const DEFAULT_LAZY_PARAMS = {
  first: 0,
  rows: 3000,
  page: 0,
  sortField: null,
  sortOrder: null,
  filters: {
    name: { value: '', matchMode: 'contains' },
    'country.name': { value: '', matchMode: 'contains' },
    company: { value: '', matchMode: 'contains' },
    'representative.name': { value: '', matchMode: 'contains' },
  },
};

const performanceCheckService = ServiceProvider.performanceCheck;
const priceService = ServiceProvider.price;

const UpdatePayments = () => {
  const [authorization, setAuthorization] = useState(false);
  const [searchConditions, setSearchConditions] = useState({
    ...DEFAULT_SEARCH_CONDITIONS,
  });
  const [datas, setDatas] = useState({});
  const [currentIdx, setCurrentIdx] = useState(0);
  const [currentAmounts, setCurrentAmounts] = useState({
    dataId: 0,
    checkPrice: 0,
    insurancePrice: 0,
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
  } = useForm({ defaultValues, reValidateMode: 'onSubmit' });
  const currentValues = useWatch({ control });

  const checkAuthorization = () => {
    if (_.get(currentValues, 'password') === 'cerp@admin') {
      setAuthorization(true);
    } else {
      console.log('비밀번호 에러');
    }
  };

  function getCarTypeCode({
    madeAt = _.get(currentValues, 'vehicleInformation.madeAt'),
    assortType = _.get(currentValues, 'vehicleInformation.VHCTY_ASORT_CODE'),
    detailModel = _.get(currentValues, 'vehicleInformation.detailedModelType'),
  }) {
    return `${madeAt}${assortType}${detailModel}`;
  }

  const getCheckList = async () => {
    const params = {
      ...searchConditions,
      startDate: dayjs(_.get(searchConditions, 'startDate')).format('YYYYMMDD'),
      endDate: dayjs(_.get(searchConditions, 'endDate')).format('YYYYMMDD'),
    };

    // params에서 조회하는 startDate, endDate는 어떤 데이터를 보고 필터링 해오나

    try {
      const {
        data: { total, list },
      } = await performanceCheckService.list({
        ...params,
        page: _.get(DEFAULT_LAZY_PARAMS, 'page') + 1,
        size: _.get(DEFAULT_LAZY_PARAMS, 'rows'),
      });

      const checkDatas = {};

      const filteredList = list.filter((data) => {
        // 5번 인덱스가 '5'이면 신전산에서 등록된 데이터
        const { statementNumber, checkState } = data;

        return (
          statementNumber.slice(5, 6) === '5' &&
          checkState === CheckState.Updated
        );
      });

      for (let data of filteredList) {
        const { statementNumber, dataId } = data;

        if (!checkDatas[statementNumber]) {
          checkDatas[statementNumber] = [];
        }

        checkDatas[statementNumber].unshift({ dataId });
      }
      // setDatas(checkDatas);
      return checkDatas;
    } catch (error) {
      console.log('get list error', error);
    }
  };

  const getCheckData = async (dataId) => {
    const {
      data: {
        performanceCheckData: {
          statementNumber,
          association: associationRaw,
          enterprise: enterpriseRaw,
          shop: shopRaw,
          paymentInformation: paymentInformationRaw,
          vehicleInformation: vehicleInformationRaw,
          regTime,
          modTime,
        },
      },
    } = await performanceCheckService.getData(dataId);

    const result = {
      statementNumber,
      dataId,
      association: JSON.parse(associationRaw),
      enterprise: JSON.parse(enterpriseRaw),
      shop: JSON.parse(shopRaw),
      paymentInformation: JSON.parse(paymentInformationRaw),
      vehicleInformation: JSON.parse(vehicleInformationRaw),
      lastUpdateTime: modTime || regTime,
    };

    return result;
  };

  const getActualDataList = async () => {
    const datas = await getCheckList();
    const copy = { ...datas };
    const statementNumbers = Object.keys(datas);

    for (let statementNumber of statementNumbers) {
      const list = datas[statementNumber];
      const updateList = [];

      for (let target of list) {
        const { dataId } = target;
        const res = await getCheckData(dataId);

        updateList.push(res);
      }

      copy[statementNumber] = updateList;
    }

    setDatas(copy);
    return copy;
  };

  const getCheckPriceTable = async ({
    associationId,
    enterpriseId,
    shopId,
    checkPriceOf,
    checkPriceTableId,
  }) => {
    try {
      const { data: checkPriceTable, error } = await priceService.getCheckPrice(
        {
          associationId,
          enterpriseId,
          shopId,
          checkPriceOf,
          checkPriceTableId,
        }
      );
      return [checkPriceTable];
    } catch (error) {
      console.log('check table read error', error);
    }
  };

  const getInsurancePriceTables = async ({
    associationId = 0,
    enterpriseId = 0,
    shopId = 0,
    insurancePriceOf = null,
    insurancePriceTableId = null,
  }) => {
    const validatedInsurancePriceOf =
      insurancePriceOf === '' ? null : insurancePriceOf;
    try {
      const { data: insurancePriceTables, error } =
        await priceService.getInsurancePrices({
          associationId,
          enterpriseId,
          shopId,
          insurancePriceOf: validatedInsurancePriceOf,
          insurancePriceTableId,
        });
      const insurances = _.reduce(
        insurancePriceTables,
        (r, v) => {
          r.push({
            label: `[${_.get(v, 'insuranceCompany')}] ${_.get(
              v,
              'companyName'
            )}`,
            value: _.get(v, 'insuranceCompany'),
          });
          return r;
        },
        []
      );
      const insurance = _.get(insurances, 0) || {};

      if (error) {
        console.log(error);
      }
      return [
        insurancePriceTables,
        _.get(insurance, 'value'),
        insurance,
        insurances,
      ];
    } catch (error) {
      console.log(
        'insurance price of',
        insurancePriceOf,
        'insurancePriceTableId',
        insurancePriceTableId
      );
      console.log('insurance table read error', error);
    }
  };

  const getCurrentCheckPrice = (data) => {
    const {
      checkPriceTable,
      paymentInformation: {
        checkPriceItemId: selectedItemId,
        discountedCheckPrice,
      },
    } = data;
    const { checkPriceOf, checkPriceTableId, checkPriceItems } =
      checkPriceTable || {};

    if (checkPriceItems) {
      const item = _.find(checkPriceItems, { id: selectedItemId });

      const currentTotalCheckPrice = _.toNumber(_.get(item, 'price'));
      const currentCheckPriceVAT =
        Math.floor(currentTotalCheckPrice / 11 / 10) * 10;
      const currentCheckPrice = currentTotalCheckPrice - currentCheckPriceVAT;

      return {
        totalCheckPrice: currentTotalCheckPrice,
        checkPriceVAT: currentCheckPriceVAT,
        checkPrice: currentCheckPrice,
      };
    }
  };

  const getCurrentInsurancePrice = (data) => {
    const {
      paymentInformation,
      vehicleInformation: {
        TRVL_DSTNC: distance,
        madeAt,
        detailedModelType: detailModel,
        VHCTY_ASORT_CODE: assortType,
      },
      insurancePriceTables,
    } = data;

    const { ASSRNC_TY_SE_CODE } = paymentInformation;

    if (ASSRNC_TY_SE_CODE === '1') {
      // 자가 보증인 경우 보험료 금액을 0원으로 리셋
      return {
        totalInsurancePrice: 0,
        insurancePrice: 0,
        insurancePriceVAT: 0,
      };
    }

    const carTypeCode = getCarTypeCode({ madeAt, assortType, detailModel });
    const suffix = performanceCheckUtil.Distance.getInsuranceSuffix(distance);

    const insuranceCompany =
      _.get(currentValues, 'paymentInformation.ICNY_CODE') ||
      _.get(_.get(insurancePriceTables, '0'), 'insuranceCompany');
    const currentPriceTable = _.find(insurancePriceTables, {
      insuranceCompany,
    });

    if (currentPriceTable) {
      const { priceTableId, priceTable, insurancePriceOf } = currentPriceTable;

      const currentInsurancePrice =
        _.toNumber(_.get(priceTable, `${carTypeCode}_${suffix}`)) || 0;
      const currentInsurancePriceVAT =
        Math.floor((currentInsurancePrice * 0.1) / 10) * 10;
      const currentTotalInsurancePrice =
        currentInsurancePrice + currentInsurancePriceVAT;

      return {
        totalInsurancePrice: currentTotalInsurancePrice,
        insurancePrice: currentInsurancePrice,
        insurancePriceVAT: currentInsurancePriceVAT,
      };
    }
  };

  const calculatePrices = ({
    checkPrice,
    checkPriceVAT,
    insurancePrice,
    insurancePriceVAT,
  }) => {
    const summaryPrice = checkPrice + insurancePrice;
    const vat = checkPriceVAT + insurancePriceVAT;
    const totalPrice = summaryPrice + vat;

    return [totalPrice, summaryPrice, vat];
  };

  const calculatePaidPrice = ({
    paidCheckPrice,
    paidInsurancePrice,
    totalPrice,
  }) => {
    const paidPrice =
      _.toNumber(paidCheckPrice) + _.toNumber(paidInsurancePrice);

    const unpaidPrice = totalPrice - paidPrice;

    return [paidPrice, unpaidPrice];
  };

  const calculatePaidInfo = ({
    originPayment,
    currentPayment,
    lastUpdateTime,
  }) => {
    // 현재 건에 부여될 금액과 납부된 총 금액을 비교해서
    // 1. 작거나 같으면 납부 처리
    // 2. 크면 미납 처리

    const paidInfo = {
      checkPricePaidAt: null,
      checkPricePaidYN: YN.NO,
      insurancePricePaidAt: null,
      insurancePricePaidYN: YN.NO,
    };

    const {
      paidCheckPrice: originPaidCheckPrice,
      paidInsurancePrice: originPaidInsurancePrice,
      checkPricePaidAt: originCheckPricePaidAt,
      // checkPricePaidYN: originCheckPricePaidYN,
      insurancePricePaidAt: originInsurancePricePaidAt,
      // insurancePricePaidYN: originInsurancePricePaidYN
    } = originPayment;

    const {
      totalCheckPrice: currentTotalCheckPrice,
      totalInsurancePrice: currentTotalInsurancePrice,
      // paidCheckPrice: currentPaidCheckPrice,
      // paidInsurancePrice: currentPaidInsurancePrice,
      // checkPricePaidAt: currentCheckPricePaidAt,
      // checkPricePaidYN: currentCheckPricePaidYN,
      // insurancePricePaidAt: currentInsurancePricePaidAt,
      // insurancePricePaidYN: currentInsurancePricePaidYN
    } = currentPayment;

    // check price 먼저 확인
    if (currentTotalCheckPrice <= originPaidCheckPrice) {
      // 현재 책정된 금액이 기결제된 금액보다 적거나 같으면 수납 처리

      // 기존 수납 여부 값이 있으면 그걸로, 그렇지 않으면 작성 시점
      if (originCheckPricePaidAt !== null) {
        paidInfo.checkPricePaidAt = originCheckPricePaidAt;
        paidInfo.checkPricePaidYN = YN.YES;
      } else {
        paidInfo.checkPricePaidAt = lastUpdateTime;
        paidInfo.checkPricePaidYN = YN.YES;
      }
    }

    // insurance price 확인
    if (currentTotalInsurancePrice <= originPaidInsurancePrice) {
      if (originCheckPricePaidAt !== null) {
        paidInfo.insurancePricePaidAt = originInsurancePricePaidAt;
        paidInfo.insurancePricePaidYN = YN.YES;
      } else {
        paidInfo.insurancePricePaidAt = lastUpdateTime;
        paidInfo.insurancePricePaidYN = YN.YES;
      }
    }

    return [
      paidInfo.checkPricePaidAt,
      paidInfo.checkPricePaidYN,
      paidInfo.insurancePricePaidAt,
      paidInfo.insurancePricePaidYN,
    ];
  };

  const calculateCurrentTotalPrices = ({
    checkPriceTable,
    insurancePriceTables,
    paymentInformation: originPayment,
    vehicleInformation,
    lastUpdateTime,
  }) => {
    const { checkPrice, checkPriceVAT, totalCheckPrice } = getCurrentCheckPrice(
      {
        checkPriceTable,
        paymentInformation: originPayment,
      }
    );

    const { insurancePrice, insurancePriceVAT, totalInsurancePrice } =
      getCurrentInsurancePrice({
        paymentInformation: originPayment,
        insurancePriceTables,
        vehicleInformation,
      });

    const [totalPrice, summaryPrice, vat] = calculatePrices({
      checkPrice,
      checkPriceVAT,
      insurancePrice,
      insurancePriceVAT,
    });

    const [paidPrice, unpaidPrice] = calculatePaidPrice({
      paidCheckPrice: _.get(originPayment, 'paidCheckPrice'),
      paidInsurancePrice: _.get(originPayment, 'paidInsurancePrice'),
      totalPrice,
    });

    const updatePayment = {
      ...originPayment,
      checkPrice,
      checkPriceVAT,
      totalCheckPrice,
      paidCheckPriceAmount: totalCheckPrice,
      insurancePrice,
      insurancePriceVAT,
      totalInsurancePrice,
      paidInsurancePriceAmount: totalInsurancePrice,
      summaryPrice,
      vat,
      totalPrice,
      paidPrice,
      unpaidPrice,
    };

    const [
      checkPricePaidAt,
      checkPricePaidYN,
      insurancePricePaidAt,
      insurancePricePaidYN,
    ] = calculatePaidInfo({
      originPayment,
      currentPayment: updatePayment,
      lastUpdateTime,
    });

    return {
      ...updatePayment,
      checkPricePaidAt,
      checkPricePaidYN,
      insurancePricePaidAt,
      insurancePricePaidYN,
    };
  };

  const updateLedgerPayments = async () => {
    // 현재 저장된 데이터로 재계산한 원장 정보 업데이트 하는 함수
    const actualDataList = await getActualDataList();
    const statementNumbers = Object.keys(actualDataList);

    for (let statementNumber of statementNumbers) {
      const dataList = actualDataList[statementNumber];

      // console.log(`성능점검[${statementNumber}]번 : `, dataList);

      // 동일한 날짜에 점검된 것으로 테이블이 다를 일 X -> 0번째 데이터에서 가지고 오기
      const { association, enterprise, shop, paymentInformation } = _.get(
        dataList,
        '0'
      );

      const [
        [checkPriceTable],
        [
          insurancePriceTables,
          // insuranceCompany,
          // insurancePriceTable,
          // insurances,
        ],
      ] = await Promise.all([
        getCheckPriceTable({
          associationId: _.get(association, 'associationId'),
          enterpriseId: _.get(enterprise, 'enterpriseId'),
          shopId: _.get(shop, 'shopId'),
          checkPriceOf: _.get(paymentInformation, 'checkPriceOf'),
          checkPriceTableId: _.get(paymentInformation, 'checkPriceTableId'),
        }),
        getInsurancePriceTables({
          associationId: _.get(association, 'associationId'),
          enterpriseId: _.get(enterprise, 'enterpriseId'),
          shopId: _.get(shop, 'shopId'),
          insurancePriceOf: _.get(paymentInformation, 'insurancePriceOf'),
          insurancePriceTableId: _.get(
            paymentInformation,
            'insurancePriceTableId'
          ),
        }),
      ]);

      for (let i = 0; i < dataList.length; i++) {
        const current = dataList[i];
        const {
          paymentInformation: currentPayment,
          vehicleInformation,
          lastUpdateTime,
        } = current;
        const calculatedPayment = calculateCurrentTotalPrices({
          checkPriceTable,
          insurancePriceTables,
          paymentInformation,
          vehicleInformation,
          lastUpdateTime,
        });

        const ledger = {
          ...current,
          paymentInformation: {
            ...currentPayment,
            ...calculatedPayment,
          },
        };

        dataList[i] = ledger;

        // console.log(
        //   '원장 업데이트',
        //   `성능점검 [${statementNumber}] : `,
        //   dataList[i]
        // );
      }

      setDatas((ps) => ({
        ...ps,
        [statementNumber]: actualDataList[statementNumber],
      }));
    }
  };

  const updatePaymentDiff = () => {
    // 원장 업데이트 된 리스트 조회하면서 데이터의 타입에 따라 실제로 남겨져야 하는 결제 금액을 계산하는 함수

    const statementNumbers = Object.keys(datas);
    const targetNumber = statementNumbers[currentIdx];
    console.log('타겟 성능 점검 번호', targetNumber);
    const targetList = _.get(datas, targetNumber);
    console.log('타겟 리스트', targetList);

    for (let i = 0; i < targetList.length; i++) {
      const {
        dataId,
        paymentInformation: {
          checkPrice,
          checkPriceVAT,
          totalCheckPrice,
          insurancePrice,
          insurancePriceVAT,
          totalInsurancePrice,
        },
      } = targetList[i];

      // 기준 금액과 변동이 없으면 모두 0원 처리하고 settlementYN no
      // 금액이 0원이 될 때는 수납 여부 yes, 수납 시점 last update 시점으로 업데이트
    }
  };

  console.log(datas);

  return (
    <div>
      <Panel className="shadow-1 mb-3">
        <Controller
          control={control}
          name="statementNumber"
          render={({ field }) => (
            <>
              <div className="mb-2">
                <p className="font-semibold">검색 일자</p>
                <p>
                  시작일 :{' '}
                  {dayjs(searchConditions.startDate).format('YYYY-MM-DD')}
                </p>
                <p>
                  종료일 :{' '}
                  {dayjs(searchConditions.endDate).format('YYYY-MM-DD')}
                </p>
              </div>
              <div>
                <label className="font-semibold" htmlFor="">
                  성능 점검 번호
                </label>
                <InputText
                  id={field.name}
                  {...field}
                  onChange={(e) => {
                    setSearchConditions((ps) => ({
                      ...ps,
                      statementNumber: e.value,
                    }));
                  }}
                  onKeyDown={async (e) => {
                    if (e.key === 'Enter') {
                      await getCheckList();
                    }
                  }}
                />
                <Button
                  label="검색"
                  onClick={async (e) => {
                    await getCheckList();
                  }}
                />
              </div>
            </>
          )}
        />
        <div className="p-5">
          <Button
            label="결제정보 계산(원장)"
            onClick={async (e) => {
              await updateLedgerPayments();
            }}
          />
        </div>
        <div className="p-5">
          <div>
            <p>동일 성능점검 수 : {Object.keys(datas).length}</p>
            <div className="mb-2">
              <span className="mr-2">현재 인덱스 : {currentIdx}</span>
              <button
                onClick={() => {
                  setCurrentIdx((ps) => ps + 1);
                  setCurrentAmounts({
                    dataId: 0,
                    checkPrice: 0,
                    insurancePrice: 0,
                  });
                }}
              >
                인덱스 증가
              </button>
            </div>
          </div>
          <Button
            label="동일 성능점검 목록 차액 계산"
            onClick={() => {
              updatePaymentDiff();
            }}
          />
        </div>
      </Panel>
      {/* <Panel className="shadow-1 mb-3">{renderList()}</Panel> */}
    </div>
  );

  // return authorization ? (
  //   <div>
  //     <Panel className="shadow-1 mb-3">
  //       <Controller
  //         control={control}
  //         name="statementNumber"
  //         render={({ field }) => (
  //           <>
  //             <label htmlFor="">성능 점검 번호</label>
  //             <InputText
  //               id={field.name}
  //               {...field}
  //               onChange={(e) => {
  //                 setSearchConditions((ps) => ({
  //                   ...ps,
  //                   statementNumber: e.value,
  //                 }));
  //               }}
  //               onKeyDown={async (e) => {
  //                 if (e.key === 'Enter') {
  //                   await getChecklist();
  //                 }
  //               }}
  //             />
  //             <Button
  //               label="검색"
  //               onClick={async (e) => {
  //                 await getChecklist();
  //               }}
  //             />
  //           </>
  //         )}
  //       />
  //     </Panel>
  //     {/* <Panel className="shadow-1 mb-3">{renderList()}</Panel> */}
  //   </div>
  // )
  //  : (
  //   <div>
  //     <Controller
  //       control={control}
  //       name="password"
  //       render={({ field }) => (
  //         <>
  //           <Password
  //             id={field.name}
  //             {...field}
  //             feedback={false}
  //             onKeyDown={(e) => {
  //               if (e.key === 'Enter') {
  //                 checkAuthorization();
  //               }
  //             }}
  //           />
  //           <Button htmlFor={field.name} onClick={(e) => checkAuthorization()}>
  //             권한 확인
  //           </Button>
  //         </>
  //       )}
  //     />
  //   </div>
  // );
};

export default UpdatePayments;
