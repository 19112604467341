import {useEffect, useRef} from 'react';
import _ from 'lodash';

const usePrevious = (value) => {
  const ref = useRef({
    value,
    prev: null,
  });

  const current = ref.current.value;

  if (!_.isEqual(value, current)) {
    ref.current = {
      value,
      prev: current,
    };
  }

  return ref.current.prev;
};

export default usePrevious;
