import AxiosUtil from '../utils/axiosUtil';

export const SearchService = (function () {
  let instance;
  const BASE_URI = '/apis/searches';

  function init() {
    return {
      users: async function (params) {
        const queryString = Object.entries(params)
          .map((e) => e.join('='))
          .join('&');
        return await AxiosUtil.Async.get(BASE_URI + '/users', queryString);
      },
      customers: async function (params) {
        const queryString = Object.entries(params)
          .map((e) => e.join('='))
          .join('&');
        return await AxiosUtil.Async.get(BASE_URI + '/customers', queryString);
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();
