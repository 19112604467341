import React, { forwardRef } from 'react';

import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';

export const TitledInputText = forwardRef((props, ref) => {
  const {
    id,
    title = '',
    keyFilter = '',
    value,
    onChange,
    onKeyDown,
    onBlur,
    readOnly = false,
    disabled = false,
    className = '',
    placeholder = '',
    error = null,
    required = false,
    selectOnFocus = false,
    tooltip = '',
  } = props;

  return (
    <div className="field m-0 w-full">
      <label htmlFor={`txt_${id}`}>
        {required && <i className="text-red-400 pi pi-check mr-1" />}
        {title}
      </label>
      <InputText
        id={`txt_${id}`}
        ref={ref}
        className={classNames(
          'w-full ' + className,
          readOnly && (className === '' ? 'bg-gray-100' : className),
          { 'p-invalid': error }
        )}
        readOnly={readOnly}
        disabled={disabled}
        value={value}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        keyfilter={keyFilter}
        placeholder={placeholder}
        onFocus={selectOnFocus ? (e) => e.target.select() : null}
        tooltip={tooltip}
        tooltipOptions={{ position: 'top' }}
      />
      <small id={`txt_err_${id}`} className="p-error">
        {error?.message}
      </small>
    </div>
  );
});
