import React, { forwardRef, useEffect, useState } from 'react';

import { RadioButton } from 'primereact/radiobutton';
import { classNames } from 'primereact/utils';

export const TitledRadioButton = forwardRef((props, ref) => {
  const {
    id,
    name,
    title = '',
    value,
    items = [],
    onChange, // custom onChange는 사용하지 말 것. - 동작이 잘 안됨. ㅠㅠ
    error = null,
    disabled = false,
    selfHeight = false,
    required = false,
  } = props;

  // const [selectedValue, setSelectedValue] = useState(value);
  // useEffect(() => setSelectedValue(value), [value]);
  // useEffect(() => onChange(selectedValue), [selectedValue]);

  const getFormErrorMessage = () => {
    return error && <small className="p-error">{error?.message}</small>;
  };

  return (
    <>
      <div className="field m-0">
        <label>
          {required && <i className="text-red-400 pi pi-check mr-1" />}
          {title}
        </label>
        <div className="bg-transparent flex flex-auto flex-wrap align-items-center justify-content-start gap-3">
          {items.map((item, idx) => (
            <div key={`${name}_${idx}`}>
              <div className="flex flex-auto align-items-center justify-content-start gap-1">
                <RadioButton
                  inputRef={ref}
                  inputId={`trb_${id || name}_${idx}`}
                  name={name}
                  value={item.value}
                  onChange={(e) => onChange(e.value)}
                  checked={value === item.value}
                  disabled={disabled}
                />
                <label
                  className={item.labelColor}
                  htmlFor={`trb_${id || name}_${idx}`}
                >
                  {item.label}
                </label>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/*{getFormErrorMessage()}*/}
    </>
  );
});
