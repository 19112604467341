import React, { useEffect, useState } from 'react';
import { Card } from 'primereact/card';
import { Panel } from 'primereact/panel';
import Claim from '../../services/ClaimService';
import dayjs from 'dayjs';
import { Chart } from 'primereact/chart';

const Dashboard = () => {
  const IntFormatter = new Intl.NumberFormat('ko-KR', { style: 'decimal' });
  const [data, setData] = useState({
    performancCount: 0,
    claimCount: 0,
    claimProcessingRate: 0,
    claimReceiptCount: 0,
    claimCompleteCount: 0,
    claimRejectCount: 0,
    claimInsuranceCount: 0,
    claimRepairCount: 0,
    claimRepairCompleteCount: 0,
  });

  const getData = async () => {
    try {
      const data = await Claim.homeInfo();
      if (data) {
        setData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const today = new Date();
  // console.log(today);

  return (
    <Card title="사고 관리" className="dashboard-h p-2">
      <div className="pr-3 pb-2 flex justify-content-end align-items-center gap-2">
        <i className="pi pi-calendar" />
        Today : {dayjs(today).format('YYYY-MM-DD')}
      </div>
      <div className="grid text-center">
        <div className="col-4">
          <Panel header="누적성능점검">
            <div className="text-lg">
              {IntFormatter.format(data.performancCount) + ' 건'}
            </div>
          </Panel>
        </div>
        <div className="col-4">
          <Panel header="누적보증건수">
            <div className="text-lg">
              {IntFormatter.format(data.claimCount) + ' 건'}
            </div>
          </Panel>
        </div>
        <div className="col-4">
          <Panel header="처리율">
            <div className="text-lg">
              {IntFormatter.format(data.claimCount) + ' %'}
            </div>
          </Panel>
        </div>

        <div className="col-4">
          <Panel header="접수중">
            <div className="text-lg">
              {IntFormatter.format(data.claimReceiptCount) + ' 건'}
            </div>
          </Panel>
        </div>
        <div className="col-4">
          <Panel header="접수완료">
            <div className="text-lg">
              {IntFormatter.format(data.claimCompleteCount) + ' 건'}
            </div>
          </Panel>
        </div>
        <div className="col-4">
          <Panel header="보증거절">
            <div className="text-lg">
              {IntFormatter.format(data.claimRejectCount) + ' 건'}
            </div>
          </Panel>
        </div>
        <div className="col-4">
          <Panel header="진단안내">
            <div className="text-lg">
              {IntFormatter.format(data.claimInsuranceCount) + ' 건'}
            </div>
          </Panel>
        </div>
        <div className="col-4">
          <Panel header="수리진행">
            <div className="text-lg">
              {IntFormatter.format(data.claimRepairCount) + ' 건'}
            </div>
          </Panel>
        </div>
        <div className="col-4">
          <Panel header="비용지급">
            <div className="text-lg">
              {IntFormatter.format(data.claimRepairCompleteCount) + ' 건'}
            </div>
          </Panel>
        </div>
      </div>
    </Card>
  );
};

export default Dashboard;
