import { Controller } from 'react-hook-form';
import { InputSwitch } from 'primereact/inputswitch';
import React from 'react';
import { YN } from '../../../constants/Constants';
import { classNames } from 'primereact/utils';
export const ControlledInputSwitch = ({
  control,
  watch,
  inputData,
  styleClass,
}) => {
  const { inputLabel, toggleLabel, dataLabel } = inputData;
  const { trueLabel, falseLabel } = toggleLabel;

  return (
    <>
      <div className="flex flex-column align-items-start justify-content-center gap-1">
        <span className="p-panel-title">{inputLabel}</span>
      </div>
      <div className="flex align-items-center justify-content-start gap-1">
        <label
          className={classNames({
            [styleClass]: watch(dataLabel) === YN.NO,
          })}
        >
          {falseLabel}
        </label>
        <Controller
          control={control}
          name={dataLabel}
          defaultValue={YN.YES}
          render={({ field, fieldState }) => (
            <InputSwitch
              id={field.name}
              {...field}
              trueValue={YN.YES}
              falseValue={YN.NO}
              checked={field.value}
            />
          )}
        />
        <label
          className={classNames({
            [styleClass]: watch(dataLabel) === YN.YES,
          })}
        >
          {trueLabel}
        </label>
      </div>
    </>
  );
};
