const BoardPostType = {
  CustomerApp: {
    service: 'customer-app',
    notice: 'CAA',
  },
  Erp: {
    service: 'erp',
    notice: 'A',
    faq: 'F',
  },
};

Object.freeze(BoardPostType);

export default BoardPostType;
