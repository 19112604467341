import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PartnerDocument from '../../components/Accident/PartnerDocument';
import Claim from '../../services/ClaimService';
import * as Button from './../../components/Atoms/Button';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { CommonUtil } from '../../utils/commonUtil';
import { BlockUI } from 'primereact/blockui';
import CustomerDocument from '../../components/Accident/CustomerDocument';
import RepariDocument from '../../components/Accident/RepariDocument';
import { ServiceProvider } from '../../services/index';
import dayjs from 'dayjs';

const kakaoService = ServiceProvider.kakao;

const PartnerUpload = () => {
  let { aI, carNum, cusN, cusC, dealer, partner, pId } = useParams();

  const aid = aI.split('=')[1];
  const carNbr = carNum.split('=')[1];
  const customerName = cusN.split('=')[1];
  const customerContact = cusC.split('=')[1];
  const dealerUser = dealer.split('=')[1] === 'true' ? true : false;
  const partnerUser = partner.split('=')[1] === 'true' ? true : false;

  // useEffect(() => {
  //   localStorage.removeItem('accessToken');
  //   localStorage.removeItem('isLoggedIn');
  //   sessionStorage.removeItem('images');
  // }, []);
  const [selectedPartner, setSelectedPartner] = useState([]);
  const [fileItems, setFileItems] = useState([]); // 모든 사진 파일
  const [deleteImgs, setDeleteImgs] = useState([]); // 모든 삭제하는 사진 파일
  const [docs, setDocs] = useState([]); // claimDocs
  const [copyFile, setCopyFile] = useState([]); // 사진 파일 카피
  const [detailData, setDetailData] = useState({}); // claimData 기존고객
  const [carDetailData, setCarDetailData] = useState(null); // 자동자 상세정보
  const [statusCode, setStatusCode] = useState('');
  const [warrantyData, setWarrantyData] = useState({
    buyDate: '',
    checkDistance: 0,
    distanceDriven: 0,
    diagnosisType: [],
    reservationDate: '',
    producingCountry: '',
    carName: '',
    partnerInfo: [],
    radius: 0,
    partnerNote: '', // opt
  });
  const [insuranceInfo, setInsuranceInfo] = useState({
    payDate: '',
    paymentAmt: null, // int
    deductibleAmt: null, // int
    completeDate: '',
  });
  const [repairData, setRepairData] = useState({
    date: '',
    maintenanceCode: '',
    workYN: '',
    workFee: null, // int
  }); // 수리정보
  const [loading, setLoading] = useState(false);

  const [carYX, setCarYX] = useState('');
  const [carAddress, setCarAddress] = useState(''); // 차량 위치

  const getAddressInfo = async (address) => {
    const {
      documents: [res],
    } = await kakaoService.getAddressInfo({
      query: address,
    });

    // console.log('좌표 정보', '경도', res.x, '위도', res.y);
    setCarYX(`${res.y},${res.x}`);
  };

  const getDetailData = async () => {
    setLoading(true);
    try {
      const data = await Claim.getClaimDetail({
        aid,
        carNbr,
        customerName,
        customerContact,
      });
      if (data) {
        // console.log(data);
        if (data.claimData.carLocation) {
          setCarYX(data.claimData.carLocation);
        } else if (data.claimData.carAddress) {
          getAddressInfo(data.claimData.carAddress);
        }
        if (data.claimData.carAddress) {
          setCarAddress(data.claimData.carAddress);
        }

        setDetailData(data.claimData);
        setDocs(data.claimDocuments);
        setWarrantyData({
          buyDate: !!data.claimData.buyDate ? data.claimData.buyDate : null,
          checkDistance: data.claimData.checkDistance,
          distanceDriven: data.claimData.distanceDriven,
          diagnosisType: data.claimData.diagnosisType
            ? data.claimData.diagnosisType
            : null,
          reservationDate: data.claimData.reservationDate,
          producingCountry: data.claimData.producingCountry,
          carName: data.claimData.carName,
          partnerInfo: data.claimData.partnerInfo
            ? data.claimData.partnerInfo
            : null,
          partnerNote: data.claimData.partnerNote,
        });
        setSelectedPartner(data.claimData.partnerInfo);
        setStatusCode(data.claimData.claimStatus);
        setRepairData({
          date: data.claimData.partnerReceiptDate
            ? CommonUtil.Formatter.stringToDate(
                data.claimData.partnerReceiptDate
              )
            : '',
          maintenanceCode: data.claimData.maintenanceCode
            ? data.claimData.maintenanceCode.split(',')
            : '',
          workYN: data.claimData.workYN,
          workFee: data.claimData.workFee,
          repairNote: data.claimData.repairNote,
          repairOpinion: data.claimData.repairOpinion
            ? data.claimData.repairOpinion
            : '',
        });
        setInsuranceInfo({
          payDate: data.claimData.insurancePurchaseDate
            ? CommonUtil.Formatter.stringToDate(
                data.claimData.insurancePurchaseDate
              )
            : '',
          paymentAmt: data.claimData.insurancePaymentAmt, // int
          deductibleAmt: data.claimData.deductibleAmt, // int
          completeDate: data.claimData.completeDate
            ? CommonUtil.Formatter.stringToDate(data.claimData.completeDate)
            : '',
          insurancePolicyNumber: data.claimData.insurancePolicyNumber
            ? data.claimData.insurancePolicyNumber
            : '',
        });
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // const checkWarranty = async (carNbr) => {
  //   setLoading(true);
  //   try {
  //     const data = await Claim.getCarDetail(carNbr);
  //     const vehicleInformation = data.performanceCheckData.vehicleInformation
  //       ? JSON.parse(data.performanceCheckData.vehicleInformation)
  //       : null;

  //     if (data) {
  //       setLoading(false);
  //       setCarDetailData({
  //         ...data,
  //         carVIN: vehicleInformation.VIN + vehicleInformation.vinRemain,
  //       });
  //     }
  //   } catch (error) {
  //     if (error?.response.status === 400) return; // 미진단차량일 경우
  //     setLoading(false);
  //     console.log(error);
  //     window.cerp.toast.warn(error?.response.data.error?.message);
  //   }
  // };

  useEffect(() => {
    if (carNbr && customerName && customerContact) {
      getDetailData();
      // checkWarranty(carNbr);
    }
  }, [carNbr, customerName, customerContact, dealerUser]);

  const paymentInfo = !!carDetailData?.performanceCheckData
    ? JSON.parse(carDetailData.performanceCheckData?.paymentInformation)
    : '';

  //TODO - 클레임 수정 PUT
  const changetClaim = async () => {
    setLoading(true);
    let fileDataList = [];
    let filesList = [];

    const del = [];
    if (!!deleteImgs.length) {
      // console.log('삭제하려는 이미지가 있는 경우 => 기존 이미지');
      const imgs = deleteImgs.filter((el) => !!el.imgId);
      // console.log('imgs', imgs);
      if (!!imgs.length) {
        imgs.map((el) =>
          del.push({
            imgId: el.imgId,
            documentCode: el.documentCode,
            state: 'D',
          })
        );
      }

      // console.log(del);
      fileDataList = del;
    }
    const findFile = fileItems.find((el) => el.file); // File 이 있는지 찾는다.
    const findImgId = fileItems.find((el) => el.imgId); // 기존 이미지를 찾는다.
    const idFile = []; // imgId 를 가지고 있는 기존 이미지파일
    fileItems.filter((el) => el.imgId && idFile.push(el));
    const imgFile = []; // file 을 가지고 있는 새로 등록한 이미지 파일
    fileItems.filter((el) => el.file && imgFile.push(el));

    if (findFile && !findImgId) {
      // console.log('새 이미지');
      fileItems.map((el) =>
        fileDataList.push({
          documentCode: el.documentCode,
          state: el.state,
        })
      );
      fileItems.map((el) => filesList.push(el.file));
    } else if (!findFile && findImgId) {
    } else if (findFile && findImgId) {
      imgFile.map((el, idx) =>
        fileDataList.push({
          documentCode: el.documentCode,
          state: el.state,
        })
      );
      imgFile.map((el) => filesList.push(el.file));
    }

    const partnerFiles = [];
    selectedPartner.map((partner) =>
      partner.partnerDocuments?.filter(
        (el) => el.file && partnerFiles.push(el.file)
      )
    );

    const modifyPartners = selectedPartner.map((partner) => {
      partner.partnerDocuments = partner.partnerDocuments
        ?.map((el) => {
          const { file, imgId, ...rest } = el;
          if (imgId) {
            return null;
          } else {
            return rest;
          }
        })
        .filter((el) => el !== null);
      return partner;
    });

    const filteredPartner = warrantyData?.selectedPartner?.filter(
      (partner) => !(partner.useYn === 'N' && partner.isOriginal === false)
    );
    if (
      !!filteredPartner &&
      filteredPartner.some(
        (el) => !el.repairCodes || el.repairCodes.length === 0
      )
    ) {
      setLoading(false);
      window.cerp.toast.warn('진단점의 진단부위를 선택해주세요.');
      return;
    }

    let partnerInfoData = [];
    if (!!modifyPartners) {
      partnerInfoData = modifyPartners.map((el) => ({
        partnerName: el?.partnerName?.split(' (')[0],
        carNbr: el.carNbr,
        statementNumber: el.statementNumber ?? null,
        checkDate: el.checkData ?? null,
        partnerId: el.partnerId,
        repairCode: el.repairCode?.toString(),
        reservationDate: !!el.reservationDate
          ? dayjs(el.reservationDate).format('YYYYMMDD')
          : null,
        useYn: el.useYn,
        documentGroupId: el?.documentGroupId ?? null,
        fileData: el.partnerDocuments ?? [],
        partnerType: !!el.partnerType ? el.partnerType?.toString() : null,
        claimStatusCode: el?.claimStatusCode ?? null,
        receiptDate: !!el.receiptDate
          ? dayjs(el.receiptDate).format('YYYYMMDD')
          : null,
        dueDate: el?.dueDate ? dayjs(el?.dueDate).format('YYYYMMDD') : null,
        paymentAmt: el?.paymentAmt ?? null,
      }));
    }

    const updateData = {
      dataId: detailData?.dataId,
      associationId: aid,
      addType: detailData.addType,
      assuranceType: detailData?.assuranceType,
      checkFlag: detailData?.checkFlag,
      funnelsType: detailData.funnelsType, // W or A
      claimStatus: detailData.statusCode,
      customerName: detailData.customerName,
      customerContact: detailData.customerContact,
      statementNumber: detailData.statementNumber ?? null,
      carNbr: detailData.carNbr,
      checkDate: detailData.checkDate ?? null,
      buyDate: detailData?.buyDate,
      checkDistance: warrantyData.checkDistance, //vehicleInformation > TRVL_DSTNC
      distanceDriven: warrantyData.distanceDriven
        ? Number(warrantyData.distanceDriven)
        : null,
      producingCountry: warrantyData.producingCountry, // D or F
      carName: warrantyData.carName,
      carAddress: carAddress ? carAddress : null,
      carLocation: carYX ? carYX : null, //Opt
      diagnosisType:
        warrantyData.diagnosisType &&
        typeof warrantyData.diagnosisType === 'string'
          ? warrantyData.diagnosisType
          : warrantyData.diagnosisType && warrantyData.diagnosisType.length > 0
          ? warrantyData.diagnosisType.join()
          : null, // 진단타입 B, D..
      consultationGroupId: detailData?.consultationGroupId ?? null,
      reservationDate: detailData.reservationDate ?? null, //Opt
      partnerInfo: !!partnerInfoData.length ? partnerInfoData : null, //Opt 진단점 목록
      receiptId: detailData.userId ? detailData.userId : null,
      receiptDate: detailData?.receiptDate, // 접수 일자
      documentGroupId: detailData?.documentGroupId, //Opt
      note: null, //Opt
      partenerReceiptDate: detailData?.partenerReceiptDate, //Opt 수리 입고일자
      maintenanceCode: detailData?.maintenanceCode
        ? detailData?.maintenanceCode
        : repairData.maintenanceCode.length > 0
        ? repairData.maintenanceCode.join()
        : null,
      workYN: repairData.workYN ? repairData.workYN : null, //Opt 수리여부
      workFee: repairData.workFee ? Number(repairData.workFee) : null, //Opt 협의비용
      insuranceCode: detailData?.ICNY_CODE, // opt 보험코드
      insurancePolicyNumber: detailData?.insurancePolicyNumber, //opt 증권번호
      insurancePurchaseDate: insuranceInfo.payDate
        ? CommonUtil.Formatter.dateToString(insuranceInfo.payDate)
        : null, //Opt 보험료 지급일
      insurancePaymentAmt: insuranceInfo.paymentAmt
        ? insuranceInfo.paymentAmt
        : null, //Opt 보험료
      deductibleAmt: insuranceInfo.deductibleAmt
        ? insuranceInfo.deductibleAmt
        : null, // opt 자기부담금
      partnerNote: warrantyData.partnerNote ? warrantyData.partnerNote : null,
      repairNote: repairData.repairNote ? repairData.repairNote : null,
      repairOpinion: repairData.repairOpinion ? repairData.repairOpinion : null, // Opt 소견내용
      completeYN: insuranceInfo.completeDate ? 'Y' : 'N', //Opt 완료여부
      completeDate: insuranceInfo.completeDate
        ? CommonUtil.Formatter.dateToString(insuranceInfo.completeDate)
        : null, //Opt 완료일
      completeLoginId: detailData.completeLoginId, //Opt 로그인유저 아이디
      completeLoginContact: detailData.completeLoginContact, //opt 로그인유저 연락처
      fileData: fileDataList, //Otp
    };

    const checkData =
      !!updateData.assuranceType &&
      !!updateData.checkFlag &&
      !!updateData.receiptDate;

    if (!checkData) {
      setLoading(false);
      window.cerp.toast.warn('필수 입력값을 입력해주세요.');
      return;
    }

    try {
      const data = await Claim.partnerUploadNoAuth(
        updateData,
        filesList,
        partnerFiles
      );
      if (data) {
        setLoading(false);
        window.cerp.toast.info('업로드가 완료 되었습니다.');
        sessionStorage.removeItem('images');
      }
    } catch (error) {
      console.log(error);
      window.cerp.toast.error(error?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (docs) {
      setFileItems(docs);
      setCopyFile(docs);
    }
  }, [docs]);

  return (
    <div className="p-4">
      <div className="flex justify-content-center">
        <h2 className="font-bold">
          {dealerUser
            ? '매매상사 사진 업로드'
            : partnerUser
            ? '차량 사진 업로드'
            : '진단점 차량 사진 업로드'}
        </h2>
      </div>
      <BlockUI
        fullScreen
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
        blocked={loading}
      >
        <Panel header="1. 차량 정보">
          <div className="field grid align-items-center">
            <div className="col-3 text-2xl">
              <label className="font-bold" htmlFor="">
                차량번호
              </label>
            </div>
            <div className="col">
              <InputText
                readOnly
                value={carNbr || ''}
                name="carNum"
                className=" w-full font-bold text-2xl"
              />
            </div>
          </div>
          <div className="field grid align-items-center">
            <div className="col-3 text-2xl">
              <label className="font-bold" htmlFor="">
                차대번호
              </label>
            </div>
            <div className="col">
              <InputText
                readOnly
                value={carDetailData?.carVIN || ''}
                name="carVIN"
                className=" w-full font-bold text-2xl"
              />
            </div>
          </div>
          <div className="field grid align-items-center">
            <div className="col-3 text-2xl">
              <label className="font-bold" htmlFor="">
                차량명
              </label>
            </div>
            <div className="col">
              <InputText
                readOnly
                value={warrantyData?.carName || ''}
                name="carVIN"
                className=" w-full font-bold text-2xl"
              />
            </div>
          </div>
        </Panel>
        {dealerUser ? (
          <CustomerDocument
            type="upload"
            deleteImgs={deleteImgs}
            setDeleteImgs={setDeleteImgs}
            fileItems={fileItems}
            setFileItems={setFileItems}
            copyFile={copyFile}
            carNbr={carNbr}
          />
        ) : (
          partnerUser && (
            <PartnerDocument
              deleteImgs={deleteImgs}
              setDeleteImgs={setDeleteImgs}
              fileItems={fileItems}
              setFileItems={setFileItems}
              copyFile={copyFile}
              carNbr={carNbr}
              selectedPartner={selectedPartner}
              setSelectedPartner={setSelectedPartner}
            />
          )
        )}
        {/* {partnerUser && (
          <RepariDocument
            type="upload"
            deleteImgs={deleteImgs}
            setDeleteImgs={setDeleteImgs}
            fileItems={fileItems}
            setFileItems={setFileItems}
            copyFile={copyFile}
            carNbr={carNbr}
            repairData={repairData}
            setRepairData={setRepairData}
          />
        )} */}
        <div className="flex justify-content-center my-5">
          <Button.Default
            className="text-2xl font-bold"
            icon="pi pi-save text-2xl"
            label="사진 업로드 하기"
            onClick={() => changetClaim()}
          />
        </div>
      </BlockUI>
    </div>
  );
};

export default PartnerUpload;
