import { Button } from 'primereact/button';
import React from 'react';

export const CloseButton = ({ onClose }) => {
  return (
    <Button
      type="button"
      label="닫기"
      className="p-button-outlined"
      onClick={onClose}
    />
  );
};
