import AxiosUtil from '../utils/axiosUtil';

export const QNAService = (function () {
  let instance;
  const BASE_URI = '/apis/qna';
  const REPLY_BASE_URI = BASE_URI + '/reply';

  function init() {
    return {
      getList: async function (params) {
        const queryString = Object.entries(params)
          .map((e) => e.join('='))
          .join('&');
        return await AxiosUtil.Async.get(BASE_URI, queryString);
      },
      registerData: async function (data, files) {
        return await AxiosUtil.Async.multipart(BASE_URI + '/post', data, files);
      },
      getData: async function (id) {
        const queryString = `seq=${id}`;

        return await AxiosUtil.Async.get(BASE_URI + '/detail', queryString);
      },
      updateData: async function (data, files) {
        return await AxiosUtil.Async.updateMultipart(
          BASE_URI + '/update',
          data,
          files
        );
      },
      deleteData: async function (id) {
        const queryString = `?seq=${id}`;
        return await AxiosUtil.Async.delete(BASE_URI + '/delete' + queryString);
      },
      verify: async function (data) {
        return await AxiosUtil.Async.post(BASE_URI + '/verify', data);
      },
      registerComment: async function (data) {
        return await AxiosUtil.Async.post(REPLY_BASE_URI + '/post', data);
      },
      updateComment: async function (data) {
        return await AxiosUtil.Async.put(REPLY_BASE_URI + '/update', data);
      },
      deleteComment: async function (id) {
        const queryString = `?seq=${id}`;
        return await AxiosUtil.Async.delete(
          REPLY_BASE_URI + '/delete' + queryString
        );
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();
