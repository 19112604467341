import * as Button from './../../components/Atoms/Button';
import * as filesize from 'filesize';

import { Accordion, AccordionTab } from 'primereact/accordion';
import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue, useRecoilValueLoadable } from 'recoil';

import { Badge } from 'primereact/badge';
import BoardPostType from '../../enums/BoardPostType';
import { ConfirmDialog } from 'primereact/confirmdialog';
import DOMPurify from 'dompurify';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { Image } from 'primereact/image';
import { InputText } from 'primereact/inputtext';
import MultiEditor from '../../components/Editor/MultiEditor';
import { Panel } from 'primereact/panel';
import { ProgressBar } from 'primereact/progressbar';
import SearchMenu from '../../components/Menu/SearchMenu';
import { ServiceProvider } from '../../services';
import UserRoleType from '../../enums/UserRoleType';
import _ from 'lodash';
import { myInfoSelector } from '../../recoil/selectors';
import { touchUIState } from '../../recoil/atoms';
import { useParams } from 'react-router-dom';

const DEFAULT_SEARCH_CONDITIONS = {
  searchText: '',
  postType: '',
};
// const DEFAULT_LAZY_PARAMS = {
//   first: 0,
//   rows: 10,
//   page: 0,
//   sortField: null,
//   sortOrder: null,
//   filters: {
//     name: { value: '', matchMode: 'contains' },
//     'country.name': { value: '', matchMode: 'contains' },
//     company: { value: '', matchMode: 'contains' },
//     'representative.name': { value: '', matchMode: 'contains' },
//   },
// };

const boardService = ServiceProvider.board;

const FAQList = () => {
  const touchUI = useRecoilValue(touchUIState);
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);

  const { service } = useParams();

  const defaultSearchConditions = useMemo(() => {
    const postType = _.get(_.find(BoardPostType, { service }), 'faq');

    return {
      ...DEFAULT_SEARCH_CONDITIONS,
      postType,
    };
  }, [service]);

  const [showConfirmPopup, setShowConfirmPopup] = useState({
    delete: false,
  });
  const [currentPostId, setCurrentPostId] = useState(null);

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentFiles, setCurrentFiles] = useState([]);

  const [loading, setLoading] = useState(false);
  const [searchConditions, setSearchConditions] = useState({
    ...defaultSearchConditions,
  });
  // const [lazyParams, setLazyParams] = useState({ ...DEFAULT_LAZY_PARAMS });

  function checkRegistAuth(userInfo) {
    const { roleCode } = userInfo;
    // 어드민, 협회
    const ACCESS_PERMISSION = _.filter(UserRoleType, function (r) {
      return (
        r.value === 'CERP_ADM' ||
        r.value === 'A_MST' ||
        r.value === 'A_ADM' ||
        r.value === 'A_OFF'
      );
    });

    return _.findIndex(ACCESS_PERMISSION, { value: roleCode }) >= 0;
  }

  async function getList(conditions) {
    const params = {
      ...conditions,
      // page: page + 1,
      // size: size,
    };

    setLoading(true);

    try {
      const {
        data: { list, total },
      } = await boardService.getList(params);

      setData(list);
      setTotal(total);
    } catch (error) {
      window.cerp.dialog.error(
        'FAQ 게시글 조회 실패',
        `[${error?.code}]${error?.message}`
      );
    }

    setLoading(false);
  }

  async function initList() {
    setSearchConditions({ ...defaultSearchConditions });
    // setLazyParams({ ...DEFAULT_LAZY_PARAMS });
    await getList(defaultSearchConditions);
  }

  async function deleteFAQ(id) {
    try {
      const { data } = await boardService.delete({
        seq: id,
        postType: _.get(defaultSearchConditions, 'postType'),
      });

      window.cerp.toast.success(
        '게시글 삭제 완료',
        '선택하신 FAQ 게시글이 삭제되었습니다.'
      );
    } catch (error) {
      window.cerp.dialog.error(
        '게시글 삭제 실패',
        `[${error?.code}]${error?.message}`
      );
    }
  }

  function downloadFile(url) {
    window.open(process.env.REACT_APP_S3_BASE_URL + url);
  }

  function filterFiles(files) {
    const images = [];
    const otherFiles = [];

    files.forEach((file) => {
      const { type } = file;

      if (_.startsWith(type, 'image/')) {
        images.push(file);
      } else {
        otherFiles.push(file);
      }
    });

    return [images, otherFiles];
  }

  useEffect(() => {
    (async () => {
      await getList(searchConditions);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="grid">
      <div className="col-12">
        {(() => {
          switch (myInfoLoadable.state) {
            case 'hasValue':
              return (
                <>
                  <SearchMenu
                    onInit={async () => await initList()}
                    onSearch={async () => await getList(searchConditions)}
                  >
                    <div className="field col-12 flex flex-column justify-content-center">
                      <label htmlFor="searchText" className="font-bold">
                        검색
                      </label>
                      <InputText
                        className="w-full"
                        value={searchConditions.searchText}
                        placeholder="질문 또는 답변 검색"
                        onChange={(e) => {
                          setSearchConditions((ps) => ({
                            ...ps,
                            searchText: e.target.value,
                          }));
                        }}
                      />
                    </div>
                  </SearchMenu>
                  {checkRegistAuth(myInfoLoadable.contents) && (
                    <div className="card flex flex-auto flex-wrap align-items-center justify-content-end gap-1">
                      <Button.Default
                        label="FAQ 신규 등록"
                        icon="pi pi-check-square"
                        style={{ marginLeft: 'auto' }}
                        onClick={() => {
                          setCurrentPostId(0);
                        }}
                      />
                    </div>
                  )}
                  <Panel className="shadow-1 mb-3">
                    {data && data.length > 0 && (
                      <div className="px-1">
                        <Accordion multiple>
                          {data.map((item, idx) => {
                            const {
                              boardData: { title, content, seq },
                              boardFilesData,
                            } = item;

                            const [images, otherFiles] =
                              filterFiles(boardFilesData);

                            return (
                              <AccordionTab
                                header={
                                  <div className="flex align-items-center text-xl w-full">
                                    <span>{title}</span>
                                  </div>
                                }
                                key={`faq_${idx}`}
                              >
                                <>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(content),
                                    }}
                                    className="mb-4"
                                  />
                                  {images && images.length > 0 && (
                                    <>
                                      {images.map((img, idx) => {
                                        const { filePath, originalFileName } =
                                          img;

                                        return (
                                          <Image
                                            key={`img_${idx}`}
                                            preview
                                            src={
                                              process.env
                                                .REACT_APP_S3_BASE_URL +
                                              filePath
                                            }
                                            alt={originalFileName}
                                            imageClassName="border-round border-1 border-200"
                                            imageStyle={{
                                              width: 100,
                                              height: 100,
                                            }}
                                          />
                                        );
                                      })}
                                    </>
                                  )}
                                  <Divider />
                                  {otherFiles && otherFiles.length > 0 && (
                                    <div className="flex align-items-center">
                                      <span
                                        className="text-center font-bold px-4"
                                        style={{ wordBreak: 'keep-all' }}
                                      >
                                        첨부파일
                                      </span>
                                      <div className="grid mr-0 ml-0 mt-0">
                                        {otherFiles.map((file, idx) => {
                                          const {
                                            originalFileName,
                                            fileSize,
                                            filePath,
                                          } = file;
                                          return (
                                            <div
                                              className="flex align-items-center gap-1 mr-4 mb-2"
                                              key={`files_${idx}`}
                                              onClick={() =>
                                                downloadFile(filePath)
                                              }
                                              style={{
                                                cursor: 'pointer',
                                              }}
                                            >
                                              <span>{originalFileName}</span>
                                              <Badge
                                                value={`${filesize(fileSize, {
                                                  round: 1,
                                                  standard: 'jedec',
                                                })}`}
                                                severity="info"
                                              />
                                              <i className="pi pi-download text-xl"></i>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}
                                  {checkRegistAuth(myInfoLoadable.contents) && (
                                    <div className="button_wrapper flex gap-1 justify-content-end">
                                      <Button.Delete
                                        onDelete={() =>
                                          setShowConfirmPopup((ps) => ({
                                            ...ps,
                                            delete: {
                                              [`${seq}`]: true,
                                            },
                                          }))
                                        }
                                      />
                                      <ConfirmDialog
                                        visible={showConfirmPopup['delete']}
                                        header="확인"
                                        message={
                                          <p>FAQ 게시글을 삭제하시겠습니까?</p>
                                        }
                                        icon="pi pi-exclamation-triangle"
                                        acceptLabel="네"
                                        rejectLabel="아니오"
                                        accept={async () => {
                                          await deleteFAQ(seq);
                                          await getList(searchConditions);
                                        }}
                                        reject={() =>
                                          setShowConfirmPopup((ps) => ({
                                            ...ps,
                                            delete: false,
                                          }))
                                        }
                                        onHide={() =>
                                          setShowConfirmPopup((ps) => ({
                                            ...ps,
                                            delete: false,
                                          }))
                                        }
                                      />
                                      <Button.Default
                                        label="수정"
                                        icon="pi pi-pencil"
                                        onClick={() => {
                                          setCurrentPostId(seq);
                                        }}
                                      />
                                    </div>
                                  )}
                                </>
                              </AccordionTab>
                            );
                          })}
                        </Accordion>
                      </div>
                    )}
                    {(!data || !data.length) && '데이터가 없습니다.'}
                  </Panel>

                  {currentPostId !== null && (
                    <Dialog
                      visible
                      header={
                        currentPostId === 0 ? 'FAQ 신규 등록' : 'FAQ 수정'
                      }
                      style={{
                        width: '80vw',
                        height: '80vh',
                      }}
                      onHide={async () => {
                        setCurrentPostId(null);
                        await getList(searchConditions);
                      }}
                    >
                      <MultiEditor
                        service={service}
                        postType={_.get(defaultSearchConditions, 'postType')}
                        postId={currentPostId}
                        userInfo={myInfoLoadable.contents}
                        onHide={async () => {
                          setCurrentPostId(null);
                          await getList(searchConditions);
                        }}
                      />
                    </Dialog>
                  )}
                </>
              );
            case 'loading':
              return <ProgressBar mode="indeterminate" />;
            case 'hasError':
              return null;
            default:
          }
        })()}
      </div>
    </div>
  );
};

export default FAQList;
