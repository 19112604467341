import React, { useEffect, useRef, useState } from 'react';
import _, { wrap } from 'lodash';

import { BlockUI } from 'primereact/blockui';
import { Menubar } from 'primereact/menubar';
import PerformanceCheckReport from '../../components/PerformanceCheck/PerformanceCheckReport';
import { ServiceProvider } from '../../services';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { useReactToPrint } from 'react-to-print';

const performanceCheckService = ServiceProvider.performanceCheck;

const PerformanceCheckViewer = ({
  match: {
    params: { statementNumber, dataId },
  },
}) => {
  const reportRef = useRef(null);
  const reportCaptureRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [checkData, setCheckData] = useState(null);
  const [checkPhotos, setCheckPhotos] = useState(null);
  const [data, setData] = useState({
    paymentInformation: null,
    association: null,
    inspector: null,
    vehicleInformation: null,
    customerCompany: null,
  });

  const [errorData, setErrorData] = useState(null);
  const [reportCaptureData, setReportCaptureData] = useState([]);

  const handlePrint = useReactToPrint({
    content: () => {
      const paperWrapper = reportRef.current.querySelectorAll('.paper_wrapper');

      console.log(paperWrapper);

      // if (paperWrapper.length > 0) {
      //   paperWrapper.forEach((wrapper) => {
      //     wrapper.style.width = '210mm';
      //     wrapper.style.height = '297mm';
      //     wrapper.style.minHeight = '297mm';
      //     wrapper.style.border = 'initial';
      //     wrapper.style.borderRadius = 'initial';
      //     wrapper.style.boxShadow = 'initial';
      //     wrapper.style.background = 'initial';
      //     wrapper.style.pageBreakAfter = 'initial';
      //   });
      // }

      const papers = reportRef.current.querySelectorAll('.paper');
      if (papers.length === 0) return false;

      const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
      for (let i = 0; i < papers.length; i++) {
        const page = papers[i];

        // page.style.transform = 'rotate(90deg)';
        // page.style.width = `148.5mm`;
        // page.style.height = '210mm';
        // page.style.minHeight = '210mm';
        // page.style.pageBreakAfter = 'avoid';

        const parts = page.querySelectorAll('.cerp-pp');
        for (let j = 0; j < parts.length; j++) {
          const part = parts[j];
          part.style.fontSize = '1rem';
        }

        const watermark = buildWatermark(now);
        page.appendChild(watermark);
      }

      return reportRef.current;
    },
    onBeforePrint: async () => {
      setLoading(true);
    },
    onAfterPrint: () => {
      const watermarks = reportRef.current.querySelectorAll('.cerp-watermark');
      _.map(watermarks, (watermark) => watermark.remove());

      const parts = reportRef.current.querySelectorAll('.cerp-pp');
      _.map(parts, (part) => (part.style.fontSize = '1.25rem'));

      setLoading(false);
    },
  });

  const handlePDF = async () => {
    setLoading(true);
    const papers = reportRef.current.querySelectorAll('.paper');
    if (papers.length === 0) return false;

    const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const pageImages = [];
    const reportPDF = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
      compress: true,
    });

    for (let i = 0; i < papers.length; i++) {
      const page = papers[i];
      const watermark = buildWatermark(now);
      page.appendChild(watermark);

      const canvas = await html2canvas(page, {
        scale: 2,
        logging: true,
        allowTaint: false,
        useCORS: true,
        imageTimeout: 0,
        backgroundColor: 'transparent',
      });

      const pageImage = canvas.toDataURL('image/png');
      pageImages.push(pageImage);
      page.removeChild(watermark);
    }

    for (let i = 0; i < pageImages.length; i++) {
      reportPDF.addImage(
        pageImages[i],
        'PNG',
        0,
        0,
        210,
        297,
        `PAGE-${i}`,
        'NONE',
        0
      );
      if (i < pageImages.length - 1) reportPDF.addPage();
    }

    reportPDF.save(`성능점검기록부_${statementNumber}.pdf`);
    setLoading(false);
  };

  const buildWatermark = (now) => {
    const watermark = document.createElement('div');
    _.map(
      _.split(
        'cerp-watermark absolute top-50 left-0 right-0 opacity-40 text-gray-500 font-bold p-4rem text-center text-4xl origin-center',
        ' '
      ),
      (cls) => watermark.classList.add(cls)
    );
    watermark.style.transform = 'rotate(-30deg)';
    const text = document.createTextNode(`출력일시 : ${now}`);
    watermark.appendChild(text);

    return watermark;
  };

  useEffect(() => {
    setLoading(true);

    (async () => {
      const { data, error } = await performanceCheckService.getData(
        // statementNumber
        dataId
      );

      if (data) {
        const {
          performanceCheckData: {
            checkData: checkDataRaw,
            paymentInformation: paymentInformationRaw,
            association: associationRaw,
            inspector: inspectorRaw,
            customerCompany: customerCompanyRaw,
            vehicleInformation: vehicleInformationRaw,
          },
          performanceCheckPhotos,
        } = data;

        const checkData = JSON.parse(checkDataRaw);
        const paymentInformation = JSON.parse(paymentInformationRaw);
        const association = JSON.parse(associationRaw);
        const inspector = JSON.parse(inspectorRaw);
        const customerCompany = JSON.parse(customerCompanyRaw);
        const vehicleInformation = JSON.parse(vehicleInformationRaw);

        setCheckData(checkData);
        setCheckPhotos(performanceCheckPhotos);
        setData((ps) => ({
          ...ps,
          paymentInformation,
          association,
          inspector,
          vehicleInformation,
          customerCompany,
        }));
      }

      if (error) {
        window.cerp.dialog.error(
          '존재하지 않는 성능지번호 입니다.',
          `[${statementNumber}]`
        );
        setErrorData(error);
      }
    })();

    setLoading(false);
  }, [dataId, statementNumber]);

  return (
    <div className="pcr_wrapper">
      <BlockUI
        fullScreen
        blocked={loading}
        template={
          loading && (
            <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />
          )
        }
      >
        <Menubar
          className="sticky top-0 z-1"
          model={[
            {
              label: '출력하기',
              icon: 'pi pi-fw pi-print',
              command: handlePrint,
            },
            {
              label: 'PDF 다운로드',
              icon: 'pi pi-fw pi-download',
              command: handlePDF,
            },
          ]}
          start={
            <label className="text-2xl font-semibold mr-2">
              성능점검기록부
            </label>
          }
        />
        <div className="flex flex-row align-items-center justify-content-center py-3">
          {checkData && (
            <PerformanceCheckReport
              ref={reportRef}
              data={checkData}
              photos={checkPhotos}
              payment={_.get(data, 'paymentInformation')}
              association={_.get(data, 'association')}
              inspector={_.get(data, 'inspector')}
              customerCompany={_.get(data, 'customerCompany')}
              vehicleInformation={_.get(data, 'vehicleInformation')}
            />
          )}
        </div>
      </BlockUI>
      {/*{_.map(reportCaptureData, data => (<img src={data} style={{width: '210mm'}} />))}*/}
    </div>
  );
};

export default PerformanceCheckViewer;
