import axios from 'axios';

export const NodeService = (function () {
  let instance;
  const BASE_URI = 'assets/demo/data';

  function init() {
    return {
      getTreeNodes: async function () {
        const {
          data: { root },
        } = await axios.get(BASE_URI + '/treenodes.json');
        return root;
      },
      getTreeTableNodes: async function () {
        const {
          data: { root },
        } = await axios.get(BASE_URI + '/treetablenodes.json');
        return root;
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();
