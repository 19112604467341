import { Button } from 'primereact/button';
import React from 'react';
import { classNames } from 'primereact/utils';

export const GroupedButton = ({ title, items, value, onChange }) => {
  return (
    <div className="grid">
      <div className="col-12 lg:col-4 py-1 lg:py-0">
        <span
          className="p-inputgroup-addon"
          style={{
            wordBreak: 'keep-all',
            borderTopRightRadius: 3,
            borderBottomRightRadius: 3,
            borderRight: '1px solid #ced4da',
          }}
        >
          {title}
        </span>
      </div>
      <div className="col-12 lg:col-8 py-1 lg:py-0">
        <div className="p-buttonset">
          {items.map((item, idx) => (
            <Button
              key={`GB_${idx}`}
              label={item.label}
              // icon={`pi pi-${item.icon}`}
              className={classNames('', {
                // 'w-6': items.length === 2,
                // 'w-4': items.length === 3,
                'bg-white text-700 border-700': value !== item.value,
                [item.active]: value === item.value,
              })}
              onClick={(e) => onChange(item.value)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
