import AxiosUtil from '../utils/axiosUtil';

export const ShopInsurancePriceService = (function () {
  let instance;
  const BASE_URI = '/apis/insurance-prices/shop-tables';

  function init() {
    return {
      list: async function (params) {
        const queryString = Object.entries(params)
          .map((e) => e.join('='))
          .join('&');
        return await AxiosUtil.Async.get(BASE_URI, queryString);
      },
      checkStartDate: async function (shopId = 0, insuranceCompanyCode = '') {
        return await AxiosUtil.Async.get(
          BASE_URI + '/checker/start-date',
          `sid=${shopId}&icc=${insuranceCompanyCode}`
        );
      },
      register: async function (data) {
        return await AxiosUtil.Async.post(BASE_URI, data);
      },
      getData: async function (priceTableId = 0) {
        return await AxiosUtil.Async.get(BASE_URI + `/${priceTableId}`);
      },
      delete: async function (priceTableId = 0) {
        return await AxiosUtil.Async.delete(BASE_URI + `/${priceTableId}`);
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();
