import AxiosUtil from '../utils/axiosUtil';

export const CodeService = (function () {
  let instance;
  const BASE_URI = '/apis/codes';

  function init() {
    return {
      insurances: async function () {
        return await AxiosUtil.Async.get(BASE_URI + '/insurances');
      },
      enterpriseTypes: async function () {
        return await AxiosUtil.Async.get(
          BASE_URI + '/common',
          'of=EnterpriseType'
        );
      },
      userRoles: async function (associationId, enterpriseId, shopId) {
        const queryString = `aid=${associationId}&eid=${enterpriseId}&sid=${shopId}`;
        return await AxiosUtil.Async.get(BASE_URI + '/user-roles', queryString);
      },
      associations: async function () {
        return await AxiosUtil.Async.get(BASE_URI + '/associations');
      },
      enterprises: async function (associationIds = []) {
        const queryString = `aids=${Array.from(associationIds).join(',')}`;
        return await AxiosUtil.Async.get(
          BASE_URI + '/enterprises',
          queryString
        );
      },
      shops: async function (enterpriseIds = []) {
        const queryString = `eids=${Array.from(enterpriseIds).join(',')}`;
        return await AxiosUtil.Async.get(BASE_URI + '/shops', queryString);
      },
      users: async function (shopIds = []) {
        const queryString = `sids=${Array.from(shopIds).join(',')}`;
        return await AxiosUtil.Async.get(BASE_URI + '/users', queryString);
      },
      user: async function (userId = 0) {
        return await AxiosUtil.Async.get(`/apis/codes/users/${userId}`);
      },
      unions: async function () {
        return await AxiosUtil.Async.get(BASE_URI + '/unions');
      },
      complexes: async function (unionId = '') {
        const queryString = `uid=${unionId}`;
        return await AxiosUtil.Async.get(BASE_URI + '/complexes', queryString);
      },
      customerCompanies: async function (
        associationId = '',
        enterpriseId = '',
        shopId = ''
      ) {
        const queryString = `aid=${associationId}&eid=${enterpriseId}&sid=${shopId}`;
        return await AxiosUtil.Async.get(
          BASE_URI + '/customer-companies',
          queryString
        );
      },
      customers: async function (customerCompanyId = '') {
        const queryString = `ccid=${customerCompanyId}`;
        return await AxiosUtil.Async.get(BASE_URI + '/customers', queryString);
      },
      baseInsurancePriceTables: async function (enterpriseId = 0) {
        if (enterpriseId === 0) return;
        const queryString = `eid=${enterpriseId}`;
        return await AxiosUtil.Async.get(
          BASE_URI + '/insurance-prices/base-tables',
          queryString
        );
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();
