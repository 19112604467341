import React, { useEffect, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';
import { InputTextarea } from 'primereact/inputtextarea';
import { InputText } from 'primereact/inputtext';
import { CommonUtil } from '../../utils/commonUtil';
import { Dropdown } from 'primereact/dropdown';
import { useRecoilValueLoadable } from 'recoil';
import { myInfoSelector } from '../../recoil/selectors';
import { Checkbox } from 'primereact/checkbox';
import { RadioButton } from 'primereact/radiobutton';

const SendSMS = ({
  onHide,
  smsData,
  setSmsData,
  customerInfo,
  showSmsType,
  postSms,
}) => {
  const myInfoLoadable = useRecoilValueLoadable(myInfoSelector);
  const aid = myInfoLoadable.contents.associationInfo?.associationId ?? '18';

  const makeContent = (type, customerInfo, partnerInfo, addDocs) => {
    let str = '';
    if (addDocs) {
      str = addDocs
        .map((el) => '- ' + el)
        .toString()
        .replace(/,/g, '\n');
    }

    switch (type) {
      case 'REPORT':
        setSmsData({
          ...smsData,
          url: '',
          contents: `[성능점검 안내]\n\n안녕하세요?\n${customerInfo?.shop.shopName} 입니다.\n성능점검이 완료되었습니다.\n\n- 차량번호: ${customerInfo?.licensePlateNumber}\n- 성능점검번호: ${customerInfo?.PRFOMNC_CHCK_NO}\n\n성능점검에 최선을 다하겠습니다.\n감사합니다.`,
        });
        break;
      case 'APP_DOWNLOAD':
        setSmsData({
          ...smsData,
          msgType: 'lms',
          url: '',
          contents: `${customerInfo?.carNbr} 고객님, 안녕하세요. 성능접수를 위해 전달 드리는 링크를 통해 APP 을 다운로드 받으신 후 차량정보 및 서류를 업로드해 주세요.\r\n\r\n 감사합니다.`,
        });
        break;
      case 'REJECTION':
        setSmsData({
          ...smsData,
          destAddr: `${customerInfo.customerContact}`,
          msgType: 'at',
          msgSubType: 'TPA06',
          contents: `${
            customerInfo?.carNbr
          } 고객님, 안녕하세요. 자동차성능·상태점검책임보험의 보장기간이 경과하여 보증이 불가하여 안내 드립니다. 자세한 내용은 아래를 참고 부탁 드립니다.\n${
            !smsData.warrnaty.day ? '\n- 인도일자로부터 30일 경과' : ''
          }\n${
            !smsData.warrnaty.distance ? '- 주행거리 2,000Km 초과\n' : ''
          }\n*자동차성능·상태점검책임보험의 보장기간\n- 자동차 인도일로부터 30일 또는 주행거리 2천킬로미터 이내 중 먼저 도래한 기준 이내\n\n감사합니다.`,
        });
        break;
      case 'CUSTOMER_DOCS':
        setSmsData({
          ...smsData,
          msgType: 'at',
          msgSubType: 'TPA04',
          subject: smsData.subject ? smsData.subject : null,
          destAddr: `${customerInfo.customerContact}`,
          contents: `${customerInfo?.carNbr} 고객님, 안녕하세요.\n고객님께서 제출하신 서류를 검토하던 중, 일부 서류에 보완이 필요하여 다시 제출해 주시기를 부탁드리게 되었습니다.\n다시 제출이 필요한 서류는 다음과 같습니다.\n\n${str}\n\n감사합니다.`,
        });
        break;
      case 'INFO_TO_CUSTOMER':
        setSmsData({
          ...smsData,
          msgType: 'at',
          msgSubType: 'TPA03',
          subject: smsData.subject ? smsData.subject : null,
          destAddr: `${customerInfo.customerContact}`,
          contents: `${
            customerInfo?.carNbr
          } 고객님, 안녕하세요. 방문하실 진단점 정보 안내 드립니다.\n\n- 진단점명 : ${
            partnerInfo?.partnerName ?? ''
          }\n- 연락처 : ${partnerInfo?.cellphone ?? ''}\n- 위치 : ${
            partnerInfo?.address ?? ''
          }\n\n감사합니다.`,
        });
        break;
      case 'INFO_TO_PARTNER':
        setSmsData({
          ...smsData,
          msgType: 'at',
          msgSubType: 'TPA05',
          subject: '사진 업로드 하기',
          destAddr: `${partnerInfo?.cellphone}`,
          contents: `안녕하세요. 방문예정 고객님의 정보를 전달 드립니다. 함께 전달 드리는 URL 을 통해 진단 사진을 업로드해주세요.\r\n\r\n- 차량번호 : ${
            customerInfo?.carNbr
          }\r\n- 연락처 : ${CommonUtil.Formatter.phone(
            customerInfo.customerContact
          )}\r\n\r\n감사합니다.`,
          url: `${process.env.REACT_APP_BASE_URL}/upload/claim/aI=${aid}&carNum=${customerInfo?.carNbr}&cusN=${customerInfo?.customerName}&cusC=${customerInfo?.customerContact}&dealer=false&partner=true&pId=${partnerInfo?.partnerId}`,
        });
        break;
      case 'PARTNER_UPLOAD_URL':
        setSmsData({
          ...smsData,
          msgType: 'at',
          msgSubType: 'TPA07',
          subject: '사진 업로드 하기',
          contents: `안녕하세요. 전달 드리는 URL 을 통해 수리 사진을 업로드해주세요.\n\n- 차량번호 : ${customerInfo?.carNbr}\n\n감사합니다.`,
          url: `${process.env.REACT_APP_BASE_URL}/upload/claim/aI=${aid}&carNum=${customerInfo?.carNbr}&cusN=${customerInfo?.customerName}&cusC=${customerInfo?.customerContact}&dealer=false&partner=true&pId=${partnerInfo?.partnerId}`,
        });
        break;
      case 'DEALER_UPLOAD_URL':
        setSmsData({
          ...smsData,
          msgType: 'at',
          msgSubType: 'TPA02',
          subject: '서류 업로드 하기',
          contents: `안녕하세요. 전달 드리는 URL 을 통해 서류를 업로드해주세요.\n\n- 차량번호 : ${
            customerInfo.carNbr
          }\n- 연락처 : ${CommonUtil.Formatter.phone(
            customerInfo.customerContact
          )}\n\n[필요서류]\n${str}\n\n감사합니다.`,
          url: `${process.env.REACT_APP_BASE_URL}/upload/claim/aI=${aid}&carNum=${customerInfo.carNbr}&cusN=${customerInfo.customerName}&cusC=${customerInfo.customerContact}&dealer=true&partner=false&pId=${partnerInfo.partnerId}`,
        });
        break;
      default:
    }
  };

  const [partnerList, setPartnerList] = useState([]);
  const [partnerInfo, setPartnerInfo] = useState(null);
  const [addDocs, setAddDocs] = useState([]);

  const onAddDocsChange = (e) => {
    let _addDocs = [...addDocs];
    if (e.checked) _addDocs.push(e.value);
    else _addDocs.splice(_addDocs.indexOf(e.value), 1);
    setAddDocs(_addDocs);
  };

  useEffect(() => {
    if (!!aid && showSmsType && addDocs) {
      makeContent(showSmsType, customerInfo, null, addDocs);
    }
  }, [showSmsType, customerInfo, aid, addDocs]);

  useEffect(() => {
    if (smsData.partnerInfo) {
      // console.log(smsData.partnerInfo);
      setPartnerList(smsData.partnerInfo);
    }
  }, [smsData]);

  useEffect(() => {
    if (showSmsType && !!partnerInfo) {
      makeContent(showSmsType, customerInfo, partnerInfo);
    }
  }, [showSmsType, customerInfo, partnerInfo]);

  useEffect(() => {
    if (smsData.msgType === 'at') {
      makeContent(showSmsType, customerInfo, partnerInfo, addDocs);
    }
  }, [smsData.msgType]);

  return (
    <Dialog
      onHide={onHide}
      modal
      visible
      style={{ width: '340px' }}
      header={'메세지 전송 - ' + SMSType[showSmsType].title}
      footer={() => (
        <div className="flex flex-auto align-items-start justify-content-center pt-2">
          <Button
            label="전송하기"
            icon="pi pi-check-circle"
            className=" mr-1"
            onClick={() => postSms(SMSType[showSmsType].title)}
          />
        </div>
      )}
    >
      {showSmsType === 'REPORT' ? null : (
        <>
          <div className="">고객명 : {customerInfo?.customerName}</div>
          <div className="my-2">
            연락처 : {CommonUtil.Formatter.phone(customerInfo?.customerContact)}
          </div>
          <Divider></Divider>
        </>
      )}
      <div className="flex align-items-center">
        <label>수신번호 :</label>
        <InputText
          value={CommonUtil.Formatter.phone(smsData.destAddr)}
          onChange={(e) => setSmsData({ ...smsData, destAddr: e.target.value })}
          className=" p-inputtext-sm ml-2"
          name="customerContact"
        />
      </div>
      <div className="flex align-items-center mt-1">
        <label>발송번호 :</label>
        <InputText
          value={CommonUtil.Formatter.phone(smsData.sourceAddr)}
          onChange={(e) =>
            setSmsData({ ...smsData, sourceAddr: e.target.value })
          }
          className=" p-inputtext-sm ml-2"
          name="customerContact"
        />
      </div>
      <div className="flex align-items-center mt-1">
        <label>발송방법 :</label>
        <div className="flex align-items-center mt-1">
          <RadioButton
            inputId="at"
            name="customerSearchBy"
            value="at"
            className="mx-2"
            checked={smsData.msgType === 'at'}
            onChange={(e) =>
              setSmsData({ ...smsData, msgType: e.target.value })
            }
          />
          <label htmlFor="at" className="mr-2">
            알림톡
          </label>
          <RadioButton
            inputId="lms"
            name="customerSearchBy"
            value="lms"
            className="mx-2"
            checked={smsData.msgType === 'lms'}
            onChange={(e) =>
              setSmsData({ ...smsData, msgType: e.target.value })
            }
          />
          <label htmlFor="lms">문자</label>
        </div>
      </div>
      <Divider />
      <div>발송내역</div>
      {showSmsType === 'INFO_TO_CUSTOMER' ||
      showSmsType === 'INFO_TO_PARTNER' ? (
        <div className="flex mt-2 align-items-center gap-4">
          <div>진단점 선택</div>
          <Dropdown
            value={partnerInfo}
            onChange={(e) => setPartnerInfo(e.value)}
            options={partnerList}
            optionLabel="partnerName"
            placeholder="진단점을 선택해주세요"
            className="w-13rem"
            emptyMessage="진단점이 없습니다."
          />
        </div>
      ) : null}
      {showSmsType === 'CUSTOMER_DOCS' && (
        <div className="mt-1">
          <div className="flex flex-wrap gap-2">
            {DOC_TYPE_CUSTOMER.map((el, idx) => (
              <div key={idx}>
                <Checkbox
                  inputId={el.value}
                  name={el.value}
                  value={el.label}
                  onChange={(e) => onAddDocsChange(e)}
                  checked={addDocs.includes(el.label)}
                />
                <label htmlFor={el.value} className="mx-2">
                  {el.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
      {showSmsType === 'DEALER_UPLOAD_URL' && (
        <div className="mt-1">
          <div className="flex flex-wrap gap-2">
            {DOC_TYPE_DEALER.map((el, idx) => (
              <div key={idx}>
                <Checkbox
                  inputId={el.value}
                  name={el.value}
                  value={el.label}
                  onChange={(e) => onAddDocsChange(e)}
                  checked={addDocs.includes(el.label)}
                />
                <label htmlFor={el.value} className="mx-2">
                  {el.label}
                </label>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="mt-2">
        <InputTextarea
          disabled={smsData.msgType === 'at'}
          className="w-full mt-1"
          rows={4}
          autoResize
          value={smsData?.contents || ''}
          onChange={({ target: { value } }) =>
            setSmsData({ ...smsData, contents: value })
          }
          readOnly={showSmsType === 'REPORT'}
        />
      </div>
    </Dialog>
  );
};

export default SendSMS;

const SMSType = {
  APP_DOWNLOAD: {
    title: '성능접수 안내',
  },
  REJECTION: {
    title: '보증 거절 안내',
    content: '',
  },
  INFO_TO_CUSTOMER: {
    title: '진단점 정보 안내',
    content: '',
  },
  INFO_TO_PARTNER: {
    title: '진단점 고객정보 안내',
    content: '',
  },
  PARTNER_UPLOAD_URL: {
    title: '수리사진 업로드 안내',
    content: '',
  },
  DEALER_UPLOAD_URL: {
    title: '매매상사 서류 업로드 안내',
  },
  CUSTOMER_DOCS: {
    title: '고객 서류 재요청 안내',
  },
  REPORT: {
    title: '최종고지 리포트(요약)',
  },
};

const DOC_TYPE_CUSTOMER = [
  {
    value: 'C_FRONT',
    label: '차량전면사진',
  },
  {
    value: 'C_DASHBOARD',
    label: '현재 기준 계기판 사진',
  },
  {
    value: 'C_CONTRACT',
    label: '매매계약서',
  },
  {
    value: 'C_CERTIFICATE',
    label: '차량등록증',
  },
  {
    value: 'C_ETC',
    label: '기타',
  },
];

const DOC_TYPE_DEALER = [
  {
    value: 'D_RECORD_1',
    label: '성능기록부',
  },
  {
    value: 'D_CONTRACT',
    label: '전산 매도용 계약서',
  },
  {
    value: 'D_INSURANCE',
    label: '자동차보험 가입증명서',
  },
];
