import axios from 'axios';

export const CountryService = (function () {
  let instance;

  function init() {
    return {
      getCountries: async function () {
        const {
          data: { data },
        } = await axios.get('assets/demo/data/countries.json');
        return data;
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();
