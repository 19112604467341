import { PC_BASE_BLUEPRINT } from './PerformanceCheckConstants';
import performanceCheckUtil from '../utils/performanceCheckUtil';

const MAX_DATE = '9999-12-31';
const YN = { YES: 'Y', NO: 'N' };
Object.freeze(MAX_DATE);
Object.freeze(YN);

const COUNT_PER_PAGE = [5, 10, 20, 30, 50, 100, 500, 1000, 5000, 10000];
const ENTERPRISE_TYPES = [
  { value: 'AD', label: '협회직영' },
  { value: 'FR', label: '프랜차이즈(법인)' },
];
const CHECK_STATES = [
  { value: 'WA', label: '대기' },
  { value: 'TS', label: '임시저장' },
  { value: 'TR', label: '작성완료' },
  { value: 'DE', label: '삭제됨' },
];
const TRANSFER_STATES = [
  { value: 'W', label: '미전송' },
  { value: 'F', label: '전송실패' },
  { value: 'N', label: '최초전송됨' },
  { value: 'U', label: '업데이트됨' },
  { value: 'R', label: '주행거리확인' }, // ?
  { value: 'D', label: '삭제됨' },
];

const PAYMENT_METHOD = [
  // { label: '결제대기', value: '' },
  { label: '신용카드', value: 'CARD' },
  { label: '현금', value: 'CASH' },
  { label: '계좌이체', value: 'TRAN' },
];

/**
 * 수입차량이지만 보험사에서는 국산에 해당하는 차량
 * @value 차량명 CNM
 */
const MADE_AT_D = [
  { label: '쉐보레 임팔라', value: 'IMPALA' },
  { label: '쉐보레 베리타스', value: '베리타스' },
  { label: '쉐보레 이쿼녹스', value: 'EQUINOX' },
  { label: '르노 QM3', value: 'QM3' },
  { label: '르노 클리오', value: 'CLIO' },
  { label: '르노 트위지', value: 'TWIZY' },
];

const VEHICLE_TYPE_LEVEL1 = [
  { label: '국산', value: 'D' },
  { label: '수입', value: 'F' },
];
const VEHICLE_TYPE_LEVEL2 = PC_BASE_BLUEPRINT['VHCTY_ASORT_CODE'].valueItems;
const VEHICLE_TYPE_LEVEL3 = [
  {
    lvl1: 'D',
    lvl2: '1',
    label: '초소형/소형A(1000cc 이하)',
    value: 'SA',
    filter: (displace, maxPerson) => {
      return displace >= 0 && displace <= 1000 && maxPerson < 7;
    },
  },
  {
    lvl1: 'D',
    lvl2: '1',
    label: '소형B(1600cc 이하)',
    value: 'SB',
    filter: (displace, maxPerson) => {
      return displace > 1000 && displace <= 1600 && maxPerson < 7;
    },
  },
  {
    lvl1: 'D',
    lvl2: '1',
    label: '중형(2000cc 이하)',
    value: 'MD',
    filter: (displace, maxPerson) => {
      return displace > 1600 && displace <= 2000 && maxPerson < 7;
    },
  },
  {
    lvl1: 'D',
    lvl2: '1',
    label: '대형(2000cc 초과)',
    value: 'LG',
    filter: (displace, maxPerson) => {
      return displace > 2000 && maxPerson < 7;
    },
  },
  {
    lvl1: 'D',
    lvl2: '1',
    label: '다인승(1,2종/7~10인승 이하)',
    value: 'MP',
    filter: (displace, maxPerson) => {
      // return maxPerson >= 7 && maxPerson <= 10;
      return displace >= 0 && maxPerson >= 7 && maxPerson <= 10;
    },
  },
  {
    lvl1: 'D',
    lvl2: '1',
    label: '전기차/수소차',
    value: 'EH',
    filter: (displace, maxPerson, vehicleInformation) => {
      return (
        performanceCheckUtil.Checker.Fuel.isElectronic(vehicleInformation) ||
        performanceCheckUtil.Checker.Fuel.isHydrogen(vehicleInformation)
      );
    },
  },

  {
    lvl1: 'D',
    lvl2: '2',
    label: '1종(26~35인승 이하)',
    value: '1C',
    filter: (displace, maxPerson) => {
      // return displace || (maxPerson >= 26 && maxPerson <= 35);
      return displace >= 0 && maxPerson >= 26 && maxPerson <= 35;
    },
  },
  {
    lvl1: 'D',
    lvl2: '2',
    label: '2~3종(11~25인승 이하)',
    value: '2C',
    filter: (displace, maxPerson) => {
      // return displace || (maxPerson >= 11 && maxPerson <= 25);
      return displace >= 0 && maxPerson >= 11 && maxPerson <= 25;
    },
  },
  {
    lvl1: 'D',
    lvl2: '2',
    label: '경승합(10인 이하 & 1000cc 미만)',
    value: 'LM',
    filter: (displace, maxPerson) => {
      return displace < 1000 && maxPerson <= 10;
    },
  },
  {
    lvl1: 'D',
    lvl2: '2',
    label: '전기차/수소차',
    value: 'EH',
    filter: (displace, maxPerson, vehicleInformation) => {
      return (
        performanceCheckUtil.Checker.Fuel.isElectronic(vehicleInformation) ||
        performanceCheckUtil.Checker.Fuel.isHydrogen(vehicleInformation)
      );
    },
  },

  {
    lvl1: 'D',
    lvl2: '3',
    label: '2종(2.5톤 초과 5톤 이하)',
    value: '2C',
    filter: () => {
      return true;
    },
  },
  {
    lvl1: 'D',
    lvl2: '3',
    label: '3종(1톤 초과 2.5톤 이하)',
    value: '3C',
    filter: () => {
      return true;
    },
  },

  {
    lvl1: 'D',
    lvl2: '3',
    label: '4종(1톤 이하)',
    value: '4C',
    filter: () => {
      return true;
    },
  },
  {
    lvl1: 'D',
    lvl2: '3',
    label: '경화물',
    value: 'LF',
    filter: () => {
      return true;
    },
  },
  {
    lvl1: 'D',
    lvl2: '3',
    label: '전기차/수소차',
    value: 'EH',
    filter: (displace, maxPerson, vehicleInformation) => {
      return (
        performanceCheckUtil.Checker.Fuel.isElectronic(vehicleInformation) ||
        performanceCheckUtil.Checker.Fuel.isHydrogen(vehicleInformation)
      );
    },
  },

  {
    lvl1: 'D',
    lvl2: '4',
    label: '4종(1톤 이하)',
    value: '4C',
    filter: () => {
      return true;
    },
  },
  {
    lvl1: 'D',
    lvl2: '4',
    label: '경화물',
    value: 'LF',
    filter: () => {
      return true;
    },
  },
  {
    lvl1: 'D',
    lvl2: '4',
    label: '전기차/수소차',
    value: 'EH',
    filter: (displace, maxPerson, vehicleInformation) => {
      return (
        performanceCheckUtil.Checker.Fuel.isElectronic(vehicleInformation) ||
        performanceCheckUtil.Checker.Fuel.isHydrogen(vehicleInformation)
      );
    },
  },

  {
    lvl1: 'F',
    lvl2: '1',
    label: '초소형/소형A,B(1600cc 이하)',
    value: 'SM',
    filter: (displace, maxPerson) => {
      return displace >= 0 && displace <= 1600 && maxPerson < 7;
    },
  },
  {
    lvl1: 'F',
    lvl2: '1',
    label: '중형(2000cc 이하)',
    value: 'MD',
    filter: (displace, maxPerson) => {
      return displace > 1600 && displace <= 2000 && maxPerson < 7;
    },
  },
  {
    lvl1: 'F',
    lvl2: '1',
    label: '대형(2000cc 초과)',
    value: 'LG',
    filter: (displace, maxPerson) => {
      return displace > 2000 && maxPerson < 7;
    },
  },
  {
    lvl1: 'F',
    lvl2: '1',
    label: '다인승(1,2종/7~10인승 이하)',
    value: 'MP',
    filter: (displace, maxPerson) => {
      return displace >= 0 && maxPerson >= 7 && maxPerson <= 10;
    },
  },
  {
    lvl1: 'F',
    lvl2: '1',
    label: '전기차/수소차',
    value: 'EH',
    filter: (displace, maxPerson, vehicleInformation) => {
      return (
        performanceCheckUtil.Checker.Fuel.isElectronic(vehicleInformation) ||
        performanceCheckUtil.Checker.Fuel.isHydrogen(vehicleInformation)
      );
    },
  },

  {
    lvl1: 'F',
    lvl2: '2',
    label: '1종(26~35인승 이하)',
    value: '1C',
    filter: (displace, maxPerson) => {
      return displace >= 0 && maxPerson >= 26 && maxPerson <= 35;
    },
  },
  {
    lvl1: 'F',
    lvl2: '2',
    label: '2~3종(11~25인승 이하)',
    value: '2C',
    filter: (displace, maxPerson) => {
      return displace >= 0 && maxPerson >= 11 && maxPerson <= 25;
    },
  },
  {
    lvl1: 'F',
    lvl2: '2',
    label: '경승합(10인 이하 & 1000cc 미만)',
    value: 'LM',
    filter: (displace, maxPerson) => {
      return displace < 1000 && maxPerson <= 10;
    },
  },
  {
    lvl1: 'F',
    lvl2: '2',
    label: '전기차/수소차',
    value: 'EH',
    filter: (displace, maxPerson, vehicleInformation) => {
      return (
        performanceCheckUtil.Checker.Fuel.isElectronic(vehicleInformation) ||
        performanceCheckUtil.Checker.Fuel.isHydrogen(vehicleInformation)
      );
    },
  },

  {
    lvl1: 'F',
    lvl2: '3',
    label: '2종(2.5톤 초과 5톤 이하)',
    value: '2C',
    filter: () => {
      return true;
    },
  },
  {
    lvl1: 'F',
    lvl2: '3',
    label: '3종(1톤 초과 2.5톤 이하)',
    value: '3C',
    filter: () => {
      return true;
    },
  },

  {
    lvl1: 'F',
    lvl2: '3',
    label: '4종(1톤 이하)',
    value: '4C',
    filter: () => {
      return true;
    },
  },
  {
    lvl1: 'F',
    lvl2: '3',
    label: '경화물',
    value: 'LF',
    filter: () => {
      return true;
    },
  },
  {
    lvl1: 'F',
    lvl2: '3',
    label: '전기차/수소차',
    value: 'EH',
    filter: (displace, maxPerson, vehicleInformation) => {
      return (
        performanceCheckUtil.Checker.Fuel.isElectronic(vehicleInformation) ||
        performanceCheckUtil.Checker.Fuel.isHydrogen(vehicleInformation)
      );
    },
  },

  {
    lvl1: 'F',
    lvl2: '4',
    label: '4종(1톤 이하)',
    value: '4C',
    filter: () => {
      return true;
    },
  },
  {
    lvl1: 'F',
    lvl2: '4',
    label: '경화물',
    value: 'LF',
    filter: () => {
      return true;
    },
  },
  {
    lvl1: 'F',
    lvl2: '4',
    label: '전기차/수소차',
    value: 'EH',
    filter: (displace, maxPerson, vehicleInformation) => {
      return (
        performanceCheckUtil.Checker.Fuel.isElectronic(vehicleInformation) ||
        performanceCheckUtil.Checker.Fuel.isHydrogen(vehicleInformation)
      );
    },
  },
];

const REPORT_TYPE = {
  PERFORMANCE_CHECK_REPORT: {
    api: '/apis/report/performance-check',
  },
  INSURANCE_CERTIFICATE: {
    api: '/apis/report/insurance-certificate',
  },
};

const MESSAGE_STATUS = {
  READY: 'ready',
};

export {
  MAX_DATE,
  YN,
  COUNT_PER_PAGE,
  ENTERPRISE_TYPES,
  CHECK_STATES,
  TRANSFER_STATES,
  VEHICLE_TYPE_LEVEL1,
  VEHICLE_TYPE_LEVEL2,
  VEHICLE_TYPE_LEVEL3,
  REPORT_TYPE,
  MESSAGE_STATUS,
  PAYMENT_METHOD,
  MADE_AT_D,
};
