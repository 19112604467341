import AxiosUtil from '../utils/axiosUtil';
import { BaseService } from './BaseService';

export const BaseInsurancePriceService = (function () {
  let instance;
  const BASE_URI = '/apis/insurance-prices/base-tables';
  const baseService = BaseService.getInstance(BASE_URI, 'baseInsurancePrice');

  function init() {
    return {
      ...baseService,
      list: async function (params) {
        const queryString = Object.entries(params)
          .map((e) => e.join('='))
          .join('&');
        return await AxiosUtil.Async.get(BASE_URI, queryString);
      },
      checkStartDate: async function (
        enterpriseId = 0,
        insuranceCompanyCode = ''
      ) {
        return await AxiosUtil.Async.get(
          BASE_URI + '/checker/start-date',
          `eid=${enterpriseId}&icc=${insuranceCompanyCode}`
        );
      },
      register: async function (data) {
        return await AxiosUtil.Async.post(BASE_URI, data);
      },
      getData: async function (priceTableId = 0) {
        return await AxiosUtil.Async.get(BASE_URI + `/${priceTableId}`);
      },
      delete: async function (priceTableId = 0) {
        return await AxiosUtil.Async.delete(BASE_URI + `/${priceTableId}`);
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();
