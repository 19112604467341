import * as Controlled from './../../../components/Atoms/Controlled';
import * as Modal from './../../../components/Atoms/Modal';
import * as Titled from './../../../components/Atoms/Titled';

import { Controller, useForm, useWatch } from 'react-hook-form';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Badge } from 'primereact/badge';
import { Button } from 'primereact/button';
import { CommonUtil } from '../../../utils/commonUtil';
import FormUtil from '../../../utils/formUtil';
import { Image } from 'primereact/image';
import { MyInfoUtil } from '../../../utils/myInfoUtil';
import { Panel } from 'primereact/panel';
import { ServiceProvider } from '../../../services';
import UserRoleType from '../../../enums/UserRoleType';
import _ from 'lodash';
import { useDropzone } from 'react-dropzone';
import { useFormValid } from '../../../hooks/useFormValid';

const associationService = ServiceProvider.association;

//* form 생성에 필요한 input data
const formField = [
  {
    code: 'agentCode',
    title: '연계기관코드',
    type: 'code',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'associationDetailInfo',
  },
  {
    code: 'associationCode',
    title: '협회 코드',
    type: 'code',
    defaultValue: '',
    required: true,
    pattern: true,
  },
  {
    code: 'associationName',
    title: '협회명',
    type: 'korean',
    defaultValue: '',
    required: true,
    pattern: false,
    category: 'associationBasicInfo',
  },
  {
    code: 'entrpsId',
    title: '법인 등록 번호',
    type: 'corporateBiz',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'associationBasicInfo',
  },
  // {
  //   code: 'businessRegistrationNumber',
  //   title: '사업자 등록 번호',
  //   type: 'biz',
  //   defaultValue: '',
  //   required: true,
  //   pattern: true,
  //   category: 'associationBasicInfo',
  // },
  {
    code: 'loginId',
    title: '로그인 아이디',
    type: 'login',
    defaultValue: '',
    required: false,
    pattern: true,
  },
  {
    code: 'representative',
    title: '대표자명',
    type: 'korean',
    defaultValue: '',
    required: true,
    pattern: false,
    category: 'associationBasicContact',
  },
  {
    code: 'mainPhone',
    title: '대표전화',
    type: 'phone',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'associationBasicContact',
  },
  {
    code: 'fax',
    title: '팩스',
    type: 'fax',
    defaultValue: '',
    required: false,
    pattern: true,
    category: 'associationBasicContact',
  },
  {
    code: 'password',
    title: '패스워드',
    type: 'password',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'password',
  },
  {
    code: 'passwordConfirmation',
    title: '패스워드 확인',
    type: 'password',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'password',
  },
  {
    code: 'personInCharge',
    title: '담당자',
    type: 'korean',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'picInfo',
  },
  {
    code: 'picContact',
    title: '담당자 연락처',
    // type: 'cellphone',
    type: 'phone',
    defaultValue: '',
    required: true,
    pattern: true,
    category: 'picInfo',
  },
  {
    code: 'picEmail',
    title: '담당자 이메일',
    type: 'email',
    defaultValue: '',
    required: false,
    pattern: true,
    category: 'picInfo',
  },
  {
    code: 'statementNumberPrefix',
    title: '성능점검번호 구분코드',
    type: 'text',
    defaultValue: '',
    required: true,
    pattern: true,
  },
  {
    code: 'useYn',
    title: '1. 협회 정보',
    type: 'boolean',
    defaultValue: 'Y',
    required: true,
    pattern: false,
  },
];

export const AssociationDialog = ({
  userInfo,
  associationId = 0,
  onHide = null,
}) => {
  const {
    myAssociationId,
    myEnterpriseId,
    myShopId,
    myUserId,
    myRoleCode,
    myUserPosition,
  } = useMemo(() => MyInfoUtil.unpack(userInfo), [userInfo]);

  const getDefaultValues = useCallback((formField) => {
    const defaultValues = {};

    formField.forEach((item) => {
      defaultValues[item.code] = item.defaultValue;
    });

    return defaultValues;
  }, []);

  const {
    control,
    formState,
    trigger,
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    resetField,
  } = useForm({
    defaultValues: getDefaultValues(formField),
    reValidateMode: 'onSubmit',
  });
  const isModified = associationId > 0;
  const currentValues = useWatch({ control });
  const { isFormComplete } = useFormValid(watch(), formField, isModified);

  /* 다이얼로그 진입시 id가 있으면 수정 || 없으면 등록 */
  const [codes, setCodes] = useState({});
  const [associationData, setAssociationData] = useState(null);

  const [loading, setLoading] = useState(false);
  const [isAvailable, setIsAvailable] = useState({
    associationCode: false,
    loginId: false,
  });

  const [needReset, setNeedReset] = useState(false);

  const [officialSeals, setOfficialSeals] = useState(null);

  const [preventAutofillInputs, setPreventAutofillInputs] = useState(true);

  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: {
      'image/*': [],
    },
    onDrop: (acceptedFiles) => {
      acceptedFiles.map((file) =>
        Object.assign(file, { preview: URL.createObjectURL(file) })
      );
      setOfficialSeals(acceptedFiles);
    },
  });

  async function getAvailableStatementNumberPrefixes({
    preSelectedPrefix,
  } = {}) {
    const prefixes = [];

    const { data, error } =
      await associationService.getAvailableStatementNumberPrefixes();
    prefixes.push(...data);

    const prefix = preSelectedPrefix
      ? { value: preSelectedPrefix }
      : _.get(prefixes, _.findIndex(prefixes, { isOccupied: false }));
    return [prefixes, _.get(prefix, 'value'), prefix];
  }

  const getFilteredInputData = (formField, category) => {
    return formField
      .filter((item) => item.category === category)
      .map((filtered) => {
        const { code, title, type, defaultValue, required, pattern } = filtered;
        return {
          code,
          title,
          type,
          defaultValue,
          required,
          pattern,
        };
      });
  };

  function fillEmptyField(data) {
    const copy = { ...data };
    for (let field in copy) {
      copy[field] = copy[field] ?? '';
    }

    return copy;
  }

  function getOfficialSealPath(officialSeals, uploadedOfficialSealPath) {
    // 유저가 업로드한 직인 이미지들이 있으면
    if (officialSeals !== null) {
      return officialSeals[0].preview;
    }

    // 조회한 협회 정보에 officialPath가 있으면
    if (uploadedOfficialSealPath) {
      if (_.startsWith(uploadedOfficialSealPath, '/')) {
        // /로 시작하는 url -> S3
        return process.env.REACT_APP_S3_BASE_URL + uploadedOfficialSealPath;
      } else {
        return uploadedOfficialSealPath;
      }
    }

    return '';
  }

  function setFormattedData(data) {
    const formatter = {
      businessRegistrationNumber: 'biz',
      entrpsId: 'corporateBiz',
      mainPhone: 'phone',
      fax: 'fax',
      cellphone: 'cellphone',
      picContact: 'phone',
    };

    for (let field in data) {
      if (formatter[field] && data[field]) {
        setValue(
          field,
          CommonUtil.Formatter[formatter[field]](_.get(data, field))
        );
      }
    }
  }

  function checkUserAuth(userInfo, type) {
    const { roleCode } = userInfo;
    const ACCESS_PERMISSION = {
      DELETE: _.filter(UserRoleType, function (r) {
        return r.value === 'CERP_ADM';
      }),
      DISABLED: _.filter(UserRoleType, function (r) {
        return r.value === 'CERP_ADM';
      }),
    };

    return _.findIndex(ACCESS_PERMISSION[type], { value: roleCode }) >= 0;
  }

  useEffect(() => {
    (async () => {
      setLoading(true);

      if (!isModified) {
        const [prefixes, prefix] = await getAvailableStatementNumberPrefixes();

        setCodes((ps) => ({ ...ps, prefixes }));
        setValue('statementNumberPrefix', prefix);

        setValue('loginId', '');
        setValue('password', '');
      } else {
        try {
          const { data } = await associationService.getData(associationId);
          const [prefixes, prefix] = await getAvailableStatementNumberPrefixes({
            preSelectedPrefix: data?.statementNumberPrefix,
          });

          setCodes((ps) => ({ ...ps, prefixes }));
          // setValue('statementNumberPrefix', prefix);
          setAssociationData(data);
          setFormattedData(data);
        } catch (error) {
          window.cerp.dialog.error(
            '협회 정보 조회 실패',
            `[${error?.code}] ${error?.message}`
          );
        }
      }

      setLoading(false);
    })();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [associationId, isModified, reset, setValue]);

  useEffect(() => {
    if (needReset) {
      setIsAvailable({ associationCode: false, loginId: false });
      setOfficialSeals(null);
      reset(getDefaultValues(formField));

      setNeedReset(false);
    }
  }, [getDefaultValues, needReset, reset]);

  useEffect(() => {
    if (associationData !== null) {
      setIsAvailable({ associationCode: true, loginId: true });
      reset(fillEmptyField(associationData));
    }
  }, [associationData, reset]);

  useEffect(() => {
    setTimeout(() => {
      setPreventAutofillInputs(false);
    }, 300);
  }, []);

  return (
    <Modal.Form
      title={'협회'}
      childDataName={'점검업체, 점검장, 사용자'}
      loading={loading}
      isModified={isModified}
      onHide={onHide}
      onDeleteConfirm={async () => {
        try {
          const {
            data: { association },
          } = await associationService.delete(associationId);

          window.cerp.toast.success(
            '협회 삭제 완료',
            `[${association.associationName}] 협회가 삭제되었습니다.`
          );

          onHide();
        } catch (error) {
          window.cerp.dialog.error(
            '협회 삭제 실패',
            `[${error?.code}] ${error?.message}`
          );
        }
      }}
      onSubmitConfirm={handleSubmit((data) => {
        // console.log('submit data', data);
        FormUtil.Async.onSubmit(
          { formData: data, files: officialSeals },
          isModified,
          'association',
          '협회',
          setNeedReset
        );
      })}
      saveBtnEnable={
        !(isAvailable.associationCode && isAvailable.loginId && isFormComplete)
      }
      deleteBtnVisible={checkUserAuth(userInfo, 'DELETE')}
    >
      <form autoComplete="off">
        <Panel
          headerTemplate={
            <div className="p-panel-header">
              {isModified && checkUserAuth(userInfo, 'DISABLED') ? (
                <Controlled.InputSwitch
                  control={control}
                  watch={watch}
                  inputData={{
                    // inputLabel: '1. 협회 정보',
                    inputLabel: formField.filter(
                      (item) => item.code === 'useYn'
                    )[0].title,
                    toggleLabel: {
                      trueLabel: '사용',
                      falseLabel: '미사용',
                    },
                    dataLabel: 'useYn',
                  }}
                  styleClass={'font-semibold'}
                />
              ) : (
                <span className="font-semibold">1. 협회 정보</span>
              )}
            </div>
          }
        >
          <div className="grid">
            <div className="col-12 sm:col-6 lg:col-4">
              <Controlled.ValidateInputText
                id={associationId}
                control={control}
                trigger={trigger}
                getValues={getValues}
                isAvailable={isAvailable}
                setIsAvailable={setIsAvailable}
                setValue={setValue}
                inputData={{
                  // inputLabel: '협회 코드',
                  inputLabel: formField.filter(
                    (item) => item.code === 'associationCode'
                  )[0].title,
                  dataLabel: 'associationCode',
                }}
                inputConfig={{
                  minLength: 4,
                  maxLength: 4,
                  placeholder: '영문 4자 (예시. XXXX)',
                }}
                rules={{
                  required: '필수 입력 항목입니다',
                  pattern: {
                    value: CommonUtil.Pattern['code'],
                    message: '유효하지 않은 포맷입니다.',
                  },
                  minLength: {
                    value: 4,
                    message: '코드는 4자리로 구성됩니다.',
                  },
                  maxLength: {
                    value: 4,
                    message: '코드는 4자리로 구성됩니다.',
                  },
                }}
              />
            </div>

            {getFilteredInputData(formField, 'associationBasicInfo').map(
              (item, idx) => {
                return (
                  <div
                    key={`col_${item.code}`}
                    className="col-12 sm:col-6 lg:col-4"
                  >
                    <Controlled.InputText
                      item={item}
                      key={idx}
                      control={control}
                      setValue={setValue}
                    />
                  </div>
                );
              }
            )}

            {getFilteredInputData(formField, 'associationBasicContact').map(
              (item, idx) => (
                <div
                  key={`col_${item.code}`}
                  className="col-12 sm:col-6 lg:col-3"
                >
                  <Controlled.InputText
                    item={item}
                    key={idx}
                    control={control}
                    setValue={setValue}
                  />
                </div>
              )
            )}

            <div className="col-12 sm:col-6 lg:col-3">
              <div className="field m-0">
                <label>직인</label>
                <div className="cm-stamp bg-transparent flex flex-auto align-items-start justify-content-start gap-2">
                  <Image
                    src={getOfficialSealPath(
                      officialSeals,
                      _.get(currentValues, 'officialSealPath')
                    )}
                    alt={officialSeals !== null ? officialSeals[0].name : ''}
                    preview={officialSeals !== null}
                    imageClassName="border-round border-1 border-200"
                  />
                  <div
                    {...getRootProps({
                      className: 'w-full',
                      style: { wordBreak: 'keep-all' },
                    })}
                  >
                    <input {...getInputProps()} />
                    <Button
                      type="button"
                      label="이미지 선택"
                      icon="pi pi-image"
                      className="w-full p-button-outlined"
                    />
                  </div>
                </div>
              </div>
            </div>

            {getFilteredInputData(formField, 'associationDetailInfo').map(
              (item, idx) => (
                <div key={`col_${item.code}`} className="col-6">
                  <Controlled.InputText
                    item={item}
                    key={idx}
                    control={control}
                    setValue={setValue}
                  />
                </div>
              )
            )}

            <div className="col-6">
              <Controller
                control={control}
                name={
                  formField.filter(
                    (item) => item.code === 'statementNumberPrefix'
                  )[0].code
                }
                defaultValue=""
                rules={{
                  required: '필수 입력 항목입니다.',
                  pattern: {
                    value: CommonUtil.Pattern['statementNumber'],
                    message: '유효하지 않은 포맷입니다.',
                  },
                }}
                render={({ field, fieldState, formState }) => (
                  <Titled.Dropdown
                    id={field.name}
                    title={
                      formField.filter(
                        (item) => item.code === 'statementNumberPrefix'
                      )[0].title
                    }
                    required
                    {...field}
                    options={_.get(codes, 'prefixes')}
                    optionDisabled={(opt) =>
                      _.get(opt, 'isOccupied') &&
                      _.get(opt, 'associationId') !==
                        _.get(currentValues, 'associationId')
                    }
                    optionTemplate={({
                      label,
                      value,
                      isOccupied,
                      associationId,
                      associationCode,
                      associationName,
                    }) => (
                      <div className="w-full flex flex-row align-items-center justify-content-between gap-1">
                        <div>{label}</div>
                        {isOccupied && (
                          <Badge
                            className="border-round-xs"
                            {...(associationId ===
                              _.get(currentValues, 'associationId') && {
                              severity: 'danger',
                            })}
                            value={`[${associationCode}] ${associationName}`}
                          />
                        )}
                      </div>
                    )}
                    readOnly={isModified}
                    disabled={isModified}
                    error={fieldState.error}
                  />
                )}
              />
            </div>
          </div>
        </Panel>

        <Panel header="2. 담당자 정보" className="mt-3">
          <div className="grid">
            {getFilteredInputData(formField, 'picInfo').map((item, idx) => (
              <div
                key={`col_${item.code}`}
                className="col-12 sm:col-6 lg:col-3"
              >
                <Controlled.InputText
                  item={item}
                  key={idx}
                  control={control}
                  setValue={setValue}
                />
              </div>
            ))}
          </div>
        </Panel>

        <Panel header="3. 마스터 계정 정보" className="pt-3">
          <div className="grid">
            <div className="col-12 sm:col-6 lg:col-3">
              <Controlled.ValidateInputText
                id={associationId}
                control={control}
                trigger={trigger}
                getValues={getValues}
                isAvailable={isAvailable}
                setIsAvailable={setIsAvailable}
                setValue={setValue}
                disabled={preventAutofillInputs}
                inputData={{
                  inputLabel: formField.filter(
                    (item) => item.code === 'loginId'
                  )[0].title,
                  dataLabel: formField.filter(
                    (item) => item.code === 'loginId'
                  )[0].code,
                }}
                inputConfig={{
                  placeholder: '소문자 및 숫자만 사용할 수 있습니다.',
                  autoComplete: 'off',
                }}
                rules={{
                  required: '필수 입력 항목입니다.',
                  // pattern: {
                  //   value: CommonUtil.Pattern['login'],
                  //   message: '유효하지 않은 포맷입니다.',
                  // },
                }}
              />
            </div>

            {!isModified &&
              getFilteredInputData(formField, 'password').map((item, idx) => (
                <div
                  key={`col_${item.code}`}
                  className="col-12 sm:col-6 lg:col-3"
                >
                  <Controlled.InputPassword
                    item={item}
                    key={idx}
                    disabled={preventAutofillInputs}
                    control={control}
                    setValue={setValue}
                    getValues={getValues}
                    inputConfig={{
                      autoComplete: 'off',
                    }}
                  />
                </div>
              ))}
          </div>
        </Panel>
      </form>
    </Modal.Form>
  );
};
