import { Button } from 'primereact/button';
import React from 'react';

export const SaveButton = ({ onSave, disabled }) => {
  return (
    <Button
      id="btn-save"
      label="저장"
      icon="pi pi-save"
      autoFocus
      disabled={disabled}
      onClick={onSave}
    />
  );
};
