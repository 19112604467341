const TransferState = {
  Wait: 'W',
  Transfered: 'N',
  Updated: 'U',
  TransferReturn: 'R',
  Deleted: 'D',
};

Object.freeze(TransferState);

export default TransferState;
