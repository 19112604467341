import React, { useEffect, useRef } from 'react';
import * as Button from './../../../components/Atoms/Button';
import { Image } from 'primereact/image';
import { CommonUtil } from '../../../utils/commonUtil';

const DocsViewer = ({
  title,
  imgFiles,
  buttonName,
  setCurImg,
  setCurTitle,
  setShowModal,
  curImg,
  deleteFile,
  userType,
  handleClick,
  upload = false,
}) => {
  return (
    <div className="lg:my-1 md:my-2 my-3">
      <div className="flex justify-content-between align-items-center">
        <div className="col font-bold sm:text-base text-2xl  sm:h-5 h-3">
          {title}
        </div>
        <div className="text-base">
          {imgFiles?.length > 0
            ? imgFiles.length + ' 장'
            : !upload
            ? '접수 대기중'
            : '업로드하기'}
        </div>
        <div>
          {imgFiles?.length > 0 && (
            <>
              <Button.Default
                label="보기"
                icon="pi pi-window-maximize"
                className="p-button-text"
                onClick={() => {
                  sessionStorage.setItem('images', JSON.stringify(imgFiles));
                  CommonUtil.Window.popup(
                    '/compensation/accident/claim/viewer',
                    '',
                    window.innerWidth * 0.9,
                    window.innerHeight * 0.9
                  );
                }}
              />
              <Button.Default
                icon={`pi ${
                  curImg === buttonName ? 'pi-angle-up' : 'pi-angle-down'
                }`}
                label=""
                className="p-button-text"
                onClick={() => {
                  curImg === buttonName ? setCurImg('') : setCurImg(buttonName);
                }}
                name={buttonName}
              />
            </>
          )}
        </div>

        <div
          onClick={
            !userType
              ? (e) => {
                  setCurTitle(e.target.id);
                  setShowModal(true);
                }
              : (e) => handleClick(e)
          }
          className="flex justify-content-center cursor-pointer"
        >
          <i
            style={{
              color: 'var(--primary-color)',
              width: '28px',
            }}
            className={`pi ${
              upload ? 'pi-cloud-upload' : 'pi-upload'
            } flex justify-content-center sm:text-base text-xl`}
            id={title}
          />
        </div>
      </div>
      {curImg === buttonName && (
        <div className="col">
          <div className="cursor-pointer flex justify-content-start gap-3 flex-wrap ">
            {imgFiles.map((el, idx) => (
              <span
                className="docs-img flex flex-column align-items-center relative"
                onClick={(e) => deleteFile(e)}
                key={idx}
                id={`${el.imgId || el.file.name}`}
              >
                <img
                  height="30"
                  alt={el.imgId || el.file.name}
                  src={
                    el.filePath
                      ? process.env.REACT_APP_S3_BASE_URL + el.filePath
                      : el.file.preview
                  }
                />
                <i
                  style={{ color: '#d9362b', top: '10px', fontWeight: 'bold' }}
                  className="pi pi-minus-circle absolute"
                  id={`${el.imgId || el.file.name}`}
                />
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DocsViewer;
