const UserRoleType = {
  CERP_ADM: { value: 'CERP_ADM', label: 'ERP관리자', order: 100 },
  A_MST: { value: 'A_MST', label: '협회마스터', order: 200 },
  A_ADM: { value: 'A_ADM', label: '협회관리자', order: 201 },
  A_OFF: { value: 'A_OFF', label: '협회사용자', order: 202 },
  E_ADM: { value: 'E_ADM', label: '성능법인대표', order: 301 },
  E_OFF: { value: 'E_OFF', label: '성능법인사용자', order: 302 },
  C_ADM: { value: 'C_ADM', label: '상사', order: 400 },
  // C_OFF: { value: 'C_OFF', label: '상사사용자', order: 401 },
  // P_MST: { value: 'P_MST', label: '성능개인마스터', order: 500 },
  // P_ADM: { value: 'P_ADM', label: '성능개인대표', order: 501 },
  // P_OFF: { value: 'P_OFF', label: '성능개인사용자', order: 502 },
  // S_MST: { value: 'S_MST', label: '성능책임자', order: 600 },
  S_ADM: { value: 'S_ADM', label: '성능책임자', order: 600 },
  S_INS: { value: 'S_INS', label: '성능점검원', order: 601 },
  S_OFF: { value: 'S_OFF', label: '성능행정원', order: 602 },
};

// const UserRoleType = {
//   CERP_ADM: { value: 'CERP_ADM', label: 'ERP관리자', order: 100 },
//   A_MST: { value: 'A_MST', label: '협회마스터', order: 200 },
//   A_ADM: { value: 'A_ADM', label: '협회관리자', order: 201 },
//   A_OFF: { value: 'A_OFF', label: '협회사용자', order: 202 },
//   E_MST: { value: 'E_MST', label: '성능법인마스터', order: 300 },
//   E_ADM: { value: 'E_ADM', label: '성능법인대표', order: 301 },
//   E_OFF: { value: 'E_OFF', label: '성능법인사용자', order: 302 },
//   S_MST: { value: 'S_MST', label: '성능책임자', order: 400 },
//   S_ADM: { value: 'S_ADM', label: '성능관리자', order: 401 },
//   S_INS: { value: 'S_INS', label: '성능점검원', order: 402 },
//   S_OFF: { value: 'S_OFF', label: '성능행정원', order: 403 },
// };

Object.freeze(UserRoleType);

export default UserRoleType;
