import { useCallback, useEffect, useRef } from 'react';

import { ServiceProvider } from '../services';
import { YN } from '../constants/Constants';
import _ from 'lodash';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userState } from '../recoil/atoms';

const authService = ServiceProvider.auth;

function AxiosInterceptor({ children }) {
  const history = useHistory();
  const userAuth = useRecoilValue(userState);

  const EXCLUDED_URLS = ['/apis/token/issue', '/apis/token/withdraw'];
  const isLoggedIn =
    localStorage.getItem('isLoggedIn') === YN.YES || userAuth.isLoggedIn;
  const accessToken =
    localStorage.getItem('accessToken') || userAuth.accessToken;

  // console.log('axios interceptor', isLoggedIn, accessToken);
  axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
  axios.defaults.withCredentials = true;
  // axios.defaults.timeout = 10000;

  // TODO : window.reload 시 최초 호출되는 요청의 header에 accessToken이 누락됨.
  if (isLoggedIn && accessToken) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  }

  let isTokenRefreshing = useRef(false);
  let refreshSubscribers = useRef({});

  const onTokenRefreshed = useCallback(
    (accessToken) => {
      _.map(refreshSubscribers, (callback, url) => {
        callback(accessToken);
      });
      // setRefreshSubscribers({});
      refreshSubscribers.current = {};
    },
    [refreshSubscribers]
  );

  const addRefreshSubscriber = useCallback(
    (url, callback) => {
      refreshSubscribers[url] = callback;
    },
    [refreshSubscribers]
  );

  useEffect(() => {
    axios.interceptors.request.use(
      function (config) {
        // console.log('[axios request] [onFulfilled]', config.url, config);
        // 요청을 보내기 전 수행할 작업
        const { url } = config;
        const { Authorization: authorization } = config.headers;

        // console.log('요청 보내기 전', config.url, config.headers);

        if (!EXCLUDED_URLS.includes(url) && authorization === undefined) {
          const isLoggedIn = localStorage.getItem('isLoggedIn') === YN.YES;
          const accessToken = localStorage.getItem('accessToken');

          if (isLoggedIn && accessToken) {
            config.headers.common['Authorization'] = `Bearer ${accessToken}`;
          }
        }
        return config;
      },
      function (error) {
        // 오류 요청 가공
        // console.log('[axios request] [onRejected]', error);
        return Promise.reject(error);
      }
    );

    axios.interceptors.response.use(
      (response) => {
        // console.log('[RESPONSE]', response.config, response);
        return response;
      },
      async (error) => {
        const { config, response } = error;
        const { status, data: errorData } = response;
        const originalRequest = config;
        const { url } = originalRequest;

        // excel error data handling
        if (url.includes('excel')) {
          const errorData = JSON.parse(await response.data.text()).error;
          return Promise.reject(errorData);
        }

        // Refresh Token마저 만료
        if (url === '/apis/token/refresh' && status === 401) {
          localStorage.removeItem('accessToken');
          localStorage.removeItem('isLoggedIn');

          if (window.opener) {
            // 401 에러 떨어진 곳이 팝업창 내부일 때
            // 부모창에 팝업창 리스트를 확인하고 전부 닫음

            for (let popup of window.opener.cerp.popups.current) {
              popup.close();
            }

            window.opener.location.replace('/login');
          } else {
            history.replace('/login');
          }

          setTimeout(
            () =>
              window.cerp.toast.warn(
                '인증 만료',
                '인증이 만료되었습니다. 다시 로그인 해주세요.'
              ),
            500
          );
        }

        if (
          !EXCLUDED_URLS.includes(url) &&
          !url.endsWith('.json') &&
          status === 401
        ) {
          if (errorData?.status === 400) {
            //* 바로 뒷 페이지로 이동할 수 있는 방법 강구해보기
            history.go(-1);
            return Promise.reject(error);
          }

          if (!isTokenRefreshing.current) {
            // isTokenRefreshing이 false인 경우에만 token refresh 요청
            (async () => {
              isTokenRefreshing.current = true;

              try {
                const {
                  data: { accessToken: newAccessToken },
                } = await authService.refreshToken();

                localStorage.setItem('accessToken', newAccessToken);
                axios.defaults.headers.common[
                  'Authorization'
                ] = `Bearer ${newAccessToken}`;

                isTokenRefreshing.current = false;
                // 새로운 토큰으로 지연되었던 요청 진행
                onTokenRefreshed(newAccessToken);
              } catch (error) {
                // console.error('[Token Refresh]', error);
              }
            })();
          }

          // token이 재발급 되는 동안의 요청은 refreshSubscribers에 저장
          return new Promise((resolve) => {
            addRefreshSubscriber(originalRequest.url, (accessToken) => {
              originalRequest.headers.Authorization = 'Bearer ' + accessToken;
              resolve(axios(originalRequest));
            });
          });
        }

        return Promise.reject(error);
      }
    );
  }, [
    EXCLUDED_URLS,
    addRefreshSubscriber,
    history,
    isTokenRefreshing,
    onTokenRefreshed,
  ]);

  return children;
}

export { AxiosInterceptor };
