const SubmitType = {
  // New: 'N',
  Base: 'B',
  Detail: 'D',
  Complete: 'C',
  Temp: 'T',
};

Object.freeze(SubmitType);

export default SubmitType;
