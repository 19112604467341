import axios from 'axios';

const AxiosUtil = {
  Async: {
    async get(uri, queryString) {
      let result = { data: null, error: null };
      let url = uri;
      if (queryString) url += '?' + queryString;

      try {
        const {
          data: { data: resData = null },
        } = await axios.get(url);

        result.data = resData;
        // return { data: resData };
      } catch (e) {
        // result.error = e.response.data?.error;
        return Promise.reject(e.response?.data?.error);
      }

      return result;
    },
    async post(uri, data) {
      let result = { data: null, error: null };

      try {
        const {
          data: { data: resData },
        } = await axios.post(uri, data);

        result.data = resData;
      } catch (e) {
        // result.error = e.response.data?.error;
        return Promise.reject(e.response?.data?.error);
      }

      return result;
    },
    async put(uri, data) {
      let result = { data: null, error: null };

      try {
        const {
          data: { data: resData },
        } = await axios.put(uri, data);

        result.data = resData;
      } catch (e) {
        // result.error = e.response.data?.error;
        return Promise.reject(e.response?.data?.error);
      }

      return result;
    },
    async delete(uri, data) {
      let result = { data: null, error: null };

      try {
        const {
          data: { data: resData },
        } = await axios.delete(uri, data);

        result.data = resData;
      } catch (e) {
        // result.error = e.response.data?.error;
        return Promise.reject(e.response?.data?.error);
      }

      return result;
    },
    async multipart(uri, data, files = null, pfiles = null) {
      let result = { data: null, error: null };

      const formData = new FormData();

      if (data) formData.append('data', JSON.stringify(data));

      if (files && files.length > 0) {
        files.forEach((file) => {
          formData.append('files', file);
        });
      }

      if (pfiles && pfiles.length > 0) {
        pfiles.forEach((file) => {
          formData.append('pfiles', file);
        });
      }

      try {
        const {
          data: { data: resData },
        } = await axios({
          method: 'post',
          url: uri,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        result.data = resData;
      } catch (e) {
        return Promise.reject(e.response?.data?.error);
      }

      return result;
    },
    async updateMultipart(uri, data, files = null, pfiles = null) {
      let result = { data: null, error: null };

      const formData = new FormData();

      if (data) formData.append('data', JSON.stringify(data));

      if (files && files.length > 0) {
        files.forEach((file) => {
          formData.append('files', file);
        });
      }

      if (pfiles && pfiles.length > 0) {
        pfiles.forEach((file) => {
          formData.append('pfiles', file);
        });
      }

      try {
        const {
          data: { data: resData },
        } = await axios({
          method: 'put',
          url: uri,
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        result.data = resData;
      } catch (e) {
        return Promise.reject(e.response?.data?.error);
      }

      return result;
    },
  },
  Sync: {
    get() {},
  },
};

export default AxiosUtil;
