import React, { useState } from 'react';
import { ServiceProvider } from '../../../services';
import { Button } from 'primereact/button';

export const ExportButton = ({ type, params }) => {
  const [loading, setLoading] = useState(false);

  const exportToExcel = async (type, params) => {
    setLoading(true);
    console.log('button =>', type, params);
    await ServiceProvider[type].exportExcel(params);

    setLoading(false);
  };

  return (
    <Button
      label="엑셀 다운로드"
      className="p-button-outlined"
      icon="pi pi-download"
      disabled={loading}
      loading={loading}
      onClick={async () => await exportToExcel(type, params)}
    />
  );
};
