import AxiosUtil from '../utils/axiosUtil';

export const BoardService = (function () {
  let instance;
  const BASE_URI = '/apis/board';

  function init() {
    return {
      getList: async function (params) {
        const queryString = Object.entries(params)
          .map((e) => e.join('='))
          .join('&');
        return await AxiosUtil.Async.get(BASE_URI, queryString);
      },
      getData: async function (params) {
        const queryString = Object.entries(params)
          .map((e) => e.join('='))
          .join('&');
        return await AxiosUtil.Async.get(BASE_URI + '/detail', queryString);
      },
      register: async function (data, files) {
        return await AxiosUtil.Async.multipart(BASE_URI + '/post', data, files);
      },
      update: async function (data, files) {
        return await AxiosUtil.Async.updateMultipart(
          BASE_URI + '/update',
          data,
          files
        );
      },
      // delete: async function (id) {
      //   const queryString = `?seq=${id}`;
      delete: async function (params) {
        const queryString = Object.entries(params)
          .map((e) => e.join('='))
          .join('&');
        return await AxiosUtil.Async.delete(
          BASE_URI + '/delete?' + queryString
        );
      },
      getPopup: async function () {
        return await AxiosUtil.Async.get(BASE_URI + '/popup');
      },
      getMini: async function () {
        return await AxiosUtil.Async.get(BASE_URI + '/mini');
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();
