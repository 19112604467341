import { Controller } from 'react-hook-form';
import React from 'react';
import ValidateInputText from '../../Common/ValidateInputText';
import _ from 'lodash';

export const ControlledValidInputText = ({
  id,
  control,
  trigger,
  getValues,
  isAvailable,
  setIsAvailable,
  setValue,
  inputData,
  inputConfig,
  rules,
  isModifiable,
}) => {
  const { inputLabel, dataLabel } = inputData;
  const getValidationErrMsg = (field, error) => {
    return (
      error && (
        <small id={field} className="p-error">
          {error?.message}
        </small>
      )
    );
  };

  return (
    <Controller
      control={control}
      name={dataLabel}
      rules={rules}
      defaultValue=""
      render={({ field, fieldState, formState }) => (
        <div className="field m-0">
          <label htmlFor={field.name}>
            {_.get(rules, 'required') && (
              <i className="text-red-400 pi pi-check mr-1" />
            )}
            {inputLabel}
          </label>
          <div className="flex flex-column">
            <ValidateInputText
              isModifiable={isModifiable}
              isModified={id > 0}
              field={field}
              fieldState={fieldState}
              trigger={trigger}
              getValues={getValues}
              isAvailable={isAvailable}
              setValue={setValue}
              setIsAvailable={setIsAvailable}
              inputLabel={inputLabel}
              inputConfig={inputConfig}
            />
            {getValidationErrMsg(field.name, fieldState.error)}
          </div>
        </div>
      )}
    />
  );
};
