import * as Button from './../../components/Atoms/Button';

import React, { useState, useRef, useEffect } from 'react';
import { InputTextarea } from 'primereact/inputtextarea';
import { Panel } from 'primereact/panel';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import _ from 'lodash';

const CounselingView = () => {
  const data = JSON.parse(sessionStorage.getItem('data'));
  const [allText, setAllText] = useState(data);
  const [loading, setLoading] = useState(false);
  const reportRef = useRef(null);

  const [htmlText, setHtmlText] = useState('');

  const buildWatermark = (now) => {
    const watermark = document.createElement('div');
    _.map(
      _.split(
        'cerp-watermark absolute top-50 left-0 right-0 opacity-40 text-gray-500 font-bold p-4rem text-center text-4xl origin-center',
        ' '
      ),
      (cls) => watermark.classList.add(cls)
    );
    watermark.style.transform = 'rotate(-30deg)';
    const text = document.createTextNode(`출력일시 : ${now}`);
    watermark.appendChild(text);

    return watermark;
  };

  const handlePDF = async () => {
    setLoading(true);
    const papers = reportRef.current.childNodes;
    if (papers.length === 0) return false;

    const now = dayjs().format('YYYY-MM-DD HH:mm:ss');
    const pageImages = [];
    const reportPDF = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
      compress: true,
    });

    for (let i = 0; i < papers.length; i++) {
      const page = papers[i];
      // const watermark = buildWatermark(now);
      // page.appendChild(watermark);

      const canvas = await html2canvas(page, {
        scale: 2,
        logging: true,
        allowTaint: false,
        useCORS: true,
        imageTimeout: 0,
        backgroundColor: 'transparent',
      });

      const pageImage = canvas.toDataURL('image/png');
      pageImages.push(pageImage);
      // page.removeChild(watermark);
    }

    for (let i = 0; i < pageImages.length; i++) {
      reportPDF.addImage(
        pageImages[i],
        'PNG',
        0,
        0,
        210,
        297,
        `PAGE-${i}`,
        'NONE',
        0
      );
      if (i < pageImages.length - 1) reportPDF.addPage();
    }

    reportPDF.save(`${now}_상담이력.pdf`);
    setLoading(false);
  };

  function textToHTML(text) {
    const planeText = text.replaceAll('"', '');
    const lines = planeText.replaceAll('\\n', '<br />').split('\n');

    let html = '';
    lines.forEach((line) => {
      if (line.trim() !== '') {
        if (line.startsWith('[생성일시')) {
          html += `<div><Strong>${line}</Strong><br /></div>`;
        } else {
          html += `<div>${line}<br /><br /></div>`;
        }
      }
    });
    setHtmlText(html);
    return html;
  }

  useEffect(() => {
    if (sessionStorage.getItem('data')) {
      textToHTML(sessionStorage.getItem('data'));
    }
  }, []);

  const [showPage, setShowPage] = useState(false);
  return (
    <div className="p-2">
      <Panel
        header={
          <div className="flex justify-content-between align-items-center">
            <div>전체 상담 이력</div>
            {!showPage ? (
              <Button.Default
                type="button"
                label="다운로드 보기"
                className="p-button-outlined"
                icon="pi pi-eye"
                onClick={() => {
                  setShowPage(!showPage);
                  textToHTML(allText);
                }}
              />
            ) : (
              <div className="flex gap-2">
                <Button.Default
                  type="button"
                  label="다운로드"
                  icon="pi pi-file-pdf"
                  onClick={handlePDF}
                />
                <Button.Default
                  type="button"
                  label="취소"
                  className="p-button-outlined"
                  icon="pi "
                  onClick={() => setShowPage(false)}
                />
              </div>
            )}
          </div>
        }
      >
        <div ref={reportRef}>
          {!showPage ? (
            <InputTextarea
              value={allText}
              onChange={(e) => {
                setAllText(e.target.value);
              }}
              rows={45}
              className="w-full"
            />
          ) : (
            <>
              <div
                style={{ height: window.innerHeight * 0.8 }}
                className="pdf p-4"
                dangerouslySetInnerHTML={{
                  __html: htmlText,
                }}
              ></div>
            </>
          )}
        </div>
      </Panel>
    </div>
  );
};

export default CounselingView;
