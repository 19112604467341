import { ServiceProvider } from '../services';
import UserPosition from '../enums/UserPosition';
import _ from 'lodash';

const codeService = ServiceProvider.code;

export const CodeUtil = {
  async getInsuranceCodes({ preSelectedInsuranceCode }) {
    const { data } = await codeService.insurances();
    const insurances = [...data];
    const insurance =
      (preSelectedInsuranceCode
        ? _.find(insurances, { value: preSelectedInsuranceCode })
        : _.get(insurances, 0)) || {};

    return [insurances, _.get(insurance, 'value'), insurance];
  },
  async getAssociationCodes(userInfo, { preSelectedAssociationId }) {
    const { myAssociationId, myAssociationName, myUserPosition } = userInfo;
    const myAssociation = { value: myAssociationId, label: myAssociationName };

    if (myUserPosition === UserPosition.Erp) {
      // 전체 협회 조회해온다
      const { data } = await codeService.associations();
      const associations = [{ value: '', label: '전체협회' }, ...data];
      const association = preSelectedAssociationId
        ? _.find(associations, { value: preSelectedAssociationId })
        : _.get(associations, 0) || {};

      return [associations, _.get(association, 'value'), association];
    }

    return [[myAssociation], _.get(myAssociation, 'value'), myAssociation];
  },
  async getEnterpriseCodes(
    userInfo,
    { isParentEmpty = false, associationId, preSelectedEnterpriseId }
  ) {
    const {
      myAssociationId,
      myEnterpriseId,
      myEnterpriseName,
      myUserPosition,
    } = userInfo;
    const myEnterprise = { value: myEnterpriseId, label: myEnterpriseName };

    if (isParentEmpty && _.isEmpty(associationId)) return [[], 0, {}];

    if (myUserPosition === UserPosition.Erp && associationId) {
      // 어드민 계정에 특별한 association 아이디로 조회하는 경우
      const { data } = await codeService.enterprises([associationId]);
      const enterprises = [{ value: '', label: '전체점검법인' }, ...data];
      const enterprise = preSelectedEnterpriseId
        ? _.find(enterprises, { value: preSelectedEnterpriseId })
        : _.get(enterprises, 0) || {};

      return [enterprises, _.get(enterprise, 'value'), enterprise];
    } else if (
      myUserPosition === UserPosition.Erp ||
      myUserPosition === UserPosition.Association
    ) {
      const { data } = await codeService.enterprises([myAssociationId]);
      const enterprises = [{ value: '', label: '전체점검법인' }, ...data];
      const enterprise = preSelectedEnterpriseId
        ? _.find(enterprises, { value: preSelectedEnterpriseId })
        : _.get(enterprises, 0) || {};

      return [enterprises, _.get(enterprise, 'value'), enterprise];
    }

    return [[myEnterprise], _.get(myEnterprise, 'value'), myEnterprise];
  },
  async getShopCodes(
    userInfo,
    { isParentEmpty = false, enterpriseId, preSelectedShopId }
  ) {
    const { myEnterpriseId, myShopId, myShopName, myUserPosition } = userInfo;
    const myShop = { value: myShopId, label: myShopName };

    if (isParentEmpty && _.isEmpty(enterpriseId)) return [[], 0, {}];

    if (
      (myUserPosition === UserPosition.Erp ||
        myUserPosition === UserPosition.Association) &&
      enterpriseId
    ) {
      // 어드민 계정에 특별한 association 아이디로 조회하는 경우
      const { data } = await codeService.shops([enterpriseId]);
      const shops = [{ value: '', label: '전체점검장' }, ...data];
      const shop =
        (preSelectedShopId
          ? _.find(shops, { value: preSelectedShopId })
          : _.get(shops, 0)) || {};

      return [shops, _.get(shop, 'value'), shop];
    } else if (
      myUserPosition === UserPosition.Erp ||
      myUserPosition === UserPosition.Association ||
      myUserPosition === UserPosition.Enterprise
    ) {
      const { data } = await codeService.shops([myEnterpriseId]);
      const shops = [{ value: '', label: '전체점검장' }, ...data];
      const shop =
        (preSelectedShopId
          ? _.find(shops, { value: preSelectedShopId })
          : _.get(shops, 0)) || {};

      return [shops, _.get(shop, 'value'), shop];
    }

    return [[myShop], _.get(myShop, 'value'), myShop];
  },
};
