const [Erp, Association, Enterprise, Shop] = ['Erp', 'Association', 'Enterprise', 'Shop'];

const UserPosition = {
  Erp,
  Association,
  Enterprise,
  Shop,
};

Object.freeze(UserPosition);

export default UserPosition;

