import AxiosUtil from '../utils/axiosUtil';

export const ErpActionService = (function () {
  let instance;
  // const BASE_URI = 'base service uri'

  function init() {
    return {
      menus: async function () {
        return await AxiosUtil.Async.get('/apis/cmea/menus');
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();
