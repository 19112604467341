import React from 'react';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Divider } from 'primereact/divider';

const ReportDialog = ({ onHide }) => {
  return (
    <Dialog
      onHide={onHide}
      modal
      breakpoints={{ '960px': '90vw' }}
      header={'보고서'}
      visible
      style={{ width: '76vw' }}
      footer={() => (
        <div className="flex flex-auto align-items-start justify-content-start pt-2">
          <Button
            label="완료"
            // icon="pi pi-send"
            className=" mr-1"
          />
        </div>
      )}
    >
      <Panel header="보고서 작성" className="pt-3">
        <div className="grid">
          <div className="col-12">
            <div className="field grid align-items-center">
              <div className="col-2">
                <label htmlFor="">보험사 선택</label>
              </div>
              <div className="col-3">
                <Dropdown
                  // value={selectedCity}
                  // onChange={(e) => setSelectedCity(e.value)}
                  // options={status}
                  optionLabel="name"
                  // placeholder=""
                  className="w-full"
                />
              </div>
            </div>

            <div className="field grid align-items-center">
              <div className="col-2">
                <label htmlFor="">증권번호</label>
              </div>
              <div className="col-3">
                <InputText
                  // value={value}
                  // onChange={(e) => setValue(e.target.value)}
                  className=" w-full"
                />
              </div>
            </div>

            <div className="field grid align-items-center">
              <div className="col-2">
                <label htmlFor="">상담내용</label>
              </div>
              <div className="col">
                <InputText
                  // value={value}
                  // onChange={(e) => setValue(e.target.value)}
                  className=" w-full"
                />
              </div>
            </div>
            <div className="field grid align-items-center">
              <div className="col-2">
                <label htmlFor="">수리품목</label>
              </div>
              <div className="col">
                <InputText
                  // value={value}
                  // onChange={(e) => setValue(e.target.value)}
                  className=" w-full"
                />
              </div>
            </div>
            <div className="field grid align-items-center">
              <div className="col-2">
                <label htmlFor="">손해액</label>
              </div>
              <div className="col-3">
                <InputText
                  // value={value}
                  // onChange={(e) => setValue(e.target.value)}
                  className=" w-full"
                />
              </div>
            </div>
            <div className="field grid align-items-center">
              <div className="col-2">
                <label htmlFor="">자기부담금</label>
              </div>
              <div className="col-3">
                <InputText
                  // value={value}
                  // onChange={(e) => setValue(e.target.value)}
                  className=" w-full"
                />
              </div>
            </div>
            <div className="field grid align-items-center">
              <div className="col-2">
                <label htmlFor="">연락처</label>
              </div>
              <div className="col-3">
                <InputText
                  // value={value}
                  // onChange={(e) => setValue(e.target.value)}
                  className=" w-full"
                />
              </div>
            </div>
            <div className="field grid align-items-center">
              <div className="col-2">
                <label htmlFor="">작성자</label>
              </div>
              <div className="col-3">
                <InputText
                  // value={value}
                  // onChange={(e) => setValue(e.target.value)}
                  className=" w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <Divider />
        <div className="flex justify-content-end mt-4 gap-2">
          <Button
            label="자부담금 통보"
            // icon="pi pi-send"
            className="p-button-outlined mr-1"
          />
          <Button
            label="보고서 보내기"
            // icon="pi pi-send"
            className=" mr-1"
          />
        </div>
      </Panel>
    </Dialog>
  );
};

export default ReportDialog;
