import AxiosUtil from '../utils/axiosUtil';

export const CheckPriceService = (function () {
  let instance;

  function init() {
    return {
      register: async function (shopId = 0, data) {
        return await AxiosUtil.Async.post(
          `/apis/shops/${shopId}/check-price`,
          data
        );
      },
      getData: async function (shopId = 0) {
        return await AxiosUtil.Async.get(`/apis/shops/${shopId}/check-price`);
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();
