import { ProgressSpinner } from 'primereact/progressspinner';
import React from 'react';

const Alert = () => {
  return (
    <div
      style={{
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ProgressSpinner aria-label="Loading" />
      <h2 style={{ display: 'inline' }}>
        <strong>서비스 준비중</strong>
        입니다.
      </h2>
      <span>페이지 준비중에 있습니다.</span>
      <span>빠른 시일내에 준비하여 찾아뵙겠습니다.</span>
    </div>
  );
};

export default Alert;
