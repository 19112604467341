import React, { useState } from 'react';
import { Galleria } from 'primereact/galleria';
import ReactPanZoom from 'react-image-pan-zoom-rotate';
const images = JSON.parse(sessionStorage.getItem('images'));

const url = process.env.REACT_APP_S3_BASE_URL;
const ImagesView = () => {
  const itemTemplate = (item) => {
    return (
      // <img
      //   src={item.file ? item.file.preview : url + item.filePath}
      //   alt={item.file ? item.file.name : item.originalFileName}
      //   style={{ width: '90vw', display: 'block' }}
      // />
      <div
        style={{ display: 'block', height: '90vh' }}
        onClick={(e) => console.log(e.target)}
      >
        <div className="flex gap-2 justify-content-center font-bold my-4">
          <div>파일명 : </div>
          <div>{item.file ? item.file.name : item.originalFileName}</div>
        </div>
        <ReactPanZoom
          image={item.file ? item.file.preview : url + item.filePath}
          alt={item.file ? item.file.name : item.originalFileName}
        />
      </div>
    );
  };

  const thumbnailTemplate = (item) => {
    return (
      <img
        src={item.file ? item.file.preview : url + item.filePath}
        alt={item.file ? item.file.name : item.originalFileName}
        style={{
          display: 'block',
          height: '50px',
        }}
      />
    );
  };

  return (
    <>
      <div className="pcr_wrapper">
        <Galleria
          value={images}
          showItemNavigators
          circular
          numVisible={10}
          // thumbnailsPosition="right"
          thumbnailsPosition="top"
          item={itemTemplate}
          thumbnail={thumbnailTemplate}
        />
      </div>
    </>
  );
};

export default ImagesView;
