import React, {useEffect, useState} from 'react';
import {BlockUI} from 'primereact/blockui';
import axios from 'axios';
import {MESSAGE_STATUS} from '../../constants/Constants';
import _ from 'lodash';

const PDF_PREFIX = 'data:application/pdf;base64,';

const ReportLoader = () => {
  const [loading, setLoading] = useState(true);
  const [docBinary, setDocBinary] = useState(null);
  const listener = (event) => {
    if (_.get(event, 'data.reportType')
      && _.get(event, 'data.params')) {
      loadData(event.data.reportType.api, event.data.params);
    }
  };

  const loadData = async (apiUrl, params) => {
    let response = await axios.post(apiUrl, params, {responseType: 'arraybuffer'});
    const buffer = new Buffer(response.data, 'binary').toString('base64');
    setDocBinary(PDF_PREFIX.concat(buffer));
    setLoading(false);
  };

  useEffect(() => {
    window.addEventListener('message', listener, false);
    window.opener.postMessage(MESSAGE_STATUS.READY, window.location.origin);

    return () => window.removeEventListener('message', listener);
  }, []);

  return (
    <div style={{maxHeight: '100vh', overflowY: 'hidden'}}>
      <BlockUI
        blocked={loading}
        template={loading && <i className="pi pi-spin pi-spinner text-white-alpha-90 font-bold text-6xl" />}>
        <object data={docBinary}
                type="application/pdf"
                style={{width: '100%', minHeight: '100vh'}}
                aria-labelledby="PDF document" />
      </BlockUI>
    </div>
  );
};

export default ReportLoader;
