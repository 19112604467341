import axios from 'axios';
import dayjs from 'dayjs';

export const ExternalService = (function () {
  let instance;
  // const BASE_URI =
  //   'https://admin-api.carmon.in/apis/admin/external/accident-history';

  function init() {
    return {
      getCarHistory: async function (licensePlateNumber) {
        const data = {
          joinCode: '2022101101',
          sType: '1',
          memberID: 'carmonster',
          carnum: licensePlateNumber,
          carNumType: '0',
          stdDate: '',
          rType: 'J',
        };
        return await axios.post(
          'http://www.carhistory.or.kr:8810/dataTrans/carhistoryAPI3Test.car',
          data
        );
      },
      getNiceDNR: async function ({ licensePlateNumber, firstRegDate }) {
        const current = dayjs().format('YYYMMDDHHmmss');
        console.log(current);
        const BASE_URI = 'https://niceab.nicednr.co.kr/carInfos';
        const data = {
          apiKey: 'ABDDD04F9380C1DA29D6CDD76CF1EBF9',
          loginId: 'carmon',
          chkSec: current,
          // chkKey: '(chkSec mod 4578702409) mod 997',
          chkKey: String((Number(current) % 4578702409) % 997),
          kindOf: '68',
          refDate: firstRegDate,
          vhrNo: licensePlateNumber,
          ownerNm: '',
          searchType: 'A',
        };

        // return await axios.post(BASE_URI, data);

        return await fetch(BASE_URI, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }).then((data) => console.log(data));
      },
    };
  }

  return {
    getInstance: function () {
      if (!instance) instance = init();
      return instance;
    },
  };
})();
