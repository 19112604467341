import * as Button from './../../components/Atoms/Button';

import { Divider } from 'primereact/divider';
import React from 'react';

const SearchMenu = ({ children, onInit, onSearch }) => {
  return (
    <div className="card">
      <div className="formgrid grid">{children}</div>
      <Divider />
      <div className="text-right">
        <Button.SearchInit onInit={onInit} />
        <Button.Search onSearch={onSearch} />
      </div>
    </div>
  );
};

export default SearchMenu;
